import React, { Fragment, Component, Children } from "react";
import {
    Row, Container, Button
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ProductSlideList from "../../../components/front-end/ProductSlideList";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL, IG_TOKEN } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

SwiperCore.use([Navigation, Autoplay]);

class ProductSlider extends Component {
    constructor(props) {
        super(props);
		
		this.state = {
			products: [],
			blocking:true,
		};
		
		this.swiperRef = React.createRef();
		this.goNextReview = this.goNextReview.bind(this);
		this.goPrevReview = this.goPrevReview.bind(this);
    }
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		const { fields } = this.props;
		let product_ids = fields.find(r=>{ return r.key==='products' });
		if(product_ids){
			product_ids = product_ids.value.map(p=>p.value).join(',');
		}else{
			product_ids='';
		}
		if(product_ids!==''){
			formData.append('product_ids',product_ids);
			axios.post(REST_API_END_POINT+'website/get-products',formData).then((res)=>{ 
				this.setState({ products: res.data.products, blocking:false });
			}).catch(e =>{ 
				console.log("Addition failed, Error ", e) 
				this.setState({ blocking:false });
			});
		}
	}
	
	goNextReview() {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}

	goPrevReview = () => {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	};
	
    render() {
		const { fields } = this.props;
		const { products } = this.state;
		let head = fields.find(r=>{ return r.key==='head' });
		let sub_head = fields.find(r=>{ return r.key==='sub_head' });
		
		const paramsone = {
			spaceBetween: 0,
			centeredSlides: false,
			loop: false,
			/*navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},*/

			shouldSwiperUpdate: true,
			rebuildOnUpdate: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 0,
				},
			}
		}

        return (
            <div>
				<div className={'text-center mt-3 pt-5'}>
					{head.value.length<50 ? <h4 className='h1'>
						{head.value}
					</h4>:<h3>
						{head.value}
					</h3>}
					{sub_head.value!=="" ? <p>
						{sub_head.value}
					</p>:""}
				</div>
				{products.length ? <div className="shop-area pt-4 pb-5">
					<div className={"container "+(products.length > 3 || (products.length > 1 && (isMobile || isAndroid)) ? "testimonial-wrapper":"")} >
						<div className="row justify-content-center">
							<div className="col-lg-12 shop-bottom-area mobile-slide-padding hide-shop-list-wrap ">
								{/* shop page content default */}
								{products.length > 3 || (products.length > 1 && (isMobile || isAndroid)) ? <Fragment><Swiper {...paramsone} ref={this.swiperRef}>
									{products.map(product => {
										return (
											<ProductSlideList
												spaceBottomClass={"mb-3"}
												sliderClassName={"swiper-slide col-12 "}
												product={product}
												key={product.id}
											/>
										);
									})}
								</Swiper>
								<div className="swiper-button-next swiper-review-next" onClick={this.goNextReview} ></div>
								<div className="swiper-button-prev swiper-review-prev" onClick={this.goPrevReview} ></div>
								</Fragment> : <div className="row justify-content-center">{products.map(product => {
									return (
										<ProductSlideList
											spaceBottomClass={"mb-3"}
											sliderClassName={"col-12 "}
											product={product}
											key={product.id}
										/>
									);
								})}</div>}
							</div>
						</div>
					</div>
				</div>:""}					
            </div>

        );
    }
};

export default ProductSlider;
