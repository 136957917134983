import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { getCurrencySymbol, displayDateFormat } from "../../../../helpers/Utils";

import ChartComponent, { Chart } from "react-chartjs-2";
import { centerTextPlugin, chartTooltip } from "../../../../components/charts/util";
import { makeColorGradient } from "../../../../helpers/Utils";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

const barChartOptions = {
	legend: {
		position: 'bottom',
		labels: {
			padding: 30,
			usePointStyle: true,
			fontSize: 12
		}
	},
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		yAxes: [
			{
				gridLines: {
					display: true,
					lineWidth: 1,
					color: 'rgba(0,0,0,0.1)',
					drawBorder: false
				},
				ticks: {
					beginAtZero: true,
					padding: 30
				}
			}
		],
		xAxes: [
			{
				gridLines: {
					display: false
				}
			}
		]
	},
	tooltips: chartTooltip
}

const days = [
	{ label:"All", value:"", key:0 },
	{ label:"Sunday", value:"Sunday", key:1 },
	{ label:"Monday", value:"Monday", key:2 },
	{ label:"Tuesday", value:"Tuesday", key:3 },
	{ label:"Wednesday", value:"Wednesday", key:4 },
	{ label:"Thursday", value:"Thursday", key:5 },
	{ label:"Friday", value:"Friday", key:6 },
	{ label:"Saturday", value:"Saturday", key:7 },
]

export default class SalesCountDay extends Component {
	
	constructor(props) {
		super(props);
		
		let currencySymbol = getCurrencySymbol(false);
		this.state = {		 
			reports:[],
			search: '',
			start_date: moment(),
			end_date: moment(),
			start_time: null,
			end_time: null,
			day_name: { label:"All", value:"", key:0 },
			blocking: true,
			currencySymbol,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 
	
	handleChangeStartTime = date => {
		this.setState({
			start_time: date
		});
	} 
	
	handleChangeEndTime = date => {
		this.setState({
			end_time: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD'));
		}else{
			formData.append('end_date','');
		}

		if(this.state.day_name!=null){
			formData.append('day_name',this.state.day_name.value);
		}else{
			formData.append('day_name','');
		}
		
		if(this.state.start_time!=null){
			formData.append('start_time',moment(this.state.start_time).format('HH:mm'));
		}else{
			formData.append('start_time','');
		}
		
		if(this.state.end_time!=null){
			formData.append('end_time',moment(this.state.end_time).format('HH:mm'));
		}else{
			formData.append('end_time','');
		}
		
		axios.post(REST_API_END_POINT+'report/sales-count-day-hour',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				this.setState({
					reports: data.reports,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		var i=1;
		for(let row of reports){
			formated_data.push([
				i++,
				row.product,
				this.state.currencySymbol+row.price,
				row.quantity,
				this.state.currencySymbol+row.total,
				row.max_qty,
				row.min_qty,
				row.avg_qty
			])
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		
		var labels = reports.map(row=>row.product);
		var chart_data_values1 = reports.map(row=>parseFloat(row.min_qty));
		var chart_data_values2 = reports.map(row=>parseFloat(row.avg_qty));
		var chart_data_values3 = reports.map(row=>parseFloat(row.max_qty));
		const chart_data = {
		  labels: labels,
		  datasets: [
			{
			  label: 'Minimum Quantity',
			  borderColor: '#ff6384',
			  backgroundColor: '#ff6384',
			  borderWidth: 2,
			  data: chart_data_values1
			},
			{
			  label: 'Average Quantity',
			  borderColor: '#36a2eb',
			  backgroundColor: '#36a2eb',
			  borderWidth: 2,
			  data: chart_data_values2
			},
			{
			  label: 'Maximum Quantity',
			  borderColor: '#80f31f',
			  backgroundColor: '#80f31f',
			  borderWidth: 2,
			  data: chart_data_values3
			}
		  ]
		}
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.sales-count-day-hour" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="4">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									placeholderText=""
									dateFormat={displayDFormat}
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									placeholderText=""
									dateFormat={displayDFormat}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Day</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="day_name"
									id="day_name"
									placeholder="All"
									value={this.state.day_name}						
									options={days}
									onChange={day_name => { this.setState({ day_name }) }}
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label>Start Time<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_time"
									name="start_time"
									className="mb-2"
									autoComplete="off"
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15}
									selected={this.state.start_time}
									onChange={this.handleChangeStartTime}
									placeholderText=""
									dateFormat="HH:mm"
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>End Time<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_time"
									name="end_time"
									className="mb-2"
									autoComplete="off"
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15}
									selected={this.state.end_time}
									onChange={this.handleChangeEndTime}
									placeholderText=""
									dateFormat="HH:mm"
								/>
							</Colxx>
							<Colxx lg="4">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 
						{chart_data_values1.length ? <Row className="mt-4">
							<Colxx xxs="12" className="report-bar-chart-container">							
								<ChartComponent
									ref={ref => (this.chart_instance = ref && ref.chart_instance)}
									type={"bar"}
									options={{
									  ...barChartOptions
									}}
									data={chart_data}
								  />
							</Colxx>
						</Row>:""}
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>S.No</th>
												<th>Product</th>
												<th>Price</th>
												<th>Quantity</th>
												<th>Total</th>
												<th>Maximum Quantity</th>
												<th>Mininum Quantity</th>
												<th>Average Quantity</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
