import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import processor from "./processor";
import equipments from "./equipments";
import columns from "./columns";
import vessels from "./vessels";
import allrecipes from "./allrecipes";
import equipment_dependencies from "./equipment-dependencies";
import sacks from "./sacks";
import jars from "./jars";
import processoverview from "./processoverview";
import rawmaterialstaging from "./rawmaterialstaging";
import column_filling from "./column-filling";
import alloperations from "./alloperations";
import extractoroperation from "./extractoroperation";
import crcoperation from "./crcoperation";
import ovenoperation from "./ovenoperation";
import mcpoperation from "./mcpoperation";
import evaporatoroperation from "./evaporatoroperation";
import distillationoperation from "./distillationoperation";
import packaging from "./packaging";
import editRawmaterialstaging from "./edit-rawmaterialstaging";
import editPackaging from "./edit-packaging";
const Processor = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
		
			<Redirect exact from={`${match.url}/`} to={`${match.url}/all-operations`} />
			<Route path={`${match.url}/processor`} component={processor} />
			<Route path={`${match.url}/equipments`} component={equipments} />
			<Route path={`${match.url}/equipment-dependencies`} component={equipment_dependencies} />
			<Route path={`${match.url}/columns`} component={columns} />
			<Route path={`${match.url}/vessels`} component={vessels} />
			<Route path={`${match.url}/sacks`} component={sacks} />
			<Route path={`${match.url}/jars`} component={jars} />
			<Route path={`${match.url}/crc-recipes`} component={allrecipes} />
			<Route path={`${match.url}/process-overview`} component={processoverview} />
			<Route path={`${match.url}/column-filling`} component={column_filling} />
			<Route path={`${match.url}/raw-material-staging`} component={rawmaterialstaging} />
			<Route path={`${match.url}/all-operations`} component={alloperations} />
			<Route path={`${match.url}/extractor-operation`} component={extractoroperation} />
			<Route path={`${match.url}/crc-operation`} component={crcoperation} />
			<Route path={`${match.url}/oven-operation`} component={ovenoperation} />
			<Route path={`${match.url}/mcp-operation`} component={mcpoperation} />
			<Route path={`${match.url}/evaporator-operation`} component={evaporatoroperation} />
			<Route path={`${match.url}/distillation-operation`} component={distillationoperation} />
			<Route path={`${match.url}/packaging`} component={packaging} />
			<Route path={`${match.url}/edit-product-batch/:staging_id`} component={editRawmaterialstaging} />
			<Route path={`${match.url}/edit-packaging/:staging_id`} component={editPackaging} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Processor  ;