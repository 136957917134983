import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button, Container, ListGroup, ListGroupItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
//import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";

import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';

import { SuperSEO } from "react-super-seo";

import { REST_API_END_POINT, dataEncryptKey, BASE_URL, SITE_URL, projectTitle } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import MailChimpIntegration from "../components/front-end/MailChimpIntegration";
//import { seo } from "../helpers/Utils";
import { SideSection, TestimonialSolo, StorePromo, ProductSlider, Slider, NewArrivalsSlider, AboutUs, ShopByCategory, RecentReviews, CenterSection } from '../components/common/landingPage';

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class commonPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template_data: null,
            alias_key: '',
            show_title_head: false,
			seo:{
				title:projectTitle,
				metaDescription: 'Know our story'
			}
        };
        this.dataListRender = this.dataListRender.bind(this);
        /*seo({
            title: 'About | ' + projectTitle,
            metaDescription: 'Know our story'
        });*/
    }

    componentDidMount() {
        document.body.classList.add("background");
        document.body.classList.add("background-main-pages");
        var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
            this.dataListRender();
        } else {
            window.location = '/';
        }
    }
	
	componentDidUpdate() {
		var facility_id = localStorage.getItem('facility_id');
        if (facility_id) {
			let old_template_id = this.state.alias_key;
			let template_id = this.props.alias_key;
			if(typeof this.props.match!='undefined'){
				template_id = this.props.match.params.alias_key;
			}
			
			if(old_template_id!==template_id){
				this.dataListRender();
			}
        } else {
            window.location = '/';
        }
    }
    componentWillUnmount() {
        document.body.classList.remove("background");
        document.body.classList.remove("background-main-pages");
    }

    dataListRender() {
        var facility_id = localStorage.getItem('facility_id');
        facility_id = dataCrypto.decrypt(facility_id);
		
		let template_id = this.props.alias_key;
		if(typeof this.props.match!='undefined'){
			template_id = this.props.match.params.alias_key;
		}
		
        var data_upload = new FormData();
        data_upload.append('facility_id', facility_id);
        data_upload.append('template_id', template_id);
        axios.post(REST_API_END_POINT + 'website/get-seo-page-template-by-alias', data_upload).then((res) => {
            if (res.data.status == 1) {
				let data = res.data.data;
				let seo = {
					title: data.name+' | ' + projectTitle,
					metaDescription: data.meta_description!=='' ? data.meta_description:'Know our story'
				};
                this.setState({ seo });
            }
        }).catch(e => {
            console.log("Addition failed , Error ", e)
            this.setState({ blocking: false });
        });
		
		axios.post(REST_API_END_POINT + 'website/get-page-template-by-alias', data_upload).then((res) => {
            if (res.data.status == 1) {
				let data = res.data.data;
				let show_title_head = data.show_title_head=="1";
                this.setState({ alias_key:template_id,template_data: data, show_title_head, blocking: false });
            } else {
				window.location="/error"
                this.setState({ blocking: false });
            }
        }).catch(e => {
            console.log("Addition failed , Error ", e)
            this.setState({ blocking: false });
        });
    }
	
	generateField(name,fields,index){
		if(name==="Quote"){
			let author = fields.find(r=>{ return r.key==='author' });
			let head = fields.find(r=>{ return r.key==='head' });
			let quote = fields.find(r=>{ return r.key==='quote' });
			return <TestimonialSolo 
						key={index}
						person={author.value}
						heading={head.value} >
						“{quote.value}”
					</TestimonialSolo>
		}else if(name==="Heading"){
			let head = fields.find(r=>{ return r.key==='head' });
			let sub_head = fields.find(r=>{ return r.key==='sub_head' });
			let buttonK = fields.find(r=>{ return r.key==='button' });
			let button_link = fields.find(r=>{ return r.key==='button_link' });
			if(head.value=="" && !(buttonK.value!='' && button_link.value!='')){
				return <div className='text-center mt-5  testim-solos' key={index}>
					<Container>
						<h5 className='h5' dangerouslySetInnerHTML={{ __html: sub_head.value }} ></h5>
					</Container>
				</div>
			}else{
				return <Container key={index}>
					<div className={'text-center mt-3 pt-5 '+(buttonK.value!='' && button_link.value!='' ? 'pb-5':'')}>
						{head.value.length<50 ? <h4 className='h1'>
							{head.value}
						</h4>:<h3>
							{head.value}
						</h3>}
						{sub_head.value!=="" ? <p>
                            {sub_head.value}
						</p>:""}
						{buttonK.value!='' && button_link.value!='' ? <a href={button_link.value} className="btn btn-secondary" >{buttonK.value}</a>:""}
					</div>
				</Container>
			}
		}else if(name==="Left Right Block"){
			let head = fields.find(r=>{ return r.key==='head' });
			let image_position = fields.find(r=>{ return r.key==='image_position' });
			let image_url = fields.find(r=>{ return r.key==='image_url' });
			let content = fields.find(r=>{ return r.key==='content' });
			let colorcbd = fields.find(r=>{ return r.key==='background' });
			let image_hover = fields.find(r=>{ return r.key==='image_hover' });
			const text_color = fields.find(r=>{ return r.key==='text_color' });
			let textColor = '#303030';
			if(text_color){
				textColor = text_color.value;
			}
			return <SideSection
					key={index}
					className='q-section'
					textLeft={image_position.value==='right'}
					img={`${BASE_URL}pages/${image_url.value}`}
					colorcbd={colorcbd.value}
					textColor={textColor}
					secondText={image_hover.value}
				>
					<div>
						<h3 className='h3'>
							{head.value}
						</h3>
						<div dangerouslySetInnerHTML={{ __html: content.value }} ></div>
					</div>
				</SideSection>
		}else if(name==="Content Block"){
			let head = fields.find(r=>{ return r.key==='head' });
			let content = fields.find(r=>{ return r.key==='content' });
			return <CenterSection style={{ background:"#fff", textAlign:'left' }} key={index} >
					<div>
						<h3 className='h3 text-center'>
							{head.value}
						</h3>
						<div className="text-left" dangerouslySetInnerHTML={{ __html: content.value }} ></div>
					</div>
				</CenterSection>
		}else if(name==="Product Slider"){
			return <ProductSlider
					key={index}
					className='q-section'
					fields={fields}
				/>
		}else if(name==="Iframes"){
			let head = fields.find(r=>{ return r.key==='head' });
			let sub_head = fields.find(r=>{ return r.key==='sub_head' });
			let no_cols = fields.find(r=>{ return r.key==='no_cols' });
			let iframes = fields.find(r=>{ return r.key==='iframes' });
			return <CenterSection style={{ background:"#fff" }} key={index} >
					{head.value.length<50 ? <h4 className={'h1 my-2 '+(no_cols.value=="12" ? "text-left":"")}>
						{head.value}
					</h4>:<h3 className={no_cols.value=="12" ? "text-left":""}>
						{head.value}
					</h3>}
					{sub_head.value!=="" ? <p>
						{sub_head.value}
					</p>:""}
					
					{no_cols.value!="12" ? <div>
						<Row className={`text-center`}>
							{iframes.value.map((iframe,if_id)=>{
								if(iframe.value!=""){
									return <Colxx key={if_id} xxs="12" md={no_cols.value} className='d-flex flex-column align-items-start justify-content-between data-sect p-3'>
										<iframe id={"news_iframe_"+if_id} className="" title={iframe.title} width="100%" height="100%" src={iframe.value} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
									</Colxx>
								}else{
									return "";
								}
							})}
						</Row>
					</div>:<div>
						<Row>
							<Colxx xxs="12" className='d-flex flex-column align-items-start justify-content-between p-3 mx-auto mx-md-0'>
								<ListGroup className='text-left' style={{width: '100%'}}>
									{iframes.value.map((iframe,if_id)=>{
										if(iframe.value!=""){
											return <ListGroupItem key={if_id} >
												<iframe className="" title={iframe.title} width="100%" height="100" src={iframe.value} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no"></iframe>
											</ListGroupItem>
										}else{
											return "";
										}
									})}
								</ListGroup>
							</Colxx>
						</Row>
					</div>}
			</CenterSection>
		}else if(name==="Articles"){
			let head = fields.find(r=>{ return r.key==='head' });
			let sub_head = fields.find(r=>{ return r.key==='sub_head' });
			let articles = fields.find(r=>{ return r.key==='articles' });
			return <CenterSection style={{ background:"#fff" }} key={index} >
					{head.value.length<50 ? <h4 className='h1 my-2'>
						{head.value}
					</h4>:<h3>
						{head.value}
					</h3>}
					{sub_head.value!=="" ? <p>
						{sub_head.value}
					</p>:""}
					
					<div>
						<Row>
							<Colxx xxs="12" md='12' className='d-flex flex-column align-items-start justify-content-between p-3'>
								<ListGroup className='text-left  mx-auto'>
									{articles.value.map((article,if_id)=>{
										if(article.value!=""){
											return <ListGroupItem key={if_id}>
												<a href={article.value} target="_blank"><h6>{article.title}</h6></a>
											</ListGroupItem>
										}else{
											return "";
										}
									})}
								</ListGroup>
							</Colxx>
						</Row>
					</div>
			</CenterSection>
		}else if(name==="LightBox Gallery"){
			let head = fields.find(r=>{ return r.key==='head' });
			let sub_head = fields.find(r=>{ return r.key==='sub_head' });
			let no_cols = fields.find(r=>{ return r.key==='no_cols' });
			if(no_cols==null){
				no_cols = {key:3,label:'3',value:'4'};
			}
			let images = fields.find(r=>{ return r.key==='images' });
			return <CenterSection style={{ background:"#fff" }} key={index}>
					{head.value.length<50 ? <h4 className='h1 my-2'>
						{head.value}
					</h4>:<h3>
						{head.value}
					</h3>}
					{sub_head.value!=="" ? <p>
						{sub_head.value}
					</p>:""}
					
					<SRLWrapper>
						<div>
							<Row className={`text-center justify-content-center`}>
								{images.value.map((image,if_id)=>{
									if(image.value!=""){
										return <Colxx key={if_id} xxs="6" md={no_cols.value} className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
											<img src={BASE_URL+'pages/'+image.label} height="100%" width="100%" style={{ objectFit: 'cover' }} />
										</Colxx>
									}else{
										return "";
									}
								})}
							</Row>
						</div>
					</SRLWrapper>
			</CenterSection>
		}else if(name==="Facebook Feed"){
			let head = fields.find(r=>{ return r.key==='head' });
			let facebook_feed = fields.find(r=>{ return r.key==='facebook_feed' });
			
			return <div className="my-5" style={{ background:"#fff" }} key={index}>
					{head.value.length<50 ? <h4 className='h1 my-2 text-left'>
						{head.value}
					</h4>:<h3 className="text-left">
						{head.value}
					</h3>}
					
					{facebook_feed.value!="" ? <div className='fbs-container'>
						<iframe
							src={facebook_feed.value}
							width={340}
							height={553}
							style={{ border: 'none', overflow: 'hidden' }}
							scrolling="no" frameBorder="0"
							allowFullScreen="true"
							allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
						</iframe>
					</div>:""}
			</div>
		}else if(name==="Slider"){
			return <Slider
				key={index}
				className='q-section'
				fields={fields}
			/>
		}else if(name==="New Arrivals"){
			return <NewArrivalsSlider
				key={index}
				className='q-section'
				url="new_arrival_products"
				fields={fields}
				reduce_head={true}
			/>
		}else if(name==="About Us"){
			return <AboutUs
				key={index}
				className='q-section'
				fields={fields}
			/>
		}else if(name==="Shop By Category"){
			return <ShopByCategory
				key={index}
				className='q-section'
				fields={fields}
			/>
		}else if(name==="Instagram Feed"){
			let head = fields.find(r=>{ return r.key==='head' });
			let access_token = fields.find(r=>{ return r.key==='access_token' });
			let feed_count = fields.find(r=>{ return r.key==='feed_count' });
			if(access_token.value!=""){
				return <div className="my-2" key={index}>
						<div className="text-center pt-4 pb-2"><h1>{head.value}</h1></div>
						<Row>
							<Colxx xs={12} sm={12}>
								<InstagramFeed token={access_token.value} counter={feed_count.value!="" ? feed_count.value:"8"} />
							</Colxx>
						</Row>
					</div>
			}else{
				return "";
			}
		}else if(name==="Featured Products"){
			return <NewArrivalsSlider
				key={index}
				className='q-section'
				url="featured_products"
				fields={fields}
				reduce_head={false}
			/>
		}else if(name==="Recent Reviews"){
			return <RecentReviews
				key={index}
				className='q-section'
				fields={fields}
			/>
		}else if(name==="Get Exclusive News"){
			return <MailChimpIntegration key={index} />
		}
	}

    render() {

        const { template_data, seo } = this.state;
        var content_text = '';
        var banner_image = '';
        var page_name = '';
        var page_sub_title = '';
        if (template_data != null) {
            let content_text_row = (template_data.content.find(r => { return r.key == 'content_text' }));
            if (content_text_row != null) {
                content_text = content_text_row.value;
            }
            banner_image = template_data.thumbnail_image;
            page_name = template_data.name;
            page_sub_title = template_data.sub_title;
        }
		
		let templateContent = [];
		if(template_data!=null){
			for(let index=0; index<template_data.content.length; index++){
				let row = template_data.content[index];
				let isNextFacebook=false;
				if(typeof template_data.content[index+1]!='undefined'){
					if(template_data.content[index+1].name=="Facebook Feed" && row.name!="Slider"){
						isNextFacebook=true;
						
					}
				}
				if(isNextFacebook){
					let row2 = template_data.content[index+1];
					templateContent.push(<CenterSection style={{ background:"#fff" }} key={index}>
						<Row>
							<Colxx xs={12} md={8}>
								{this.generateField(row.name,row.fields,index)}
							</Colxx>
							<Colxx xs={12} md={4}>
								{this.generateField(row2.name,row2.fields,index+1)}
							</Colxx>
						</Row>
					</CenterSection>);
					index++;
				}else{
					templateContent.push(this.generateField(row.name,row.fields,index))
				}
				
			}
		}
		
        return (
            <Fragment>
				<SuperSEO
					  title={seo.title}
					  description={seo.metaDescription}
					  lang="en"
					  openGraph={{
						ogImage: {
						  ogImage: SITE_URL+"assets/img/favicon.png",
						  ogImageAlt: projectTitle,
						  ogImageWidth: 862,
						  ogImageHeight: 748,
						  ogImageType: "image/png",
						},
					  }}
					  twitter={{
						twitterSummaryCard: {
						  summaryCardImage: SITE_URL+"assets/img/favicon.png",
						  summaryCardImageAlt: projectTitle,
						  summaryCardSiteUsername: "skyniche",
						},
					  }}
				/>
                <ToastProvider placement="bottom-left">
                    <div className="main-pages-layout landing-root">
                        <main className={this.state.alias_key.split('-').join('')}>
                            <HomeHeader />
							<SimpleReactLightbox>
								<div className="">
									{this.state.show_title_head ? <Row className='q-section-main'>
										<Colxx xxs={12} md={6} className='p-5 d-flex justify-content-center align-items-center'>
											<div>
												<h1 className="h1">{page_name}</h1>
											</div>
										</Colxx>
										<Colxx xxs={12} md={6}>
											<div className='qual-header-img q-header-img' style={{background:`url('${BASE_URL}pages/${banner_image}')`}} />
										</Colxx>
									</Row>:""}

									{templateContent}
									
								</div>
							</SimpleReactLightbox>
                            <HomeFooter />

                        </main>
                    </div>
                </ToastProvider>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default commonPage;
