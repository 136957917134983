import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";
import { displayDateFormat } from "../../../../helpers/Utils";
import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class InventoryAdjustment extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			categories:[],
			sub_categories:[{ label:"--All--", value:0, key:0 }],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			category_id: { label:"--All--", value:0, key:0 },
			sub_category: { label:"--All--", value:0, key:0 },
			blocking: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
		this.selectCategory = this.selectCategory.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}	
		
		if(this.state.sub_category!=null){
			formData.append('sub_category',this.state.sub_category.value);
		}else{
			formData.append('sub_category',0);
		}
		
		if(this.state.category_id!=null){
			formData.append('category_id',this.state.category_id.value);
		}else{
			formData.append('category_id',0);
		}
		
		axios.post(REST_API_END_POINT+'report/inventory-adjustment',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				var categories = data.categories;
				categories = categories.map(row=>{
					row.label=row.name;
					row.value=row.category_id;
					row.key=row.category_id;
					return row;
				});
				categories.unshift({ label:"--All--", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					categories,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					categories: [],
					sub_categories: [{ label:"--All--", value:0, key:0 }],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	selectCategory() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.category_id!=null){
			formData.append('category_id',this.state.category_id.value);
		}else{
			formData.append('category_id',0);
		}
		
		axios.post(REST_API_END_POINT+'report/get-sub-category',formData).then((res)=>{
			var data = res.data;
			if(data.categories){				
				var sub_categories = data.categories;
				sub_categories = sub_categories.map(row=>{
					row.label=row.name;
					row.value=row.category_id;
					row.key=row.category_id;
					return row;
				});
				sub_categories.unshift({ label:"--All--", value:0, key:0 });
				
				this.setState({
					sub_categories,
					blocking:false
				});
			}else{
				this.setState({
					sub_categories: [],
					blocking:false
				});
			}				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', {
						extend: 'pdfHtml5',
						orientation: 'landscape',
						pageSize: 'LEGAL'
					}, 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		for(let row of reports){
			formated_data.push([
				row.product_name,
				row.barcode,
				row.old_qty+' '+row.qty_type,
				row.new_qty+' '+row.qty_type,
				row.difference+' '+row.qty_type,
				row.percent+'%',				
				moment(row.timestamp*1000).format(displayDFormat+" hh:mm a"),
				row.authorized_by,
				row.reason
			])
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		const { categories, sub_categories } = this.state;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.inventory-adjustment" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="6">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat={displayDFormat+" hh:mm a"}
								/>
							</Colxx>
							
							<Colxx lg="6">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat={displayDFormat+" hh:mm a"}
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label>Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="category_id"
									id="category_id"
									placeholder="All"
									value={this.state.category_id}						
									options={categories}
									onChange={category_id => { this.setState({ category_id },()=>{ this.selectCategory() }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Sub Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="sub_category"
									id="sub_category"
									placeholder="All"
									value={this.state.sub_category}						
									options={sub_categories}
									onChange={sub_category => { this.setState({ sub_category }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Product Name</th>
												<th>Barcode</th>
												<th>Old Qty</th>
												<th>New Qty</th>
												<th>Difference</th>
												<th>Percent</th>
												<th>Date</th>
												<th>User</th>
												<th>Reason</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
