import React, { Component } from "react";
import { AvForm, AvField, AvGroup,AvRadio,AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx } from "../../../components/common/CustomBootstrap";

import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import { getCurrencySymbol } from "../../../helpers/Utils";
let currencySymbol = getCurrencySymbol();

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const couponTypes = [
	{ label: currencySymbol, value: "0", key: 0 },
	{ label: "%", value: "1", key: 1 }
];

const UsageLimitOptions = [
	{ label: "--Select Limit--", value: "0", key: -1 },
	{ label: "Per Day", value: "1", key: 0 },
	{ label: "Per Week", value: "2", key: 1 },
	{ label: "Per Month", value: "3", key: 2 },
	{ label: "Per Year", value: "4", key: 3 }
];

const selectType = [
	{ label: "--Select Limit--", value: "0", key: -1 },
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
];
  
class AddNewCouponModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			start_date: null,
			end_date: null,
			selectedType:{ label: currencySymbol, value: "0", key: 0 },
			auto_apply_yes:false,
			auto_apply_yes_value:1,
			auto_apply_no_value:0,
			auto_apply_no:true,
			selectProducts:[],
			selectCategories:[],
			product_val:[],
			category_val:[],
			use_limit_type: { label: "--Select Limit--", value: "0", key: -1 },
			customer_type: { label: "--Select Customer Type--", value: "0", key: -1 },
			discountCode: null
		};
	}
	
	
	componentDidMount() {		
		var products=[];
		var categories=[];
		var discountCode=null;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'point-of-sale/get-product-and-category-for-discount',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
			categories=data.categories;
			discountCode=data.discount_code;
			
			products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectProducts:products,
				selectCategories:categories,
				discountCode:discountCode
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			let vStartDate = document.getElementById("start_date").value.trim();
			let vEndDate = document.getElementById("end_date").value.trim();
			
			let errorMessage='';
			if(vStartDate.length==0){
				errorMessage+="Start Date is empty\n";
			}
			if(vEndDate.length==0){
				errorMessage+="End Date is empty\n";
			}

			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			var productVal= this.state.product_val.map(row=>row.value);
			var categoryVal= this.state.category_val.map(row=>row.value);
			
			document.getElementById("add-coupon").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("customercouponForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('product_id',productVal);
			formData.append('category_id',categoryVal);

			axios.post(REST_API_END_POINT+'point-of-sale/add-discount',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-coupon").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				this.setState({				
					discountCode:this.props.discountCode
				});	

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-coupon").disabled=false;
			});

		}
	}

    showRadio = e =>{
		var elements = document.querySelectorAll('input[name="is_product"]');
		var value='0';
		for (var element of elements) {
			if(element.checked==true){
				value=element.value;
			}
		}
	
		if(value==='1'){
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='2'){
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='3'){
			document.getElementById('show_products').style.display='block';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='4'){
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='block';
		}
	}

	render() {
		const {  selectProducts,selectCategories,start_date,end_date } = this.state;
		var product_val=[];
		var category_val=[];
		const today = new Date();
		const checkInValidationMessage = "This field is required";
		
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add Discount Program
			</ModalHeader>
			<AvForm
		        id="customercouponForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The  name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Code<span className="text-danger">*</span></Label>
							  <AvField
								id="code"
								name="code"
								type="text"
								autoComplete="off"
								value={this.state.discountCode}
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Discount<span className="text-danger">*</span></Label>
							  <AvField
								id="amount"
								name="amount"
								type="number"
								min='0'
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter numeric value"
									},
									max:{
										value: this.state.selectedType.value=="1" ? "100":"10000000000000000000",
										errorMessage: "Please enter a value less than or equal to "+(this.state.selectedType.value=="1" ? "100":"10000000000000000000")
									}
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<Label>Type<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="type"
								id="type"
								value={this.state.selectedType}
								options={couponTypes}
								onChange={selectedType => { this.setState({ selectedType }) }}									
							/>
						</Colxx>
					</Row>
					<Row className="mt-2">
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Start Date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									selected={this.state.start_date}
									minDate={new Date()}
									onChange={(e) => {this.setState({ start_date: e });}}
									placeholderText=""
									autoComplete="off"
									dateFormat="YYYY-MM-DD"
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>End Date<span className="text-danger">*</span></Label>
								 <DatePicker
									id="end_date"
									name="end_date"
									selected={this.state.end_date}
									minDate={(start_date)?start_date:(new Date())}
									onChange={ e => {
										if(start_date){
											this.setState({end_date: e })
										}else{
											swal("Warning", "End Date should be greater than!", "warning").then(()=>{
												this.setState({end_date:null});
											});
										}
									}}
									placeholderText=""
									autoComplete="off"
									dateFormat="YYYY-MM-DD"
									required={true}
									min={today}
									validationMessage={checkInValidationMessage}
								  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Usage Limit</Label>
							  <AvField
								id="use_limit"
								name="use_limit"
								type="number"
								autoComplete="off"
								min='0'
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-l-100 error-t-negative">
								<Label>&nbsp;&nbsp;</Label>
							   <Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="use_limit_type"
									id="use_limit_type"
									value={this.state.use_limit_type}
									onChange={use_limit_type => { this.setState({ use_limit_type }) }}
									options={UsageLimitOptions}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Maximum use per distributor<span className="text-danger">*</span></Label>
							  <AvField
								id="max_uses"
								name="max_uses"
								type="number"
								min='0'
								autoComplete="off"
								validate={{
										required: {
										value: true,
										errorMessage: "Please enter numeric value"
									  }
									}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row >
						<Colxx lg="12" className="mt-2 mb-2" >
							<Label>Buy Product In </Label>
							<AvRadioGroup
								inline
								name="is_product"
								onChange={(e) => this.showRadio(e)}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the options"
								  }
								}}
								defaultValue='1'
								>
								<AvRadio customInput label="No Product" value="1" />
								<AvRadio customInput label="All Products" value="2" />
								<AvRadio customInput label="Specific Products" value="3" />
								<AvRadio customInput label="Specific Categories" value="4" />
							</AvRadioGroup >
						</Colxx>

					</Row>
					
				<div id="show_categories" style={{display:(this.state.is_product===4)?'block':'none'}}>
					<Label >Choose Categories</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti						
						name="category_val[]"
						value={this.state.category_val}						
						onChange={category_val => { this.setState({ category_val:category_val }) }}
						options={this.state.selectCategories}
					/>
				</div>
				<div id="show_products" style={{display:'none'}}>
					<Label >Choose Products</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						name="product_val[]"						
						value={this.state.product_val}						
						onChange={product_val => { this.setState({ product_val:product_val}) }}
						options={this.state.selectProducts}
					/>
				</div>

				<Row className="mt-2">
					<Colxx lg="12" className="mt-2 mb-2">
						<Label>Auto Apply</Label><br/>
						<Label>
							<CustomInput
								id="auto_apply_yes"
								type="radio"
								name="auto_apply"
								value={this.state.auto_apply_yes_value}
								checked={this.state.auto_apply_yes}
								onChange={() => { this.setState({ auto_apply_yes:true,auto_apply_no:false }) }}
								className="d-inline-block"
							/>
							{" "}Yes
						</Label>&nbsp;&nbsp;
						<Label>
						  <CustomInput
							id="auto_apply_no"
							type="radio"
							name="auto_apply"
							value={this.state.auto_apply_no_value}
							checked={this.state.auto_apply_no}
							onChange={() => { this.setState({ auto_apply_yes:false,auto_apply_no:true }) }}
							className="d-inline-block"
						  />
							{" "}No
						</Label>&nbsp;&nbsp;
					</Colxx>
				</Row>
				<Row className="d-none">
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Type of Customer</Label>
						  <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="customer_type"
								id="customer_type"
								value={this.state.customer_type}						
								onChange={customer_type => { this.setState({ customer_type:customer_type}) }}
								options={selectType}
							/>
						</AvGroup>
					</Colxx>
				</Row>
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-coupon" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewCouponModal;
