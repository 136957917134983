import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Col, Input, CustomInput, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { uuid } from 'uuidv4';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { ToastProvider } from "react-toast-notifications";
import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL, SITE_URL, IG_TOKEN, AGE_WARNING } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import DealSlideList from "../components/front-end/DealSlideList";
import CommonPage from "./commonPage";

import moment from "moment";

import { isMobile, isAndroid } from "react-device-detect";

import { seo } from "../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmed: false,
			show_facilities: false,
			facilities: [],
			home_sliders: [],
			happy_hour_sliders: [],
			categories: [],
			posts: [],
			testimonials: [],
			featured_products: [],
			new_arrival_products: [],
			top_deal_products: [],
			google_reviews: [],
			auth_background:SITE_URL+'/assets/img/balloon.jpg'
		};
		this.setUserAgeToken = this.setUserAgeToken.bind(this);
		this.loadHomePage = this.loadHomePage.bind(this);
		this.goNextReview = this.goNextReview.bind(this);
		this.goPrevReview = this.goPrevReview.bind(this);

		this.swiperRef = React.createRef();
		seo({
			title: 'Home | ' + projectTitle,
		});
	}

	componentDidMount() {

		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var formData = new FormData();
		formData.append('subscriber_id', 1);

		axios.post(REST_API_END_POINT + 'front-end/get-facilities', formData).then((res) => {
			var data = res.data;

			let auth_background = SITE_URL+'/assets/img/balloon.jpg';
			var facilities = data.facilities;
			if(facilities.length>0){
				if(facilities[0].auth_background!=null){
					auth_background = BASE_URL+'pages/'+facilities[0].auth_background;
				}
			}
			
			this.setState({
				facilities,
				auth_background
			});
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
		this.loadHomePage();
	}

	loadHomePage() {
		var facility_id = localStorage.getItem('facility_id');
		if (facility_id) {
			/*facility_id = dataCrypto.decrypt(facility_id);
			var data_upload = new FormData();
			data_upload.append('facility_id', facility_id);
			data_upload.append('user_time', moment().format('HH:mm:00'));
			axios.post(REST_API_END_POINT + 'website/home-page', data_upload).then((res) => {
				if (res.data.status == 1) {
					this.setState({
						home_sliders: res.data.home_sliders,
						happy_hour_sliders: res.data.happy_hour_sliders,
						categories: res.data.categories,
						posts: res.data.posts,
						testimonials: res.data.testimonials,
						featured_products: res.data.featured_products,
						new_arrival_products: res.data.new_arrival_products,
						top_deal_products: res.data.top_deal_products,
						google_reviews: res.data.google_reviews,
						blocking: false
					});
				} else {
					this.setState({ blocking: false });
				}
			}).catch(e => {
				console.log("Addition failed , Error ", e)
				this.setState({ blocking: false });
			});*/
		}
	}

	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}

	setUserAgeToken(event) {
		event.preventDefault();
		localStorage.setItem('user_age_token', uuid());
		this.setState({ show_facilities: true });
	}

	goNextReview() {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}

	goPrevReview = () => {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	};

	render() {
		const { home_sliders, happy_hour_sliders, categories, featured_products, new_arrival_products, top_deal_products, posts, testimonials, google_reviews } = this.state;

		
		const user_age_token = AGE_WARNING ? localStorage.getItem('user_age_token'):uuid();
		const facility_id = localStorage.getItem('facility_id');

		if (user_age_token != null && facility_id != null) {
			return (
				<Fragment>
					<ToastProvider placement="bottom-left">
						<div className="main-pages-layout">
							<main>
								<CommonPage alias_key="home" />

								
								{/*top deals start */}
								{/*top_deal_products.length ? <Fragment><div className="text-center pt-4 pb-2 d-none"><h1>TOP DEALS</h1></div>
									<div className="shop-area pb-5 d-none">
										<div className="container-fluid">
											<div className="row">
												<div className="col-lg-12 shop-bottom-area mobile-slide-padding hide-shop-list-wrap ">
													
													{top_deal_products.length > 3 || (top_deal_products.length > 1 && (isMobile || isAndroid)) ? <Swiper {...paramsone}>
														{top_deal_products.map((product, i) => {
															return (
																<DealSlideList
																	spaceBottomClass={"mb-3"}
																	sliderClassName={"swiper-slide col-12 "}
																	product={product}
																	key={i}
																/>
															);
														})}
													</Swiper> : <div className="row">{top_deal_products.map((product, i) => {
														return (
															<DealSlideList
																spaceBottomClass={"mb-3"}
																sliderClassName={"swiper-slide col-12 "}
																product={product}
																key={i}
															/>
														);
													})}</div>}
												</div>
											</div>
										</div>
									</div></Fragment> : ""*/}
								{/*top deals ends */}

								{/*client starts *}
										{testimonials.length>0 ? <div className="container testimonial-wrapper pb-4 pt-4">
											<div className="row">
												{testimonials.map((row,i)=>{
													return(<div key={i} className="col-lg-3 text-center">
														{row.featured_image!=null ? <img
																	className="default-img img-responsive"
																	src={BASE_URL+'pages/'+row.featured_image}
																	alt=""
														/>:""}
														  <div><p className="pt-2 black text-center">"{row.content}"</p></div>
													</div>)
												})}
											</div>
										</div>:""}
									{/*client ends */}

								{/*client starts */}

							</main>
						</div>
					</ToastProvider>
				</Fragment>
			);
		} else if (user_age_token == null && AGE_WARNING) {
			return (<Fragment>
				<div className="h-100">
					<div className="fixed-background" style={{backgroundImage:`url(${this.state.auth_background})`}} />
					<main>
						<div className="container">
							<Row className="h-100">
								<Colxx xxs="12" md="6" className="mx-auto my-auto">
									<Card className="auth-card" >
										<div className="form-side" style={{ padding: '50px' }}>
											<NavLink to={`/`} className="white">
												<span className="logo-single" />
											</NavLink>
											<CardTitle className="mb-4 text-center text-uppercase font-weight-bold">
												Please confirm your age to move forward
											</CardTitle>
											<Form onSubmit={this.setUserAgeToken} className="text-center">
												<div className="d-flex justify-content-center align-items-center mt-2">
													<CustomInput
														id="confirm_check21"
														name="confirm_check"
														type="radio"
														value="21"
														onClick={(e) => { this.setState({ confirmed: true }) }}
														label="Yes, I am 21 years or older"
													/>
												</div>
												<div className="d-flex justify-content-center align-items-center mt-2">
													<Button
														color="primary"
														className="btn-shadow"
														size="lg"
														type="submit"
														disabled={!this.state.confirmed}
													>
														{`Enter >>`}
													</Button>
												</div>
											</Form>

										</div>
									</Card>
								</Colxx>
							</Row>
						</div>
					</main>
				</div>
			</Fragment>)
		} else {
			return (<Fragment>
				<div className="h-100">
					<div className="fixed-background" style={{backgroundImage:`url(${this.state.auth_background})`}} />
					<main>
						<div className="container">
							<Row className="h-100">
								<Colxx xxs="12" md="6" className="mx-auto my-auto">
									<Card className="auth-card" >
										<div className="form-side" style={{ padding: '50px' }}>
											<NavLink to={`/`} className="white">
												<span className="logo-single" />
											</NavLink>
											<CardTitle className="mb-4 text-center text-uppercase font-weight-bold">
												Please Select The Store
											</CardTitle>
											<div className="d-flex justify-content-center align-items-center">
												<div className="text-center w-80 mt-2">
													{this.state.facilities.map(row => {
														return (<Button
															key={row.id}
															block
															color="primary"
															className="btn-shadow"
															size="lg"
															type="button"
															onClick={(e) => {
																localStorage.setItem('facility_id', dataCrypto.encrypt(row.id));
																this.setState({ show_facilities: false });
																this.loadHomePage();
															}}
														>
															{row.name}
														</Button>)
													})}
												</div>
											</div>

										</div>
									</Card>
								</Colxx>
							</Row>
						</div>
					</main>
				</div>
			</Fragment>)
		}
	}
}

const mapStateToProps = ({ authUser }) => {
	const { user, loading } = authUser;
	return { user, loading };
};

export default connect(
	mapStateToProps,
	{
		loginUser
	}
)(Home);
