import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm, AvField } from "availity-reactstrap-validation";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { getCurrencySymbol, displayDateFormat } from "../../../../helpers/Utils";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class SalesDemographics extends Component {
	
	constructor(props) {
		super(props);
		
		let currencySymbol = getCurrencySymbol(false);
		this.state = {		 
			reports:[],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			top: 10,
			blocking: true,
			currencySymbol
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}
		
		if(this.state.top!=null){
			formData.append('top',this.state.top);
		}else{
			formData.append('top',10);
		}
		
		axios.post(REST_API_END_POINT+'report/sales-demographics',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				this.setState({
					reports: data.reports,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el1 = $(this.el1);			
		this.$el1.DataTable().clear().destroy();
		this.$el1.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('zip_non_members'),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
			
		this.$el2 = $(this.el2);			
		this.$el2.DataTable().clear().destroy();
		this.$el2.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('zip_members'),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
			
		this.$el3 = $(this.el3);			
		this.$el3.DataTable().clear().destroy();
		this.$el3.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('zip_overall'),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
			
		this.$el4 = $(this.el4);			
		this.$el4.DataTable().clear().destroy();
		this.$el4.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('age_non_members'),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
			
		this.$el5 = $(this.el5);			
		this.$el5.DataTable().clear().destroy();
		this.$el5.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('age_members'),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
			
		this.$el6 = $(this.el6);			
		this.$el6.DataTable().clear().destroy();
		this.$el6.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('age_overall'),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(key){
		const reports = this.state.reports;
		var formated_data=[];
		if(typeof reports[key]!=='undefined'){
			for(let row of reports[key]){
				formated_data.push([
					key=='zip_non_members' || key=='zip_members' || key=='zip_overall' ? row.zip_code:row.age,
					row.visits,
					row.percent_by_visits,
					this.state.currencySymbol+row.amount,
					row.percent_by_amount
				])
			}
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.sales-demographics" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="4">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat={displayDFormat+" hh:mm a"}
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat={displayDFormat+" hh:mm a"}
								/>
							</Colxx>
							
							<Colxx lg="2">
								<Label>Top<span className="text-danger">*</span></Label>
								<AvField
									name="top"
									id="top"
									value={this.state.top}
									onChange={e=>{
										this.setState({top:e.target.value});
									}}
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<h2>Non-members</h2>
									<table className="display table table-bordered table-striped" ref={el=>this.el1=el} >
										<thead>
											<tr>				
												<th>Zip</th>
												<th>Visits</th>
												<th>% By Visits</th>
												<th>Amount</th>
												<th>% By Amount</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
								
								<div className="mb-4 dropdown-edit">
									<h2>Members</h2>
									<table className="display table table-bordered table-striped" ref={el=>this.el2=el} >
										<thead>
											<tr>				
												<th>Zip</th>
												<th>Visits</th>
												<th>% By Visits</th>
												<th>Amount</th>
												<th>% By Amount</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
								
								<div className="mb-4 dropdown-edit">
									<h2>Overall</h2>
									<table className="display table table-bordered table-striped" ref={el=>this.el3=el} >
										<thead>
											<tr>				
												<th>Zip</th>
												<th>Visits</th>
												<th>% By Visits</th>
												<th>Amount</th>
												<th>% By Amount</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
								
								<div className="mb-4 dropdown-edit">
									<h2>Non-members</h2>
									<table className="display table table-bordered table-striped" ref={el=>this.el4=el} >
										<thead>
											<tr>				
												<th>Age</th>
												<th>Visits</th>
												<th>% By Visits</th>
												<th>Amount</th>
												<th>% By Amount</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
								
								<div className="mb-4 dropdown-edit">
									<h2>Members</h2>
									<table className="display table table-bordered table-striped" ref={el=>this.el5=el} >
										<thead>
											<tr>				
												<th>Age</th>
												<th>Visits</th>
												<th>% By Visits</th>
												<th>Amount</th>
												<th>% By Amount</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>
								
								<div className="mb-4 dropdown-edit">
									<h2>Overall</h2>
									<table className="display table table-bordered table-striped" ref={el=>this.el6=el} >
										<thead>
											<tr>				
												<th>Age</th>
												<th>Visits</th>
												<th>% By Visits</th>
												<th>Amount</th>
												<th>% By Amount</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
