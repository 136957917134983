import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import moment from "moment";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, Table, 
  Input, Badge,
  DropdownMenu, DropdownItem,
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, INVENTORY_SOURCES} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import { getCurrencySymbol } from "../../../helpers/Utils.js";

import AddVariationModal from "./Variations/AddVariationModal";
import EditVariationModal from "./Variations/EditVariationModal";
import VariationViewStockModal from "./Variations/VariationViewStockModal";
import AddFinishedProductModal from "./FinishedPackages/AddFinishedProductModal";
import AdjustInventoryModal from "./Packages/AdjustInventoryModal";

const variation_types = {
						'0' : 'Milligram', 
						'1' : 'Gram', 
						'2' : 'Kilogram', 
						'3' : 'Unit', 
						'4' : 'Liter', 
						'5' : 'Cartridge', 
						'6' : 'Shatter', 
						'7' : 'Roll', 
						'8' : 'Case',
						'9' : 'Battery'
					};
const qty_types = {
				'0' : 'mg', 
				'1' : 'g', 
				'2' : 'kg', 
				'3' : 'units', 
				'4' : 'L', 
				'5' : 'mg', 
				'6' : 'mg', 
				'7' : 'mg', 
				'8' : '',
				'9' : 'units'
			};


var dataCrypto = new SimpleCrypto(dataEncryptKey);

const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

export default class Variations extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		
		let currencySymbol = getCurrencySymbol();
		this.state = {
			selectedIds: [],
			selectAll: 0,
			modalOpenAddFinishedProduct: false,
			modalOpenAddVariation: false,
			modalOpenEditVariation: false,
			modalOpenViewStock: false,
			product_variation:null,
			search:"",
			currencySymbol
		};

		this.toggleModalAddVariation = this.toggleModalAddVariation.bind(this);
		this.toggleModalEditVariation = this.toggleModalEditVariation.bind(this);
		this.toggleModalViewStock = this.toggleModalViewStock.bind(this);
		this.toggleModalAddFinishedProduct = this.toggleModalAddFinishedProduct.bind(this);
		this.toggleModalAdjustInventory = this.toggleModalAdjustInventory.bind(this);
		
		this.activeItem = this.activeItem.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.props.product_variations.forEach(x => {
                newSelected.push(x.variation_id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	activeItem=(e,variation_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(variation_id);			
		}else{
			var x=selectedIds.indexOf(variation_id);
			if(x>=0){
				selectedIds.splice(x,1);
			}
		}
		if(selectedIds.length!=0 && selectedIds.length==this.props.product_variations.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAddFinishedProduct = () => {
		this.setState({
			modalOpenAddFinishedProduct: !this.state.modalOpenAddFinishedProduct
		});
	};
	
	toggleModalAdjustInventory = (e,package_id=null) => {
		if(package_id==null){
			this.setState({
				product_package: null,
				modalOpenAdjustInventory: !this.state.modalOpenAdjustInventory,
			});
		}else{
			var formData = new FormData();
			formData.append('package_id',package_id);
			
			axios.post(REST_API_END_POINT+'inventory/view-finished-detail',formData).then((res)=>{
				var data = res.data;
				const product_package=data.package_detail;
				
				this.setState({ 
					product_package,
					modalOpenAdjustInventory: !this.state.modalOpenAdjustInventory,
				});				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				this.setState({ blocking:false });
			});	
		}
	};
	
	
	toggleModalAddVariation = () => {
		this.setState({
			modalOpenAddVariation: !this.state.modalOpenAddVariation
		});
	};
	
	toggleModalViewStock = (e,product_variation=null) => {
		this.setState({
			product_variation: product_variation,
			modalOpenViewStock: !this.state.modalOpenViewStock,
		});
	};
	
	toggleModalEditVariation = (e,product_variation=null) => {
		this.setState({
			product_variation: product_variation,
			modalOpenEditVariation: !this.state.modalOpenEditVariation,
		});
	};
	
	deleteVariations = (e,selectedId=null)  => {		
		var selectedIds = selectedId==null ? this.state.selectedIds:selectedId;
		if(selectedIds.length<1){
			swal("Warning","Please select at least one variation","warning");
			return;
		}
		
        swal({
          title: "Are you sure?",
          text: "Delete the product variations?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-product-variations").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);	
					
					var formData = new FormData();							
					formData.append('facility_id',facility_id);			
					formData.append('variation_ids',JSON.stringify(selectedIds));
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-product-variations',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-product-variations").disabled=false;
						
						var api_message=document.getElementById("api_message_variations");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.props.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-product-variations").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
  
	render() {
		var data = [];
		if(typeof this.props.product_variations!='undefined'){
			data = JSON.parse(JSON.stringify(this.props.product_variations));	
			data = data.map(row=>{				
				row.qty_type_name=qty_types[row['qty_type']];
				row.variation_type=variation_types[row['qty_type']];
				
				if(row['qty_type']==2){	
					if(row['quantity']==0.125){
						row.quantity_value = '1/8';
					}else if(row['quantity']==0.25){
						row.quantity_value = '1/4';
					}else if(row['quantity']==0.5){
						row.quantity_value = '1/2';
					}else{
						row.quantity_value = parseFloat(row['quantity']);
					}
				}else{
					row.quantity_value = parseFloat(parseFloat(row['quantity']).toFixed(2));
				}

				if(row.sub_category_name==null){
					row.sub_category_name="";
				}
				return row;
			});
		}
		
		if (this.state.search) {
			data = data.filter(row => {
				if(row.sub_category_name==null){
					row.sub_category_name='';
				}
				return row.variation_name.toLowerCase().includes(this.state.search) || row.quantity_value.toString().toLowerCase().includes(this.state.search) || row.sub_category_name.toLowerCase().includes(this.state.search) || row.variation_type.toLowerCase().includes(this.state.search) 
			})
		}
	  
		const dataTableColumns = [			  
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "variation_id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"variation_id_"+rowInfo.original.variation_id} onChange={event =>this.activeItem(event,rowInfo.original.variation_id) } checked={this.state.selectedIds.includes(rowInfo.original.variation_id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "variation_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>,
			},
			{
				Header: "Quantity",
				accessor: "quantity_value",
				Cell: props => <p className="list-item-heading">{props.value+' '+props.original.qty_type_name}</p>
			},
			{
				Header: "Sub Category",
				accessor: "sub_category_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			/*{
				Header: "Price",
				accessor: "variation_price",
				Cell: props => <p className="list-item-heading">{this.state.currencySymbol}{props.value}</p>
			},*/
			{
				Header: "Variation Type",
				accessor: "variation_type",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Stock",
				accessor: "real_stock",
				Cell: props => <p className="list-item-heading font-weight-bold">{parseInt(props.value)}</p>
			},
			{
				Header: "Created Date",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(parseInt(props.value)*1000).format('MM-DD-YYYY')}</p>
			},
			{
				Header: "Options",
				accessor: "variation_id",
				width:70,
				sortable: false,
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
									{props.original.package_id!=null ? <DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalAdjustInventory(e,props.original.package_id) }} >
										<span className="d-inline-block" >Adjust Inventory</span>				
									</DropdownItem>:""}								
									<DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalEditVariation(e,props.original) }} >
										<span className="d-inline-block" >Edit</span>				
									</DropdownItem>
									<DropdownItem className="cursor-pointer" onClick={e=>{ this.deleteVariations(e,[props.value]) }} >
										<span className="d-inline-block" >Delete</span>				
									</DropdownItem>
								</DropdownMenu>}
							/>
						</div>
					</Fragment>
			}
	];
	var product_type=1;
	if(this.props.product!=null){
		product_type=parseInt(this.props.product.product_type);
	}
	
    return( 
		<Fragment>
			<div id="api_message_variations"></div>
			<div id="api_message_finished_products"></div>
			<div className="float-sm-right">
				{this.props.product!=null && this.props.product_variations.length && product_type==2 ? <Button
					  color="primary"
					  size="sm"
					  className=""
					  onClick={this.toggleModalAddFinishedProduct}
					>
					Add Stock
				</Button>:""}
				
				{" "}<Button
					  color="primary"
					  size="sm"
					  className=""
					  onClick={this.toggleModalAddVariation}
					>
					Add Variation
				</Button>
				{" "}<Button
					color="primary"
					size="sm"
					id="delete-product-variations"
					className=""
					onClick={this.deleteVariations}
				  >
					Delete Variations
				</Button>
			</div>
			<div className="mb-4 dropdown-edit small-padd-table">	
				<FormGroup row>
					<Colxx lg="6">
						<Input
							type="text"
							name="variation_table_search"
							id="variation_table_search"
							placeholder="Search"
							value={this.state.search}
							onChange={e => this.setState({search: e.target.value.toLowerCase()})}
						/>
					</Colxx>
				</FormGroup>
				<ReactTable
					data={data}
					TbodyComponent={CustomTbodyComponent}
					columns={dataTableColumns}
					defaultPageSize={10}
					minRows = {2}
					filterable={false}
					showPageJump={false}
					PaginationComponent={DataTablePagination}
					showPageSizeOptions={true}
					defaultSorted={[{id: "variation_id",desc: true}]}
				/>
			</div>
			
			{this.props.product!=null ? <AddVariationModal
				modalOpen={this.state.modalOpenAddVariation}
				toggleModal={this.toggleModalAddVariation}
				product={this.props.product}
				sub_categories={this.props.sub_categories}
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.state.product_variation!=null ? <EditVariationModal
				modalOpen={this.state.modalOpenEditVariation}
				toggleModal={this.toggleModalEditVariation}
				product={this.props.product}
				product_variation={this.state.product_variation}
				sub_categories={this.props.sub_categories}
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.state.product_variation!=null ? <VariationViewStockModal
				modalOpen={this.state.modalOpenViewStock}
				toggleModal={this.toggleModalViewStock}
				product={this.props.product}
				product_variation={this.state.product_variation}				
				product_id={this.props.product_id}
				dataListRender={this.props.dataListRender}
				finished_products={this.props.finished_products}
				product_variations={this.props.product_variations}
				unused_packages={this.props.unused_packages}
				facilities={this.props.facilities}
				warehouses={this.props.warehouses}
				facility={this.props.facility}
			/>:""}
			
			{this.props.product!=null && this.props.product_variations.length && product_type==2 ? <AddFinishedProductModal
				modalOpen={this.state.modalOpenAddFinishedProduct}
				toggleModal={this.toggleModalAddFinishedProduct}
				product={this.props.product}
				product_variations={this.props.product_variations}
				facilities={this.props.facilities}
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.state.product_package!=null ? <AdjustInventoryModal
				modalOpen={this.state.modalOpenAdjustInventory}
				toggleModal={this.toggleModalAdjustInventory}
				product={this.props.product}
				product_package={this.state.product_package}
				dataListRender={this.props.dataListRender}
				api_message={"api_message_finished_products"}
			/>:""}
			
		</Fragment>
    );
  }
  
};
