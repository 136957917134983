import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import swal from 'sweetalert';
import reactCSS from 'reactcss'
import { ChromePicker } from 'react-color';
 
import 'react-color-picker/index.css'

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
	[{ 'align': [] }],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    [{ 'color': [] }],
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",  
  "list",
  "bullet",
  "indent", 
  "color", 
];

class AddHappyHourSliderModal extends Component{
	constructor(props) {
		super(props);
		this.state={
			text_content:'',
			is_mobile:false,
			cover_height:100,
			displayColorPicker:false,
			displayBgColorPicker:false,
			color:'#fff',
			bg_color:'#000'
		}
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	
	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};
	
	handleBgClick = () => {
		this.setState({ displayBgColorPicker: !this.state.displayBgColorPicker })
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false })
	};
	
	handleBgClose = () => {
		this.setState({ displayBgColorPicker: false })
	};

	handleChange = (color) => {
		this.setState({ color: color.hex })
	};
	
	handleBgChange = (color) => {
		this.setState({ bg_color: color.hex })
	};
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			if(document.getElementById("featured_image").value==''){
				swal("Warning","Please select the slider image","warning");
				return;
			}
			
			document.getElementById("add-post").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("postForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('content',this.state.text_content);
			formData.append('button_color',this.state.color);
			formData.append('button_background_color',this.state.bg_color);
			
			axios.post(REST_API_END_POINT+'website/add-happy-hour-slider',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-post").disabled=false;
				this.setState({ text_content:'' });
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-post").disabled=false;
			});

		}
	}

	render() {
		const styles = reactCSS({
		  'default': {
			color: {
			  width: '42px',
			  height: '42px',
			  borderRadius: '2px',
			  background: `${ this.state.color }`,
			},
			bg_color: {
			  width: '42px',
			  height: '42px',
			  borderRadius: '2px',
			  background: `${ this.state.bg_color }`,
			},
			swatch: {
			  padding: '0px',
			  background: '#fff',
			  borderRadius: '1px',
			  boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
			  display: 'inline-block',
			  cursor: 'pointer',
			},
			popover: {
			  position: 'absolute',
			  zIndex: '999',
			},
			cover: {
			  position: 'fixed',
			  top: '0px',
			  right: '0px',
			  bottom: '0px',
			  left: '0px',
			  width:'100%',
			  height: `${ this.state.cover_height }px`,
			},
		  },
		});
		
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Happy Hour Slider
		  </ModalHeader>
		  <AvForm
		        id="postForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Post Content</Label>
							<ReactQuill
								name="content"
								theme="snow"
								value={this.state.text_content}
								onChange={(text_content)=>{
									this.setState({ text_content });
								}}					
								placeholder=""
								modules={quillModules}
								formats={quillFormats}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Button Link</Label>
							<AvField
								id="button_link"
								name="button_link"
								type="text"
								autoComplete="off"
								validate={{
									pattern: {
									value: "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$",								
									errorMessage: "Invalid website url"
								  },
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
							<Label>Button Text</Label>
							<AvField
								id="button_text"
								name="button_text"
								type="text"
								autoComplete="off"
							/>
						</AvGroup>
					</Colxx>
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
							<Label>Button Position</Label>
							<AvField
								id="button_position"
								name="button_position"
								type="select"
								autoComplete="off"
							>
								<option value="text-left">Left</option>
								<option value="text-center">Center</option>
								<option value="text-right">Right</option>
							</AvField>
						</AvGroup>
					</Colxx>
					<Colxx lg="2">
						<AvGroup className="error-t-negative">
							<Label>Button Color</Label>
							<div>
								<div style={ styles.swatch } onClick={ this.handleClick }>
								  <div style={styles.color} />
								</div>
								{ this.state.displayColorPicker ? <div style={ styles.popover }>
								  <div style={ styles.cover } onClick={ this.handleClose }/>
								  <ChromePicker color={ this.state.color } onChange={ this.handleChange } />
								</div> : null }

							</div>
						</AvGroup>
					</Colxx>
					<Colxx lg="2">
						<AvGroup className="error-t-negative">
							<Label>Button Background Color</Label>
							<div>
								<div style={ styles.swatch } onClick={ this.handleBgClick }>
								  <div style={styles.bg_color} />
								</div>
								{ this.state.displayBgColorPicker ? <div style={ styles.popover }>
								  <div style={ styles.cover } onClick={ this.handleBgClose }/>
								  <ChromePicker color={ this.state.bg_color } onChange={ this.handleBgChange } />
								</div> : null }

							</div>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Slider Image (Recommended Size: 1440x600)</Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="featured_image"
									id="featured_image"
									accept="image/png,image/jpeg,image/jpg,image/gif"
									onChange={(e)=>{							
										if(e.target.files[0]){
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
										}else{
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
										}
									}}
								/>
							</InputGroup>
						</AvGroup>
					</Colxx>					
				</Row>

				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Mobile Version (Recommended Size: 480x850)</Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="mobile_version"
									id="mobile_version"
									accept="image/png,image/jpeg,image/jpg,image/gif"
									onChange={(e)=>{							
										if(e.target.files[0]){
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
										}else{
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
										}
									}}
								/>
							</InputGroup>
						</AvGroup>
					</Colxx>					
				</Row>
			</ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-post" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddHappyHourSliderModal;
