const pageStructure = [
	{
		name:'Heading',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},
			{
				key:'button',
				label:'Button Text',
				type:'text',
				value:''
			},
			{
				key:'button_link',
				label:'Button Link',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Quote',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'quote',
				label:'Content',
				type:'text',
				value:''
			},
			{
				key:'author',
				label:'Author',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Left Right Block',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'image_url',
				label:'Image URL',
				type:'file',
				value:''
			},
			{
				key:'image_position',
				label:'Image Position',
				type:'select',
				options:[
					{key:1,label:'Left',value:'left'},
					{key:2,label:'Right',value:'right'},
				],
				value:'left'
			},
			{
				key:'image_hover',
				label:'Image Right Content',
				type:'text',
				value:''
			},
			{
				key:'content',
				label:'Content',
				type:'quill',
				value:''
			},
			{
				key:'background',
				label:'Background Color',
				type:'color',
				value:'#ffffff'
			},
			{
				key:'text_color',
				label:'Text Color',
				type:'color',
				value:'#303030'
			}
		]
	},
	{
		name:'Content Block',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'content',
				label:'Content',
				type:'quill',
				value:''
			}
		]
	},
	{
		name:'Product Slider',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},
			{
				key:'products',
				label:'Products',
				type:'select',
				options:[],
				value:[]
			},
		]
	},
	{
		name:'Slider',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},
			{
				key:'slider',
				label:'Slider',
				type:'select',
				options:[],
				value:""
			},
		]
	},
	{
		name:'Iframes',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},
			{
				key:'no_cols',
				label:'No. Cols',
				type:'select',
				options:[
					{key:1,label:'1',value:'12'},
					{key:2,label:'2',value:'6'},
					{key:3,label:'3',value:'4'},
					{key:4,label:'4',value:'3'},
				],
				value:'4'
			},
			{
				key:'iframes',
				label:'Iframes',
				type:'array',
				value:[{value:'',title:''}]
			},
		]
	},
	{
		name:'Articles',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},
			{
				key:'articles',
				label:'Articles',
				type:'array',
				value:[{value:'',title:''}]
			},
		]
	},
	{
		name:'LightBox Gallery',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},			
			{
				key:'images',
				label:'Images',
				type:'select',
				options:[],
				value:[]
			},
			{
				key:'no_cols',
				label:'No. Cols',
				type:'select',
				options:[
					{key:1,label:'1',value:'12'},
					{key:2,label:'2',value:'6'},
					{key:3,label:'3',value:'4'},
					{key:4,label:'4',value:'3'},
					{key:5,label:'5 or 6',value:'2'},
				],
				value:'4'
			},
		]
	},
	{
		name:'Facebook Feed',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'facebook_feed',
				label:'Facebook Feed Link',
				type:'text',
				value:''
			},
		]
	},
	{
		name:'New Arrivals',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			}
		]
	},
	/*{
		name:'About Us',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			}
		]
	},*/
	{
		name:'Shop By Category',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Instagram Feed',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'access_token',
				label:'Access Token',
				type:'text',
				value:''
			},
			{
				key:'feed_count',
				label:'Count',
				type:'number',
				value:''
			}
		]
	},
	{
		name:'Featured Products',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Recent Reviews',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Get Exclusive News',
		fields:[]
	}
];
export default pageStructure;
