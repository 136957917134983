import React, { Fragment, Component } from "react";

import {
	Row, Container,
	ListGroup, ListGroupItem
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";

function GetImage(img) {
	return (
		<img src={img} className='sectionImg' />
	)
}

class HomePageSection extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div>
				<Row>
					<Colxx xxs="12" className='d-flex flex-column align-items-start justify-content-between p-3 mx-auto mx-md-0'>
						<ListGroup className='text-left' style={{width: '100%'}}>
							<ListGroupItem>
								<iframe src="https://anchor.fm/ultradieseltime/embed/episodes/A-Boldly-Positive-Conversation-with-the-Owner-of-Lacuna-Kava-Chase-Brendle-eeviqt/a-ahl5ch" height="100" width="100%" frameborder="0" scrolling="no"></iframe>
							</ListGroupItem>
							<ListGroupItem>
								<iframe width="100%" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/763225159&amp;color=%23060606&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true"></iframe>
							</ListGroupItem>
						</ListGroup>
					</Colxx>


				</Row>
			</div>
		);
	}
};

export default HomePageSection;
