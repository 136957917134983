import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter, Card, CardHeader,
	Label,
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
  
export default class EditFlowModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			recipes:[],
			selectedRecipes:[],
			packaging_flow_data:null
		};	
	}
	
	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'kitchen/get-data-add-packaging-flow',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				let recipes=data.recipes;
				
				recipes = recipes.map((element,index)=>{
					element.label=element.recipe_name;
					element.value=element.recipe_id;
					element.key=index;
					return element;
				});
				
				this.setState({
					recipes
				});	
			}else{
				this.setState({
					recipes:[]
				});
			}			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			if (prevProps.flow_id!== this.props.flow_id && this.props.flow_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('flow_id',this.props.flow_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'kitchen/get-packaging-flow',formData).then((res)=>{
					
					var data = res.data;
					var packaging_flow_data=data.packaging_flow;
					let selectedRecipes = [];
					if(packaging_flow_data.recipes.length){
						let selectedIds = packaging_flow_data.recipes;
						selectedRecipes = this.state.recipes.filter(r=>{ return selectedIds.indexOf(r.value)!=-1 });
					}
					
					this.setState({ 
						packaging_flow_data,
						selectedRecipes
					});					
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			
			if(this.state.selectedRecipes.length==0){
				swal("Warning!","Please add at least one package setup","warning");
				return;
			}
			
			document.getElementById("edit-packaging-flow").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('flow_id',this.props.flow_id);	
			formData.append('name',values.name);	
			formData.append('recipes',JSON.stringify(this.state.selectedRecipes.map(r=>r.value)));		
			
			axios.post(REST_API_END_POINT+'kitchen/edit-packaging-flow',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("edit-packaging-flow").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("edit-packaging-flow").disabled=false;
			});

		}
	}

	render() {	
		const { packaging_flow_data } = this.state;
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Packaging Flow
				</ModalHeader>
				<AvForm
					id="editPackagingFlowForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup >
									<Label>Name<span className="text-danger">*</span></Label>
									<AvField
										id="name"
										name="name"
										type="text"
										autoComplete="off"
										value={packaging_flow_data!=null ? packaging_flow_data.name:""}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											minLength: {
												value: 2,
												errorMessage:"The name must be between 2 and 100 characters"
											},
											maxLength: {
												value: 100,
												errorMessage:"The name must be between 2 and 100 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>					
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup >
									<Label>Package Setups<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										isMulti
										name="selected_recipes"
										id="selected_recipes"
										value={this.state.selectedRecipes}						
										options={this.state.recipes}
										onChange={selectedRecipes => { this.setState({ selectedRecipes }) }}
									/>
								</AvGroup>
							</Colxx>					
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="edit-packaging-flow">
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
