import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import EditVisitorsModal from "../../../containers/visitors/EditVisitorsModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { displayDateFormat } from "../../../helpers/Utils";

import ViewLogsModal from "../../../containers/visitors/ViewLogsModal";
import CheckoutModal from "../../../containers/visitors/CheckoutModal";
import PrintBadgeModal from "../../../containers/visitors/PrintBadgeModal";
import WebCheckInModal from "../../../containers/visitors/WebCheckInModal";
import CovidFormModal from "../../../containers/visitors/CovidFormModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

export default class Visitors extends Component {
	
	constructor(props) {
		super(props);		
		
		this.state = {		 		 
			visitor_id: 0,
			visitor_data: null,
			modalOpenViewLogs: false,
			modalOpenPrintBadge: false,
			modalOpenCheckout: false,
			modalOpenWebCheckIn: false,
			modalOpenCovidForm: false,
			search: '',
		};
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.toggleModalViewLogs = this.toggleModalViewLogs.bind(this);
		this.toggleModalCheckout = this.toggleModalCheckout.bind(this);
		this.toggleModalPrintBadge = this.toggleModalPrintBadge.bind(this);
		this.toggleModalWebCheckIn = this.toggleModalWebCheckIn.bind(this);
		this.toggleModalCovidForm = this.toggleModalCovidForm.bind(this);
	}
	
	componentDidMount(){  
		this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'visitor/get-all-visitors',formData).then((res)=>{
			var data = res.data;
		
			if(data.visitors){
				this.setState({
					visitors: data.visitors,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					visitors: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	showPrintBadge = (e,visitor_data=null) =>{
		this.setState({
			modalOpenWebCheckIn: false,
			modalOpenCovidForm: false,
			modalOpenPrintBadge: true,
			visitor_data
		});
	}
	
	toggleModalCovidForm = (e,visitor_data=null) =>{
		this.setState({
			modalOpenWebCheckIn: false,
			modalOpenCovidForm: true,
			visitor_data
		});
	}
	
	toggleModalWebCheckIn = () => {
		this.setState({
			modalOpenWebCheckIn: !this.state.modalOpenWebCheckIn
		});
	};
	
	toggleModalViewLogs = (e,visitor_id =0) => {
		this.setState({
		  visitor_id:parseInt(visitor_id),
		  modalOpenViewLogs: !this.state.modalOpenViewLogs
		});
	};
	
	toggleModalPrintBadge = (e,visitor_data =null) => {
		this.setState({
		  visitor_data,
		  modalOpenPrintBadge: !this.state.modalOpenPrintBadge
		});
	};
	
	toggleModalCheckout = (e,visitor_data =null) => {
		this.setState({
		  visitor_data,
		  modalOpenCheckout: !this.state.modalOpenCheckout
		});
	};
		
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one visitor");
			return;
		}
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="visitor";
		
		if(selectedIds.length>1){
			msg_word="visitors";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-visitors").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('visitor_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'visitor/delete-visitors',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-visitors").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-visitors").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
	
	activeItem=(e,visitor_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(visitor_id);			
		}else{
		  var x=selectedIds.indexOf(visitor_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.visitors.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.visitors.forEach(x => {
				newSelected.push(x.vid);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	render() {
		let data = this.state.visitors;
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.includes(this.state.search) || row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.phone.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "vid",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"vid"+rowInfo.original.vid} onChange={event =>this.activeItem(event,rowInfo.original.vid) } checked={this.state.selectedIds.includes(rowInfo.original.vid)} />
						</FormGroup> );
				}
			},
			{
				Header: "ID",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Phone",
				accessor: "phone",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Last Checked In",
				accessor: "last_checkin",
				Cell: props => <p className="text-muted">{props.value!=0 && props.value!=null ? moment(props.value*1000).format(displayDFormat+" hh:mm a"):""}</p>
			},
			{
				Header: "Last Checked Out",
				accessor: "last_checkout",
				Cell: props => <p className="text-muted">{props.value!=0 && props.value!=null ? moment(props.value*1000).format(displayDFormat+" hh:mm a"):""}</p>
			},
			{
				Header: "Options",
				accessor: "vid",
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalViewLogs(e,props.value) }}>
									<span className="d-inline-block">View Logs</span>
								</DropdownItem>
								
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalPrintBadge(e,props.original) }}>
									<span className="d-inline-block">Print Badge</span>
								</DropdownItem>
								
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.deleteItem(e,[props.value],2) }} >
									<span className="d-inline-block">Delete</span>
								</DropdownItem>
								
								{parseInt(props.original.last_checkin)>0 && parseInt(props.original.last_checkout)===0  ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalCheckout(e,props.original) }} >
									<span className="d-inline-block" >Checkout</span>
								</DropdownItem>:""}
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.visitors" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
			
				<Card className="p-4">
					<Row>
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						
						<Colxx xxs="12">
							<div className="float-sm-right mb-3">
								{" "}<Button
									  color="primary"
									  size="sm"
									  id="delete-visitors"
									  onClick={this.toggleModalWebCheckIn}
									>
									  Web CheckIn
								</Button>
								{" "}<NavLink to="/app/visitors/settings" className="btn btn-sm btn-primary">Visitor Settings</NavLink>
								{" "}<Button
									  color="primary"
									  size="sm"
									  id="delete-visitors"
									  onClick={(e)=>{ this.deleteItem(e,this.state.selectedIds,2); }}
									>
									  Delete Visitors
								</Button>
							</div>
							
							<div className="mb-4 dropdown-edit">
								<FormGroup row>
									<Colxx lg="6">
										<Input
											type="text"
											name="table_search"
											id="table_search"
											placeholder="Search"
											value={this.state.search}
											onChange={e => this.setState({search: e.target.value})}
										/>
									</Colxx>
								</FormGroup>			
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={5}
									filterable={false}
									showPageJump={false}
									responsive
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "send_on",desc: false}]}
								/>
							</div>
						</Colxx>
						
						<ViewLogsModal
							modalOpen={this.state.modalOpenViewLogs}
							toggleModal={this.toggleModalViewLogs}
							visitor_id={this.state.visitor_id}
						/>
						
						<CheckoutModal
							modalOpen={this.state.modalOpenCheckout}
							toggleModal={this.toggleModalCheckout}
							visitor_data={this.state.visitor_data}
							dataListRender={this.dataListRender}
						/>
						
						<PrintBadgeModal
							modalOpen={this.state.modalOpenPrintBadge}
							toggleModal={this.toggleModalPrintBadge}
							visitor_data={this.state.visitor_data}
						/>
						
						{this.state.modalOpenWebCheckIn ? <WebCheckInModal
							modalOpen={this.state.modalOpenWebCheckIn}
							toggleModal={this.toggleModalWebCheckIn}
							dataListRender={this.dataListRender}
							showPrintBadge={this.showPrintBadge}
							toggleModalCovidForm={this.toggleModalCovidForm}
						/>:""}
						
						{this.state.modalOpenCovidForm ? <CovidFormModal
							modalOpen={this.state.modalOpenCovidForm}
							toggleModal={this.toggleModalCovidForm}
							dataListRender={this.dataListRender}
							showPrintBadge={this.showPrintBadge}
							visitor_data={this.state.visitor_data}
						/>:""}
					</Row>
				</Card>
			</Fragment>
		);
	}
}
