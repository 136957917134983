import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { displayDateFormat } from "../../helpers/Utils";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT, dataEncryptKey, themeColor, JQUERY_MODULE, LBS_CONSTANT} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

const finishedStruct = {
	id:uuid(),
	product_id:null,
	variation_id:null,
	package_date:null,
	quantity:0,
	mono_carton:true,
	variations:[],
}

export default class Packaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			rr:"",
			loadedPackagingProducts:[],
			productsPackaging:[],
			finishedProducts:[JSON.parse(JSON.stringify(finishedStruct))],
			packagingProducts:[JSON.parse(JSON.stringify(finishedStruct))],
			blocking:true,
			submitted:false,
			batch_data:null,
			variation_id:null,
			batch_start_time:"",
			batch_end_time:"",
			packaging:0,
		};
		
		this.updateFinishedProduct = this.updateFinishedProduct.bind(this);
		this.handleBatchSubmit = this.handleBatchSubmit.bind(this);
		this.updatePackagingProduct = this.updatePackagingProduct.bind(this);
		this.completePackaging = this.completePackaging.bind(this);
		
		this.formEl = React.createRef()
		
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('staging_id',this.props.staging_id);
		
		axios.post(REST_API_END_POINT+'processor/get-packaging-details-by-id',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var productsPackaging = data.products_packaging;
				productsPackaging = productsPackaging.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					row_data.is_mono_carton=row.is_mono_carton;
					return row_data;
				});
				
				let batch_data = data.batch_data;
				let product_variations = data.product_variations;
				let batch_start_time=null, batch_end_time=null, variation_id=null, rr="";
				
				product_variations = product_variations.map(row=>{
					row.label=row.variation_name;
					row.value=row.variation_id;
					row.key=row.variation_id;
					return row;
				});
				
				if(batch_data){
					/*if(batch_data.batch_start_time){
						batch_start_time = batch_data.batch_start_time;
					}
					if(batch_data.batch_end_time){
						batch_end_time = batch_data.batch_end_time;
					}*/
					if(batch_data.rr){
						if(batch_data.rr!=0){
							rr = batch_data.rr;
						}
					}
					if(batch_data.variation_id){
						variation_id = product_variations.find(v=>{ return v.value==batch_data.variation_id; });
					}
				}
				
				let finishedProducts = data.staging_products;
				finishedProducts = finishedProducts.map(row=>{
					row.package_date = moment(row.package_date*1000);
					return row;
				});
				if(finishedProducts.length==0){
					finishedProducts = [JSON.parse(JSON.stringify(finishedStruct))]
				}
				
				let loadedPackagingProducts = JSON.parse(JSON.stringify(data.packaging_products));
				let packagingProducts = data.packaging_products;
				packagingProducts = packagingProducts.map(row=>{
					row.variations = row.variations.map(v=>{
						v.label=v.variation_name;
						v.value=v.variation_id;
						v.key=v.variation_id;
						
						let variation_index = loadedPackagingProducts.findIndex(v=>{ return v.variation_id==row.variation_id })
						if(variation_index!=-1){
							v.stock=(parseFloat(v.stock)+parseFloat(loadedPackagingProducts[variation_index].quantity)).toString();
						}
						
						return v;
					});
					row.mono_carton = row.is_mono_carton==1;
					row.variation_id = row.variations.find(v=>{ return v.value==row.variation_id });
					row.product_id = productsPackaging.find(v=>{ return v.value==row.product_id });
					return row;
				});
				
				if(packagingProducts.length==0){
					packagingProducts = [JSON.parse(JSON.stringify(finishedStruct))]
				}
				
				this.setState({
					productsPackaging,
					finishedProducts,
					loadedPackagingProducts,
					packagingProducts,
					batch_data, batch_start_time, batch_end_time, variation_id, rr,
					product_variations, 
					submitted:false, blocking:false
				});
				
			}else{
				this.setState({					
					batches:[],
					productsPackaging: [],
					blocking:false
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleBatchSubmit(event, errors, values) {
		let inValidPackagingProducts = this.state.packagingProducts.filter(row=>{
			return row.mono_carton;
		});
		if(inValidPackagingProducts.length==0){
			swal("Warning!","Please select a product that is mono carton","warning");
			return false;
		}else if(inValidPackagingProducts.length>1){
			swal("Warning!","You can only choose one product that is mono carton","warning");
			return false;
		}
		
		if (errors.length === 0) {
			this.setState({ submitted:true })
			if(this.state.packagingProducts.length==0){
				swal("Warning!","Please enter at least one product for packaging","warning");
				return false;
			}
			
			if(this.state.finishedProducts.length==0){
				swal("Warning!","Please enter at least one stock","warning");
				return false;
			}
			
			if(this.state.variation_id==null){
				swal("Warning!","Please select variation","warning");
				return false;
			}
			let inValidfinishedProducts = this.state.finishedProducts.filter(row=>{
				return row.package_date==null || row.quantity==0 || row.quantity==""
			});
			if(inValidfinishedProducts.length>0){
				return false;
			}
			
			/*inValidfinishedProducts = this.state.finishedProducts.filter(row=>{
				let variation_count = this.state.finishedProducts.filter(p=>{ 
					if(p.variation_id!=null && row.variation_id!=null){
						return p.variation_id.value==row.variation_id.value;
					}else{
						return false;
					}			
				}).length;
				if(variation_count>1){
					return true;
				}
			});
			if(inValidfinishedProducts.length>0){
				swal("Warning!","Duplicate variations found in stock","warning");
				return false;
			}*/
			
			let inValidPackagingProducts = this.state.packagingProducts.filter(row=>{
				let variation_count = this.state.packagingProducts.filter(p=>{ 
					if(p.variation_id!=null && row.variation_id!=null){
						return p.variation_id.value==row.variation_id.value;
					}else{
						return false;
					}			
				}).length;
				if(variation_count>1){
					return true;
				}
			});
			if(inValidPackagingProducts.length>0){
				swal("Warning!","Duplicate variations found in packaging","warning");
				return false;
			}
			
			document.getElementById('step-2-submit-btn').disabled=true;
			if(document.getElementById('step-3-submit-btn')){
				document.getElementById('step-3-submit-btn').disabled=true;
			}
			
			var facility_id = localStorage.getItem('facility_id');
			var user_id = localStorage.getItem('user_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('staging_id',this.props.staging_id);
			formData.append('product_id',this.state.batch_data.product_id);
			formData.append('barcode',this.state.batch_data.batch_uid);
			formData.append('rr',this.state.rr);
			formData.append('packaging',this.state.packaging);
			formData.append('variation_id',this.state.variation_id.value);
			
			let finishedProducts = JSON.parse(JSON.stringify(this.state.finishedProducts)).map(row=>{
				row.variation_id = this.state.variation_id.value;
				row.package_date = moment(row.package_date).unix();
				delete row.id;
				delete row.mono_carton;
				delete row.variations;
				delete row.product;
				delete row.variation_name;
				delete row.is_mono_carton;
				return row;
			});
			
			formData.append('finished_products',JSON.stringify(finishedProducts));
			
			let packagingProducts = JSON.parse(JSON.stringify(this.state.packagingProducts)).map(row=>{
				row.product_id = row.product_id.value;
				row.variation_id = row.variation_id.value;
				delete row.id;
				delete row.variations;
				delete row.package_date;
				delete row.mono_carton;
				delete row.product;
				delete row.variation_name;
				delete row.is_mono_carton;
				return row;
			});
			
			formData.append('packaging_products',JSON.stringify(packagingProducts));
			axios.post(REST_API_END_POINT+'processor/finish-packaging',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					swal("Success!",data.msg,"success").then(()=>{
						/*if(this.state.container_uid!=null){
							this.getContainerDetails(this.state.container_uid.value);
						}*/
						if(this.props.update){
							window.location="/app/processor/all-operations";
						}else{
							window.location.reload();
						}
					});
				}else{
					swal("Warning!",data.msg,'warning')
				}
				if(document.getElementById('step-2-submit-btn')){
					document.getElementById('step-2-submit-btn').disabled=false;
				}
				if(document.getElementById('step-3-submit-btn')){
					document.getElementById('step-3-submit-btn').disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				if(document.getElementById('step-2-submit-btn')){
					document.getElementById('step-2-submit-btn').disabled=false;
				}
				if(document.getElementById('step-3-submit-btn')){
					document.getElementById('step-3-submit-btn').disabled=false;
				}
			});
		}
	}
	
	/* Finished Products */
	addFinishedProduct = () =>{
		let finishedProducts = this.state.finishedProducts;
		let finishedProduct = JSON.parse(JSON.stringify(finishedStruct));
		finishedProduct.id=uuid();
		finishedProducts.push(finishedProduct);
		this.setState({ finishedProducts });
	}
	
	removeFinishedProduct = (id) =>{
		let finishedProducts = this.state.finishedProducts;
		finishedProducts = finishedProducts.filter(row=>{ return row.id!==id });
		this.setState({ finishedProducts });
	}
	
	updateFinishedProduct = (id,key,value) => {
		let finishedProducts = this.state.finishedProducts;
		finishedProducts = finishedProducts.map(row=>{
			if(row.id==id){
				row[key] = value;
			}
			return row;
		});
		this.setState({ finishedProducts });
	};

	renderFinishedProducts = () => {
		const { submitted } = this.state;
		let finishedProducts = [];
		if(this.state.finishedProducts.length>0){
			finishedProducts = this.state.finishedProducts.map((finished,index)=>{
				let variation_count = this.state.finishedProducts.filter(p=>{ 
						if(p.variation_id!=null && finished.variation_id!=null){
							return p.variation_id.value==finished.variation_id.value;
						}else{
							return false;
						}			
					}).length;
				return (<Row className="align-items-center mb-2" key={index}>
							<Colxx md="6">
								<AvGroup className="error-t-negative">
									<Label>Package Date<span className="text-danger">*</span></Label>
									<DatePicker
										id={"f_package_date_"+index}
										name={"f_package_date_"+index}
										autoComplete="off"
										maxDate={moment()}
										selected={finished.package_date}
										placeholderText=""
										dateFormat={displayDFormat}
										onChange={e => { 
											if(typeof e!=='string'){
												this.updateFinishedProduct(finished.id,'package_date',e);
											}else{
												this.updateFinishedProduct(finished.id,'package_date',null);
											}
										}}
										readOnly={true}
									/>
									{submitted ? (finished.package_date==null ? <span className="text-danger">Please select a date</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="5">
								<AvGroup className="error-t-negative">
									<Label>Quantity<span className="text-danger">*</span></Label>
									<AvField
										id={"f_quantity_"+index}
										name={"f_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.quantity}
										onChange={e => { 
											this.updateFinishedProduct(finished.id,'quantity',e.target.value);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the quantity"
											},
											min: {
												value: "0",
												errorMessage: "Quantity must be greater than 0"
											},
											step: {
												value: "1",
												errorMessage: "Quantity must be a whole number"
											}
										}}
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="1">
								{this.state.finishedProducts.length>1 ? <span className="text-danger" onClick={()=>{ this.removeFinishedProduct(finished.id) }}><i className="fas fa-2x fa-times" /></span>:""}
							</Colxx>	
						</Row>)
			})
		}
		return finishedProducts;
	};
	
	/* Packaging */
	addPackagingProduct = () =>{
		let packagingProducts = this.state.packagingProducts;
		let finishedProduct = JSON.parse(JSON.stringify(finishedStruct));
		finishedProduct.id=uuid();
		finishedProduct.mono_carton=false;
		packagingProducts.push(finishedProduct);
		this.setState({ packagingProducts });
	}
	
	removePackagingProduct = (id) =>{
		let packagingProducts = this.state.packagingProducts;
		packagingProducts = packagingProducts.filter(row=>{ return row.id!==id });
		this.setState({ packagingProducts });
	}
	
	updatePackagingProduct = (id,key,value) => {
		if(key==='product_id'){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('product_id',value.value);
			axios.post(REST_API_END_POINT+'processor/get-variations',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					let loadedPackagingProducts = this.state.loadedPackagingProducts;
					
					let mono_carton = data.mono_carton;
					var product_variations = data.product_variations;
					product_variations = product_variations.map(row=>{
						row.label=row.variation_name;
						row.value=row.variation_id;
						row.key=row.variation_id;
						
						let variation_index = loadedPackagingProducts.findIndex(v=>{ return parseInt(v.variation_id)==parseInt(row.variation_id) });
						if(variation_index!=-1){
							row.stock=(parseFloat(row.stock)+parseFloat(loadedPackagingProducts[variation_index].quantity)).toString();
						}
						
						return row;
					});
					
					let packagingProducts = this.state.packagingProducts;
					packagingProducts = packagingProducts.map(row=>{
						if(row.id==id){
							row[key] = value;
							row.mono_carton = mono_carton;
							row.variation_id = null;
							row.variations = product_variations;
						}
						return row;
					});
					this.setState({ packagingProducts });
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}else{
			let packagingProducts = this.state.packagingProducts;
			packagingProducts = packagingProducts.map(row=>{
				if(row.id==id){
					row[key] = value;
				}
				return row;
			});
			this.setState({ packagingProducts });
		}
	};

	renderPackagingProducts = () => {
		const { submitted } = this.state;
		let packagingProducts = [];
		let renderProducts = this.state.packagingProducts.filter(row=> !row.mono_carton);
		if(renderProducts.length>0){
			packagingProducts = renderProducts.map((finished,index)=>{
				let variation_count = this.state.packagingProducts.filter(p=>{ 
						if(p.variation_id!=null && finished.variation_id!=null){
							return p.variation_id.value==finished.variation_id.value;
						}else{
							return false;
						}			
					}).length;
				
				return (<Row className="align-items-center mb-2" key={index}>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Product<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"p_product_id"+index}
										id={"p_product_id"+index}
										placeholder="-- Select Product --"
										value={finished.product_id}						
										options={this.state.productsPackaging.filter(row=> row.is_mono_carton!=1)}
										onChange={product_id => { 
											this.updatePackagingProduct(finished.id,'product_id',product_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select product</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Variation<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"p_variation_id"+index}
										id={"p_variation_id"+index}
										placeholder="-- Select Variation --"
										value={finished.variation_id}						
										options={finished.variations}
										onChange={variation_id => { 
											this.updatePackagingProduct(finished.id,'variation_id',variation_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select variation</span>:""):""}
									{variation_count>1 ? <span className="text-danger">Duplicate variation found</span>:""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup >
									<Label>Current Stock<span className="text-danger">*</span></Label>
									<AvField
										id={"avail_quantity_"+index}
										name={"avail_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.variation_id!=null ? finished.variation_id.stock:""}
										readOnly
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="2">
								<AvGroup >
									<Label>Used Quantity<span className="text-danger">*</span></Label>
									<AvField
										id={"p_quantity_"+index}
										name={"p_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.quantity}
										onChange={e => { 
											this.updatePackagingProduct(finished.id,'quantity',e.target.value);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the quantity"
											},
											min: {
												value: "1",
												errorMessage: "Quantity must be greater than 1"
											},
											max: {
												value: finished.variation_id!=null ? finished.variation_id.stock.toString():"0",
												errorMessage: "Quantity must be less than or equal to "+(finished.variation_id!=null ? finished.variation_id.stock.toString():"0")
											},
											step: {
												value: 1,
												errorMessage: "Quantity must be a whole number"
											}
										}}
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="1">
							<span className="text-danger" onClick={()=>{ this.removePackagingProduct(finished.id) }}><i className="fas fa-2x fa-times" /></span>
							</Colxx>	
						</Row>)
			})
		}
		return packagingProducts;
	};
	
	renderMonocartonProducts = () => {
		const { submitted } = this.state;
		let packagingProducts = [];
		let renderProducts = this.state.packagingProducts.filter(row=> row.mono_carton);
		if(renderProducts.length>0){
			packagingProducts = renderProducts.map((finished,index)=>{
				let variation_count = this.state.packagingProducts.filter(p=>{ 
						if(p.variation_id!=null && finished.variation_id!=null){
							return p.variation_id.value==finished.variation_id.value;
						}else{
							return false;
						}			
					}).length;
				
				return (<Row className="align-items-center mb-2" key={index}>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Product<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"p_product_id"+index}
										id={"p_product_id"+index}
										placeholder="-- Select Product --"
										value={finished.product_id}						
										options={this.state.productsPackaging.filter(row=> row.is_mono_carton==1)}
										onChange={product_id => { 
											this.updatePackagingProduct(finished.id,'product_id',product_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select product</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Variation<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"p_variation_id"+index}
										id={"p_variation_id"+index}
										placeholder="-- Select Variation --"
										value={finished.variation_id}						
										options={finished.variations}
										onChange={variation_id => { 
											this.updatePackagingProduct(finished.id,'variation_id',variation_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select variation</span>:""):""}
									{variation_count>1 ? <span className="text-danger">Duplicate variation found</span>:""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup >
									<Label>Current Stock<span className="text-danger">*</span></Label>
									<AvField
										id={"avail_quantity_"+index}
										name={"avail_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.variation_id!=null ? finished.variation_id.stock:""}
										readOnly
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="2">
								<AvGroup >
									<Label>Used Quantity<span className="text-danger">*</span></Label>
									<AvField
										id={"p_quantity_"+index}
										name={"p_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.quantity}
										onChange={e => { 
											this.updatePackagingProduct(finished.id,'quantity',e.target.value);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the quantity"
											},
											min: {
												value: "1",
												errorMessage: "Quantity must be greater than 1"
											},
											max: {
												value: finished.variation_id!=null ? finished.variation_id.stock.toString():"0",
												errorMessage: "Quantity must be less than or equal to "+(finished.variation_id!=null ? finished.variation_id.stock.toString():"0")
											},
											step: {
												value: 1,
												errorMessage: "Quantity must be a whole number"
											}
										}}
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="1">
							{renderProducts.length>1 ?<span className="text-danger" onClick={()=>{ this.removePackagingProduct(finished.id) }}><i className="fas fa-2x fa-times" /></span>:""}
							</Colxx>	
						</Row>)
			})
		}
		return packagingProducts;
	};
	
	completePackaging = ()  => {
		let proceed = true;
		if(typeof this.formEl.current.state.invalidInputs!='undefined'){
			if(Object.keys(this.formEl.current.state.invalidInputs).length!=0){
				proceed=false;
				document.getElementById('step-submit-btn').click();
				return false;
			}
		}
		
		let inValidPackagingProducts = this.state.packagingProducts.filter(row=>{
			return row.mono_carton;
		});
		if(inValidPackagingProducts.length==0){
			swal("Warning!","Please select a product that is mono carton","warning");
			proceed=false;
			return false;
		}else if(inValidPackagingProducts.length>1){
			swal("Warning!","You can only choose one product that is mono carton","warning");
			proceed=false;
			return false;
		}
		
		this.setState({ submitted:true })
		if(this.state.packagingProducts.length==0){
			swal("Warning!","Please enter at least one product for packaging","warning");
			proceed=false;
			return false;
		}
		
		if(this.state.finishedProducts.length==0){
			swal("Warning!","Please enter at least one stock","warning");
			proceed=false;
			return false;
		}
		
		if(this.state.variation_id==null){
			swal("Warning!","Please select variation","warning");
			proceed=false;
			return false;
		}
		let inValidfinishedProducts = this.state.finishedProducts.filter(row=>{
			return row.package_date==null || row.quantity==0 || row.quantity==""
		});
		if(inValidfinishedProducts.length>0){
			proceed=false;
			return false;
		}
		
		inValidPackagingProducts = this.state.packagingProducts.filter(row=>{
			let variation_count = this.state.packagingProducts.filter(p=>{ 
				if(p.variation_id!=null && row.variation_id!=null){
					return p.variation_id.value==row.variation_id.value;
				}else{
					return false;
				}			
			}).length;
			if(variation_count>1){
				return true;
			}
		});
		if(inValidPackagingProducts.length>0){
			swal("Warning!","Duplicate variations found in packaging","warning");
			proceed=false;
			return false;
		}
		
		if(proceed){
			swal({
			  title: "Are you sure?",
			  text: "You want complete the packaging for this batch?",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
				if (willDelete) {
					this.setState({ packaging:1 },()=>{ document.getElementById('step-submit-btn').click(); });
				}else{
					swal("Your operation is canceled!");
				}
			});
		}
	} 
	
	
	render() {
		const { submitted, batch_data } = this.state;
		let max_stock = "0";
		let total_stock = 0;
		if(this.state.finishedProducts.length>0){
			total_stock = this.state.finishedProducts.map(r=>{ if(r.quantity==""){ return 0; } return parseInt(r.quantity); }).reduce((total,r)=>{ return total+r; });
		}
		if(this.state.packagingProducts.length>0){
			let monoCarton = this.state.packagingProducts.find(r=>{ return r.mono_carton; });
			if(monoCarton){
				if(monoCarton.quantity!=""){
					max_stock = monoCarton.quantity;
				}
			}
		}
    return (
      <Fragment>
        <div className="px-3 mb-3">
			<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleBatchSubmit}
					id="packagingForm"
					ref={this.formEl}
				  >
					{this.props.update ? <Row className="">
						<Colxx lg="12" className="form-group-mb-0">
							<h4>{batch_data!=null ? batch_data.product_name+" ("+batch_data.batch_uid+")":""}</h4>
						</Colxx>
					</Row>:""}
					
					<Row className="">
						<Colxx lg="12" className="form-group-mb-0">
							<AvGroup>
								<Label>RR</Label>
								<AvField
									id="rr"
									name="rr"
									type="number"												
									placeholder="(Gram)"
									value={this.state.rr}
									onChange={e => { this.setState({ rr:e.target.value }) }}
									validate={{
										min:{
											value: "0",
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
								<Label>Grams</Label>
							</AvGroup>
						</Colxx>
						<Colxx md="12">
							<AvGroup className="error-t-negative">
								<Label>Variation<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name={"variation_id"}
									id={"variation_id"}
									placeholder="-- Select Variation --"
									value={this.state.variation_id}						
									options={this.state.product_variations}
									onChange={variation_id => { 
										this.setState({ variation_id });
									}}
								/>
								{submitted ? (this.state.variation_id==null ? <span className="text-danger">Please select a variation</span>:""):""}
							</AvGroup>
						</Colxx>
						
						{this.state.batch_data !=null ? <Fragment>
							<Colxx lg="12" className=" mt-3">
								<h4 className="font-weight-bold">Monocarton</h4>
								{this.renderMonocartonProducts()}
								
							</Colxx>
							<hr/>
							
							<Colxx lg="12" className="mb-4 mt-3">
								<h4 className="font-weight-bold">Packaging</h4>
								{this.renderPackagingProducts()}
								
								<Button color="primary" type="button" onClick={this.addPackagingProduct}>
									Add another packaging product
								</Button>
							</Colxx>
							<hr/>
							<Colxx lg="12" className="mb-4">
								<h4 className="font-weight-bold">Stock</h4>
								{this.renderFinishedProducts()}
								
								{total_stock<parseInt(max_stock) ? <Button color="primary" type="button" onClick={this.addFinishedProduct}>
									Add another stock
								</Button>:""}
								
								<Row className="align-items-center mt-2 mb-2">
									<Colxx md="6">
										<AvGroup className="error-t-negative">
											<h5 className="font-weight-bold">Total Stock</h5>
										</AvGroup>
									</Colxx>
									<Colxx md="5">
										<AvGroup >
											<AvField
												name={"f_total"}
												type="number"												
												placeholder="(Unit)"
												readOnly={true}
												value={total_stock}
												validate={{
													required: {
														value: true,
														errorMessage: "Please enter the total"
													},
													min: {
														value: max_stock.toString(),
														errorMessage: "Total must be equal to "+max_stock.toString()
													},
													max: {
														value: max_stock.toString(),
														errorMessage: "Total must be equal to "+max_stock.toString()
													},
													step: {
														value: "1",
														errorMessage: "Total must be a whole number"
													}
												}}
											/>									
										</AvGroup>
									</Colxx>	
								</Row>
								
							</Colxx>
						</Fragment>:""}
						
						<Colxx lg="12">
							<Row className="align-items-center">
								<Colxx lg="12">
									<Button id="step-submit-btn" className="d-none" color="primary" disabled={this.state.batch_data==null} >Submit</Button>{" "}
									<Button type="button" id="step-2-submit-btn" color="primary" disabled={this.state.batch_data==null} onClick={()=>{ this.setState({ packaging:0 },()=>{ document.getElementById('step-submit-btn').click(); });  }}>Save</Button>{" "}
									{!this.props.update ? <Button type="button" id="step-3-submit-btn" color="primary" disabled={this.state.batch_data==null} onClick={this.completePackaging} >Complete Packaging</Button>:""}
								</Colxx>
							</Row>
						</Colxx>
						
					</Row>	
				</AvForm>
			</BlockUi>
		</div>
		
		
      </Fragment>
    );
  }
}
