import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';

import {REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";
import Batch from "../../../components/processor/Batch";
import KitchenProcessComponent from "../../../components/kitchen/KitchenProcess";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const finishedStruct = {
	id:uuid(),
	product_id:null,
	variation_id:null,
	quantity:0,
	variations:[],
}

export default class RawMaterialStaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			rrs:[],
			containers:[],
			product_packages:[],
			packages_dropdown:[],
			waste:"",
			rr:"",
			package_uid:null,		  
			step:1,	
			running_batches:[],
			products:[],
			product_variations:[],
			productsPackaging:[],
			finishedProducts:[JSON.parse(JSON.stringify(finishedStruct))],
			packagingProducts:[JSON.parse(JSON.stringify(finishedStruct))],
			submitted:false,
			batch_product_id:null,
			no_of_batches:0,
			loaded_no_of_batches:0,
			showCreateBatch:true,
			staging_id:0,
			staging_batch_id:0,
		};
		
		this.handleBatchSubmit = this.handleBatchSubmit.bind(this);
		this.getProductBatches = this.getProductBatches.bind(this);
		this.updatePackages = this.updatePackages.bind(this);
		this.prevBatch = this.prevBatch.bind(this);
		this.nextBatch = this.nextBatch.bind(this);
		this.completeBatching = this.completeBatching.bind(this);
		
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/raw-material',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var containers = data.containers;
				containers = containers.map(row=>{
					row.label=row.container_name+" ("+row.barcode+")";
					row.value=row.container_id;
					row.key=row.container_id;
					return row;
				});
				
				var products = data.products;
				products = products.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					return row_data;
				});
				
				var productsPackaging = data.products_packaging;
				productsPackaging = productsPackaging.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					return row_data;
				});
				
				let product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.package_id;
					row.key=row.package_id;
					row.package=true;
					return row;
				});
				
				let rrs = data.rrs;
				rrs = rrs.map(row=>{
					row.label=row.name+" ("+row.batch_uid+")";
					row.value=row.id;
					row.key=row.id;
					row.package=false;
					return row;
				});
				
				let packages_dropdown = [...product_packages,...rrs]
				
				this.setState({
					containers,
					product_packages,
					rrs,
					packages_dropdown,
					products,
					productsPackaging
				});
				
				if(containers.length==0){
					swal("Warning!",'There is no vessel available now. Please add at least one vessel',"warning");
				}else if(packages_dropdown.length==0){
					swal("Warning!",'There is no stock available now. Please add at least one stock',"warning");
				}
				
			}else{
				this.setState({					
					containers:[],
					product_packages: [],
					rrs: [],
					packages_dropdown: [],
					products:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleBatchSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.setState({ submitted:true })
			if(this.state.batch_product_id==null){
				return false;
			}
			
			this.setState({ showCreateBatch:false });
			
			var facility_id = localStorage.getItem('facility_id');
			var user_id = localStorage.getItem('user_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('batch_product_id',this.state.batch_product_id.value);
			formData.append('batch_product_name',this.state.batch_product_id.label);
			formData.append('no_of_batches',values.no_of_batches);
			formData.append('staging_batch_id',this.state.staging_batch_id);
			
			axios.post(REST_API_END_POINT+'processor/create-raw-material-batch',formData).then((res)=>{
				var data = res.data;
				if(data.status==1){
					let staging_id = 0;
					if(data.staging_ids.indexOf(this.state.staging_id)==-1){
						staging_id = data.staging_ids[0];
					}else{
						staging_id = this.state.staging_id;
					}
					
					this.setState({ running_batches:data.staging_ids,loaded_no_of_batches:data.staging_ids.length, staging_id, staging_batch_id:data.staging_batch_id })
					swal("Success!",data.msg,"success");
				}else{
					swal("Warning!",data.msg,'warning')
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				this.setState({ showCreateBatch:true });
			});
		}
	}
	
	getProductBatches(product_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('product_id',product_id);
		
		axios.post(REST_API_END_POINT+'processor/get-product-running-batches',formData).then((res)=>{
			var data = res.data;
			if(data.status==1){			
				let staging_batch_id = data.staging_batch_id;
				let running_batches = data.running_batches;
				let product_variations = data.product_variations;
				let no_of_batches = 0;
				let loaded_no_of_batches = 0;
				let showCreateBatch = true;
				let staging_id = 0;
				if(running_batches.length>0){
					no_of_batches=running_batches.length;
					loaded_no_of_batches=running_batches.length;
					showCreateBatch=false;
					if(running_batches.indexOf(this.state.staging_id)==-1){
						staging_id = running_batches[0];
					}else{
						staging_id = this.state.staging_id;
					}
					
				}
				this.setState({
					staging_batch_id,
					running_batches,
					no_of_batches,
					loaded_no_of_batches,
					showCreateBatch,
					staging_id,
					product_variations
				});
			}else{
				this.setState({	
					staging_batch_id:0,
					running_batches:[],
					no_of_batches:0,
					loaded_no_of_batches:0,
					showCreateBatch:true,
					staging_id:0,
					product_variations:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	updatePackages(props){
		this.setState({
			...props
		});
	}
	
	prevBatch(){
		let index = this.state.running_batches.indexOf(this.state.staging_id);
		if(index!=0){
			this.setState({staging_id:this.state.running_batches[index-1] });
		}
	}
	
	nextBatch(){
		let index = this.state.running_batches.indexOf(this.state.staging_id);
		if(index!=(this.state.running_batches.length-1)){
			this.setState({staging_id:this.state.running_batches[index+1] });
		}
	}
	
	completeBatching() {		
		swal({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("complete-batching").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				formData.append('is_update',0);
				formData.append('staging_batch_id',this.state.staging_batch_id);
				formData.append('running_batches',JSON.stringify(this.state.running_batches));
				formData.append('batch_product_name',this.state.batch_product_id.label);
				
				axios.post(REST_API_END_POINT+'processor/complete-raw-material-staging',formData).then((res)=>{
					var data = res.data;

					if(data.status==1){
						swal("Success!",data.msg,"success").then(()=>{
							window.location.reload();
						});
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	
	render() {
		const { containers, product_packages, packages_dropdown, submitted, showCreateBatch } = this.state;
		const currentIndex = (this.state.running_batches.indexOf(this.state.staging_id)+1).toString();
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading_text="Raw Material Staging" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4 mb-3">
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleBatchSubmit}
			  >
				<Row className="">
					<Colxx lg="6" className="mb-3 form-group-mb-0">
						<AvGroup className="error-t-negative">
							<Label>Batch Name/Product<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name={"batch_product_id"}
								id={"batch_product_id"}
								placeholder="-- Select Batch Name/Product --"
								value={this.state.batch_product_id}						
								options={this.state.products}
								onChange={batch_product_id => {
									this.setState({ batch_product_id })
									if(batch_product_id!=null){
										this.getProductBatches(batch_product_id.value);
									}
								}}
							/>
							{submitted ? (this.state.batch_product_id==null ? <span className="text-danger">Please select product</span>:""):""}
						</AvGroup>
					</Colxx>
					<Colxx lg={showCreateBatch ? "3":"6"} className="mb-3 form-group-mb-0 ">
						<AvGroup>
							<Label>No. of Batches</Label>
							<AvField
								id="no_of_batches"
								name="no_of_batches"
								type="number"												
								placeholder="Enter No. of Batches"
								value={this.state.no_of_batches}
								onChange={e => { this.setState({ no_of_batches:e.target.value  }); }}
								onBlur={e => { setTimeout(()=>{ if(this.state.no_of_batches!=this.state.loaded_no_of_batches){ document.getElementById('update-batch-btn').click(); } },100); }}
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min: {
										value: showCreateBatch ? "1":currentIndex,
										errorMessage: "Value must be greater than or equal to "+(showCreateBatch ? "1":currentIndex)
									},
									max: {
										value: "100",
										errorMessage: "Value must be less than or equal to 100"
									},
									step: {
										value: "1",
										errorMessage: "Value must be a whole number"
									}
								}}
							/>
						</AvGroup>
					</Colxx>
					{showCreateBatch ? <Colxx lg="3" className="mb-3 form-group-mb-0">
						<Label className="d-block">&nbsp;</Label>
						<Button color="primary" >Create Batches</Button>
					</Colxx>:<Colxx lg="3" className="mb-3 form-group-mb-0 d-none">
						<Label className="d-block">&nbsp;</Label>
						<Button color="primary" id="update-batch-btn" >Update Batch Count</Button>
					</Colxx>}
				</Row>	
			</AvForm>
		</Card>
		
		<Batch
			containers={containers}
			packages_dropdown={packages_dropdown}
			staging_id={this.state.staging_id}
			currentIndex={currentIndex}
			showNextPrev={true}
			isPrev={this.state.running_batches.indexOf(this.state.staging_id)>0}
			isNext={this.state.running_batches.indexOf(this.state.staging_id)!==(this.state.running_batches.length-1)}
			isFinished={this.state.running_batches.indexOf(this.state.staging_id)===(this.state.running_batches.length-1)}
			updatePackages={this.updatePackages}
			prevBatch={this.prevBatch}
			nextBatch={this.nextBatch}
			completeBatching={this.completeBatching}
		/>
		
      </Fragment>
    );
  }
}
