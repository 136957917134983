import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';

import {REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";
import Batch from "../../../components/processor/Batch";
import KitchenProcessComponent from "../../../components/kitchen/KitchenProcess";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const finishedStruct = {
	id:uuid(),
	product_id:null,
	variation_id:null,
	quantity:0,
	variations:[],
}

export default class RawMaterialStaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			rrs:[],
			containers:[],
			product_packages:[],
			packages_dropdown:[],
			waste:"",
			rr:"",
			package_uid:null,		  
			step:1,	
			running_batches:[],
			products:[],
			product_variations:[],
			productsPackaging:[],
			submitted:false,
			batch_product_id:null,
			staging_id:0,
		};
		
		this.updatePackages = this.updatePackages.bind(this);
		this.prevBatch = this.prevBatch.bind(this);
		this.nextBatch = this.nextBatch.bind(this);
		this.completeBatching = this.completeBatching.bind(this);
		
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/raw-material',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var containers = data.containers;
				containers = containers.map(row=>{
					row.label=row.container_name+" ("+row.barcode+")";
					row.value=row.container_id;
					row.key=row.container_id;
					return row;
				});
				
				var products = data.products;
				products = products.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					return row_data;
				});
				
				var productsPackaging = data.products_packaging;
				productsPackaging = productsPackaging.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					return row_data;
				});
				
				let product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.package_id;
					row.key=row.package_id;
					row.package=true;
					return row;
				});
				
				let rrs = data.rrs;
				rrs = rrs.map(row=>{
					row.label=row.name+" ("+row.batch_uid+")";
					row.value=row.id;
					row.key=row.id;
					row.package=false;
					return row;
				});
				
				let packages_dropdown = [...product_packages,...rrs];
				let running_batches = [this.props.match.params.staging_id];
				let staging_id = this.props.match.params.staging_id;
				this.setState({
					containers,
					product_packages,
					rrs,
					packages_dropdown,
					products,
					productsPackaging,
					running_batches,
					staging_id
				});
				
				if(containers.length==0){
					swal("Warning!",'There is no vessel available now. Please add at least one vessel',"warning");
				}else if(packages_dropdown.length==0){
					swal("Warning!",'There is no stock available now. Please add at least one package',"warning");
				}
				
			}else{
				this.setState({					
					containers:[],
					product_packages: [],
					rrs: [],
					packages_dropdown: [],
					products:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	updatePackages(props){
		this.setState({
			...props
		});
	}
	
	prevBatch(){
		let index = this.state.running_batches.indexOf(this.props.match.params.staging_id);
		if(index!=0){
			this.setState({staging_id:this.state.running_batches[index-1] });
		}
	}
	
	nextBatch(){
		let index = this.state.running_batches.indexOf(this.props.match.params.staging_id);
		if(index!=(this.state.running_batches.length-1)){
			this.setState({staging_id:this.state.running_batches[index+1] });
		}
	}
	
	completeBatching() {		
		swal({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("complete-batching").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				formData.append('is_update',1);
				formData.append('running_batches',JSON.stringify([this.props.match.params.staging_id]));
				
				axios.post(REST_API_END_POINT+'processor/complete-raw-material-staging',formData).then((res)=>{
					var data = res.data;

					if(data.status==1){
						swal("Success!",data.msg,"success").then(()=>{
							window.location="/app/processor/all-operations";
						});
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	render() {
		const { containers, product_packages, packages_dropdown, submitted, showCreateBatch } = this.state;
		
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading_text="Edit Product Batch" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Batch
			containers={containers}
			packages_dropdown={packages_dropdown}
			staging_id={this.state.staging_id}
			currentIndex={1}
			showNextPrev={false}
			isPrev={false}
			isNext={false}
			isFinished={true}
			updatePackages={this.updatePackages}
			prevBatch={this.prevBatch}
			nextBatch={this.nextBatch}
			completeBatching={this.completeBatching}
		/>
		
      </Fragment>
    );
  }
}
