import React, { Fragment, Component } from "react";
import {
	Row, Card, Button
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, themeColor, BASE_URL, SITE_URL } from "../../constants/defaultValues";
import { uuid } from 'uuidv4';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
const dataCrypto = new SimpleCrypto(dataEncryptKey);

class HomeFooter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			configurations: {
				facility_footer_logo: null,
			},
			contact_map: '',
			social_links:[],
			footer_additional_logos:[],
			userCookie: localStorage.getItem('user_cookie_token')
		};
	}

	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		formData.append('is_contact_map', 1);
		axios.post(REST_API_END_POINT + 'website/configurations', formData).then((res) => {
			let social_links = res.data.configurations.social_links.filter(s=>{ return s.twitter!="" || s.facebook!="" || s.instagram!="" || s.linkedin!="" });
			let footer_additional_logos = res.data.configurations.footer_additional_logos.filter(s=>{ return s.logo!="" });
			this.setState({ configurations: res.data.configurations, contact_map: res.data.contact_map, social_links, footer_additional_logos, blocking: false });
		}).catch(e => {
			console.log("Addition failed, Error ", e)
			this.setState({ blocking: false });
		});

		const script = document.createElement("script");
		script.src = "https://media.twiliocdn.com/sdk/js/chat/releases/4.0.0/twilio-chat.min.js";
		document.body.appendChild(script);

		const script2 = document.createElement("script");
		script2.src = "/assets/twilio-chat/index.js";
		document.body.appendChild(script2);
	}
	
	getLinkURL(link_url){
		if(/(http(s?)):\/\//i.test(link_url)){
		}else{
			link_url = 'http://'+link_url;
		}
		return link_url;
	}
	
	render() {
		const { userCookie, configurations, contact_map } = this.state;
		return (
			<Fragment>
				<Row className="footer-layout">
					<Colxx xxs="12">
						<Card className="auth-card main-footer-wrapper no-shadow py-2">
							<div className="container">
								<Row className="w-100 mx-auto">
									<Colxx xxs="12" lg="4" xxl="3" >
										<div className="d-flex flex-wrap-wrap align-items-center mt-2">
											<div className="d-block w-100">
												{configurations.facility_footer_logo == null ? <img src="/assets/img/logo-black.svg" className="img-responsive" style={{ filter: 'invert(1)' }} /> : <img src={BASE_URL + 'pages/' + configurations.facility_footer_logo} className="img-responsive" />}
												<p className="mt-3 mb-0" style={{ whiteSpace: 'pre-wrap' }}>{configurations.footer_about}</p>
											</div>
										</div>
									</Colxx>
									<Colxx xxs="12" lg="8" xxl="9" >
										<Row>
											<Colxx xxs="12" lg="4" >
												<h2 className="bottom-line mt-3"><strong>Location</strong></h2>
												<p style={{ whiteSpace: 'pre-wrap' }}>{configurations.website_locations}</p>
											</Colxx>
											<Colxx xxs="12" lg="4" >
												<h2 className="bottom-line mt-3"><strong>STAY CONNECTED</strong></h2>
												<div className='mt-3'>
													<p className='m-0'>
														<a target="_blank" href="#" ><i className="fab fa-whatsapp-square mr-2" /></a>
														Customer Care
													</p>
													<h3>+91 8111 90 7612</h3>
												</div>
												<div className='mt-3'>
													<p className='m-0'>
														<a target="_blank" href="#" ><i className="fas fa-envelope-square mr-2" /></a>
														Email us at
													</p>
													<h3>info@nelent.com</h3>
												</div>
												<div className='mt-3'>
													<p className='m-0'>
														<a target="_blank" href="#" ><i className="fas fa-phone-square mr-2" /></a>
														Call us at
													</p>
													<h3>0466 2968855</h3>
												</div>
											</Colxx>
											<Colxx xxs="12" lg="4" >
												{this.state.social_links.map((s,ind)=>{
													return <Fragment key={ind}>
													{s.title!="" ? <h2 className="bottom-line mt-3"><strong>{s.title}</strong></h2>:""}
													<div className="social-icons dark mt-20">
														{s.twitter!="" ? <a target="_blank" href={s.twitter}><i className="fab fa-twitter" /></a>:""}
														{s.facebook!="" ? <a target="_blank" href={s.facebook}><i className="fab fa-facebook-f" /></a>:""}
														{s.instagram!="" ? <a target="_blank" href={s.instagram}><i className="fab fa-instagram" /></a>:""}
														{s.linkedin!="" ? <a target="_blank" href={s.linkedin}><i className="fab fa-linkedin-in" /></a>:""}
													</div>
													</Fragment>
												})}
												
												{this.state.footer_additional_logos.map((s,ind)=>{
													return <Fragment key={ind}>
													{s.title!="" ? <h2 className="bottom-line mt-3"><strong>{s.title}</strong></h2>:""}
													<div className="my-2">
														{s.logo_link == null || s.logo_link == "" ? 
															<img src={BASE_URL + 'pages/' + s.logo} className="img-responsive" />:
															<a href={this.getLinkURL(s.logo_link)} target="_blank" ><img src={BASE_URL + 'pages/' + s.logo} className="img-responsive" /></a>}
													</div>
													</Fragment>
												})}
											</Colxx>
										</Row>
									</Colxx>
								</Row>
							</div>
						</Card>
					</Colxx>
				</Row>
				<div className="gray-bg my-3 main-footer-wrapper">
					<div className="container py-2">
						<Row>
							<Colxx md="5" >
								<p className="d-flex flex-wrap-wrap align-items-center h-100">©2021 {projectTitle} | Powered by skyniche</p>
							</Colxx>
							<Colxx md="7">
								<ul className="main-footer-links d-flex flex-wrap-wrap align-items-center h-100">
									<li><a href={`${SITE_URL}pages/terms-conditions`} className="">Terms & Conditions</a></li>
									<li><a href={`${SITE_URL}pages/privacy-policy`} className="">Privacy Policy</a></li>
									<li><a href={`${SITE_URL}pages/cookies-policy`} className="">Cookies Policy</a></li>
								</ul>
							</Colxx>
						</Row>
					</div>
				</div>
				{userCookie == null ? <div className="cookie-policy" style={{ position: "fixed", bottom: "0", zIndex: "99999", width: '100%' }}>
					<Row>
						<Colxx lg={12}>
							<div className="pl-4 pr-4 mt-4">
								<h3 style={{ fontSize: "1.75rem", lineHeight: "2rem", color: "#000" }}>COOKIE POLICY</h3>
							</div>
							<div>
								<h5 className="underground-cookies-text pl-4 pr-4 black">
									We process personal information, including by placing cookies and other tracking technologies, for certain legitimate purposes to improve your experience and the services we provide. If you accept this processing, please click "Accept."
								</h5>
							</div>
							<div className="pl-2 pr-2 mb-4">
								<a href={`${SITE_URL}pages/cookies-policy`} className="btn btn-secondary ml-2 mr-2">LEARN MORE</a>
								<Button className="btn-secondary ml-2 mr-2" onClick={() => {
									let user_cookie_token = uuid()
									localStorage.setItem('user_cookie_token', user_cookie_token);
									this.setState({ userCookie: user_cookie_token });
								}}>ACCEPT</Button>
							</div>
						</Colxx>
					</Row>
				</div> : ""}

				<div id="Smallchat">
					<div className="Layout Layout-open Layout-expand Layout-right" style={{ backgroundColor: '#3F51B5', color: 'rgb(255, 255, 255)', opacity: 5, borderRadius: '10px' }}>
						<div className="Messenger_messenger">
							<div className="Messenger_header" >
								<h4 className="Messenger_prompt">Support</h4> <span className="chat_close_icon"><i className="fa fa-times" aria-hidden="true"></i></span>
							</div>
							<div className="Messenger_content">
								<div className="Messages">
									<div className="Messages_list" id="callcenter-chat-bot"></div>
								</div>
								<div className="Input Input-blank">
									<input id="unt-chat-input" className="Input_field" placeholder="Send a message..." style={{ height: '20px' }}></input>

									<button className="Input_button Input_button-send" id="unt-chat-btn">
										<div className="Icon" style={{ width: '18px', height: '18px' }}>
											<svg width="57px" height="54px" viewBox="1496 193 57 54" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ width: '18px', height: '18px' }}>
												<g id="Group-9-Copy-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1523.000000, 220.000000) rotate(-270.000000) translate(-1523.000000, -220.000000) translate(1499.000000, 193.000000)">
													<path d="M5.42994667,44.5306122 L16.5955554,44.5306122 L21.049938,20.423658 C21.6518463,17.1661523 26.3121212,17.1441362 26.9447801,20.3958097 L31.6405465,44.5306122 L42.5313185,44.5306122 L23.9806326,7.0871633 L5.42994667,44.5306122 Z M22.0420732,48.0757124 C21.779222,49.4982538 20.5386331,50.5306122 19.0920112,50.5306122 L1.59009899,50.5306122 C-1.20169244,50.5306122 -2.87079654,47.7697069 -1.64625638,45.2980459 L20.8461928,-0.101616237 C22.1967178,-2.8275701 25.7710778,-2.81438868 27.1150723,-0.101616237 L49.6075215,45.2980459 C50.8414042,47.7885641 49.1422456,50.5306122 46.3613062,50.5306122 L29.1679835,50.5306122 C27.7320366,50.5306122 26.4974445,49.5130766 26.2232033,48.1035608 L24.0760553,37.0678766 L22.0420732,48.0757124 Z" id="sendicon" fill="#96AAB4" fillRule="nonzero"></path>
												</g>
											</svg>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
					{/*<!--===============CHAT ON BUTTON STRART===============-->*/}
					<div className="chat_on" style={{ display: 'none' }}> <span className="chat_on_icon"><i className="fa fa-comments" aria-hidden="true"></i></span> </div>
					{/*<!--===============CHAT ON BUTTON END===============-->*/}
				</div>

			</Fragment>
		);
	}
};

export default HomeFooter;
