import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import kitchen from "./kitchen";
import allRecipes from "./allrecipes";
import AllPackagingFlows from "./all-packaging-flows";
import kitchenprocess from "./kitchenprocess";

const Kitchen = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Route path={`${match.url}/kitchen`} component={kitchen} />
			<Route path={`${match.url}/all-recipes`} component={allRecipes} />
			<Route path={`${match.url}/all-packaging-flows`} component={AllPackagingFlows} />
			<Route path={`${match.url}/kitchen-process`} component={kitchenprocess} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Kitchen  ;