import React, { Fragment, Component, Children } from "react";
import {
    Row, Container, Button, Card
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";
import { Divider } from '../../ui-elements';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

SwiperCore.use([Navigation, Autoplay]);

class Slider extends Component {
    constructor(props) {
        super(props);
		
		this.state = {
			slider: null,
			slides: [],
			blocking:true,
		};
    }
	
	componentDidMount(){
		
		const { fields } = this.props;
		let slider = fields.find(r=>{ return r.key==='slider' });
		
		if(slider.value!==''){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('status',1);
			axios.post(REST_API_END_POINT+'website/slider/'+slider.value.value,formData).then((res)=>{ 
				if(res.data.status==1){
					if(res.data.data.status==1){
						this.setState({ slider: res.data.data,slides: res.data.slides, blocking:false });
					}
				}
			}).catch(e =>{ 
				console.log("Addition failed , Error ", e) 
				this.setState({ blocking:false });
			});
		}
		
		
	}

    render() {
		const { fields } = this.props;
		const { slider, slides } = this.state;
		let head = fields.find(r=>{ return r.key==='head' });
		let sub_head = fields.find(r=>{ return r.key==='sub_head' });
		let showFooter = false;
		const params = {
			spaceBetween: 10,
			centeredSlides: true,
			loop: true,
		};
		if(slider!=null){
			if(slider.auto_play==1){
				params.autoplay={
					delay: parseInt(slider.auto_play_delay),
					disableOnInteraction: false
				};
				
			}
			if (slides.length > 1) {
				params.navigation = {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			}
			showFooter = slider.show_footer==1
		}

        return (
            <div>
				{slides.length ? <div className={head.value!="" ? 'text-center pt-4 mb-5':""}>
					{head.value!="" ? (head.value.length<50 ? <h4 className='h1'>
						{head.value}
					</h4>:<h3>
						{head.value}
					</h3>):""}
					{sub_head.value!=="" ? <p>
						{sub_head.value}
					</p>:""}
				</div>:""}
				
				{slides.length ? <Swiper {...params}>
					{slides.map((row, i) => {
						let featured_image = (isMobile || isAndroid) && row.mobile_version != null ? row.mobile_version : row.featured_image;

						let content = '';

						if (row.content != null) {
							content = JSON.parse(JSON.stringify(row.content));
						}
						if (row.button_text != null && row.button_link != null && row.button_text != "" && row.button_link != "") {
							content += `<div class="${row.button_position}"><a class="btn btn-primary btn-md mt-3" style="border-color:${row.button_background_color};background-color:${row.button_background_color};color:${row.button_color}" href="${row.button_link}">${row.button_text}</a></div>`;
						}
						return (<div className="front-home-slider" key={i} >
							<img src={`${BASE_URL}pages/${featured_image}`} />
							<div className="dynamic-page-content" dangerouslySetInnerHTML={{ __html: content }} ></div>
						</div>)
					})}
				</Swiper> : ""}
				{showFooter ? <Fragment>
					<Card className='info-card col-md-8 mx-3'>
						<Row className='p-3 align-items-center'>
							<Row className='flex-grow-1 align-items-center justify-content-center mx-0 px-2'><h6 className='ml-2 mb-0'>125+ Great Reviews</h6></Row>
							<Divider />
							<Row className='flex-grow-1 align-items-center justify-content-center mx-0 px-2'> <img src={`/assets/img/best-seller.png`} /><h6 className='ml-2 mb-0'>Quality Products</h6></Row>
							<Divider />
							<Row className='flex-grow-1 align-items-center justify-content-center mx-0 px-2'> <img src={`/assets/img/isoglobe.png`} /><h6 className='ml-2 mb-0'>9001:2015 Certified Company</h6></Row>
						</Row>
					</Card>
					<div className="mt-3"></div>
				</Fragment>:""}
            </div>

        );
    }
};

export default Slider;
