import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	Label,
	CustomInput, Badge,
	InputGroup, 
    InputGroupAddon,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import KitchenProcessComponent from "../../../components/kitchen/KitchenProcess";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";

export default class KitchenProcess extends Component {
	
	render() {
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading_text="Packaging Process" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<KitchenProcessComponent />
			</Fragment>
		);
	}
}
