import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactSortable  from "react-sortablejs";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class ReorderFlowModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			packaging_flows:this.props.packaging_flows
		};
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("package_flow-reorder").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('packaging_flows',JSON.stringify(this.state.packaging_flows.map(row=>row.id)));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'kitchen/reorder-packaging-flows',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("package_flow-reorder").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("package_flow-reorder").disabled=false;
			});
		}
	}
	
	render() {
		let packaging_flows = this.state.packaging_flows;
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Reorder Package Flows
				</ModalHeader>
				<AvForm
					id="packageFlowForm"		
					className="av-tooltip tooltip-right-bottom reorder-categories"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<ReactSortable
									tag="ul" 
									className="list-unstyled"
									options={{
										handle: ".sort-handle"
									}}
									onChange={items => {
										let packaging_flows=this.state.packaging_flows;
										let comments = [];
										for(let id of items){
											comments.push((packaging_flows.find(row=>{ return row.id===id})));
										}
										this.setState({ packaging_flows:comments })
									}}
								>	
									{packaging_flows.map((row,index)=>{
										return(<li className="comment-li" data-id={row.id} key={row.id} >
												<div className="comment-li-name" >										
													<i className="sort-handle fas fa-exchange-alt fa-rotate-90 mr-1" /> <label >{row.name}</label>
												</div>
											</li>)
									})}
								</ReactSortable>
							</Colxx>					
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="package_flow-reorder" >Submit</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
						  <IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default ReorderFlowModal;
