import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, GOOGLE_API_KEY } from "../../constants/defaultValues";

import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker, InfoWindow

} from "react-google-maps";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const google=window.google;

const defaultMapOptions = {
  zoomControl: true,
  disableDefaultUI: true
};

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key="+GOOGLE_API_KEY+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withGoogleMap,

  lifecycle({
    componentDidMount() {
		const DirectionsService = new google.maps.DirectionsService();
		var places=[];
		
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
	
		var formData = new FormData();
		formData.append('order_id',this.props.order_id);
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'transportation/get-data-for-assign-driver',formData).then((res)=>{
			
			var data = res.data;
			
			var place={};
			place = {address:data.start_loc};
			places.push(place);
			places.push({address:data.end_loc});
								
			if(places.length>1){
				const waypoints = places.map(p => ({
					location: p.address,
					stopover: true
				}));
				
				const origin = waypoints.shift().location;
				const destination = waypoints.pop().location;

				DirectionsService.route(
					{
						origin: origin,
						destination: destination,
						travelMode: google.maps.TravelMode.DRIVING,
						waypoints: waypoints
					},
					(result, status) => {
					  if (status === google.maps.DirectionsStatus.OK) {
						if(typeof result.routes[0]!='undefined'){
							let legs = result.routes[0].legs[0];
							let start_location = {lat:legs.start_location.lat(),lng:legs.start_location.lng()}
							let end_location = {lat:legs.end_location.lat(),lng:legs.end_location.lng()}
							places[0].latLng=start_location;
							places[1].latLng=end_location;
						
							var travel_duration = legs.duration.value;
							var summaryPanel = document.getElementById("google_expected_time");
							var expected_time = document.getElementById("expected_time");
							let hours = Math.floor(travel_duration / 3600);
							hours=hours >= 10 ? hours : "0"+hours.toString();					  
							let minutes = Math.floor((travel_duration / 60) % 60);
							minutes=minutes >= 10 ? minutes : "0"+minutes.toString();
							let seconds = travel_duration % 60;
							seconds=seconds >= 10 ? seconds : "0"+seconds.toString();
							summaryPanel.value = hours+":"+minutes+":"+seconds;
							expected_time.value = summaryPanel.value;
							
							this.props.setExpectedTime(summaryPanel.value);
						}
						
						this.setState({
							directions: result,
							places
						});
						
						
					  } else {
						console.error(`error fetching directions ${result}`);
					  }
					}
				);
			}									
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});              
			
			  
    }
  })
)(props => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }} defaultOptions={defaultMapOptions}>
    {props.directions && <DirectionsRenderer directions={props.directions} options={{suppressMarkers:true}} />}	
	{props.places && props.places.map((marker,i)=>{
		if(i==0){
			return(<Marker
			  key={i}
			  position={marker.latLng}
			  title={marker.address}
			>
				
			</Marker>)
		}else{
			return(<Marker
			  key={i}
			  position={marker.latLng}
			  title={marker.address}
			>
				
			</Marker>)
		}
	})}
  </GoogleMap>
));


class AssignToDriverModel extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setExpectedTime = this.setExpectedTime.bind(this);
	
		this.state = {	
			selectDriverType:this.props.drivers[0],
			google_expected_time:''
		};	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		if(this.props.modalOpen){
			if (prevProps.order_id !== this.props.order_id && this.props.order_id!=0) {			
				var selectDriverType = this.props.drivers[0];
				
				this.setState({ 
					selectDriverType
				});
            }
        }
    }
	
	setExpectedTime(value){
		this.setState({ google_expected_time:value });
	}
 
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("assign-driver").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("deliveryForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);
			
			axios.post(REST_API_END_POINT+'transportation/assign-driver',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("assign-driver").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				
				this.setState({
					selectDriverType: this.props.drivers[0],							
				});
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("assign-driver").disabled=false;
			});

		}
	}
  


	render() {
		const {  selectDrivers } = this.state;
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Assign to Driver
				</ModalHeader>
				<AvForm
					id="deliveryForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<MapWithADirectionsRenderer directions places={[]} order_id={this.props.order_id} setExpectedTime={this.setExpectedTime} />
						
						<Row className="mt-4">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Driver<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="driver_id"
										id="driver_id"									
										value={this.state.selectDriverType}						
										options={this.props.drivers}
										onChange={selectDriverType => { this.setState({ selectDriverType }) }}
									/>
									{this.props.drivers.length==0 ? <span className="text-danger">No drivers are available now. If drivers are present, please check if vehicle is assigned.</span>:""}
								</AvGroup>
							</Colxx>					
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Expected Duration By Google</Label>
									<AvField
										id="google_expected_time"
										name="google_expected_time"
										readOnly={true}
										value={this.state.google_expected_time}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="d-none">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Actual Expected Duration</Label>
									<AvField
										id="expected_time"
										name="expected_time"
										readOnly={true}
										value={this.state.google_expected_time}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="assign-driver">
							Submit
						</Button>
					</ModalFooter>
				</AvForm>
		  </Modal>
	  );
  }
};

export default AssignToDriverModel;
