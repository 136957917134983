import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card,  
  InputGroupAddon,Input, NavLink
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import data from "../../data/allWholesale";
import Barcode from 'react-barcode';
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditVendorModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			stateOptions:[],
			vendorTypes:[],
			ranks:[],
			
			same_as_vendor_name:false,
			trade_name:'',
			vendor_name:'',
			vendor:null,
			selectStateType:null,
			selectVendorType: null,
			selectRankType:null
		};	
	}
	
	componentDidMount() {		
		var stateOptions=[], vendorTypes=[], ranks=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'wholesale/get-data-for-add-vendor',formData).then((res)=>{
			var data = res.data;
				
			if(data.partner_types){
				stateOptions=data.states;			
				vendorTypes=data.partner_types;			
				ranks=data.ranks;			
				let selectStateType = null;
				let selectVendorType = null;
				let selectRankType = null;
				
				stateOptions = stateOptions.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				if(stateOptions.length){
					selectStateType = stateOptions[0];
				}
				
				vendorTypes = vendorTypes.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				if(vendorTypes.length){
					selectVendorType = vendorTypes[0];
				}
				
				ranks = ranks.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				if(ranks.length){
					selectRankType = ranks[0];
				}
			
				this.setState({
					stateOptions,
					vendorTypes,
					ranks,
					selectStateType,
					selectVendorType,
					selectRankType
				});
			}else{
				this.setState({
					stateOptions,
					vendorTypes,
					ranks,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){			
            if (prevProps.vendor_id !== this.props.vendor_id && this.props.vendor_id!=0) {				
				var formData = new FormData();
				formData.append('vendor_id',this.props.vendor_id);
				axios.post(REST_API_END_POINT+'wholesale/get-vendor-by-id',formData).then((res)=>{
					
					let vendor = res.data.vendor;
					if(typeof vendor!='undefined'){
						let selectStateType = null;
						let selectVendorType = null;
						let selectRankType = null;
						
						if(this.state.stateOptions.length){
							selectStateType = this.state.stateOptions.find(row=>{ return row.id==vendor.state_id });
						}
						
						if(this.state.vendorTypes.length){
							selectVendorType = this.state.vendorTypes.find(row=>{ return row.id==vendor.partner_type });
						}
						
						if(this.state.ranks.length){
							selectRankType = this.state.ranks.find(row=>{ return row.id==vendor.rank_id });
						}
						
						let trade_name = vendor.same_as_vendor_name=="1" ? vendor.vendor_name:vendor.trade_name;
						this.setState({ vendor, vendor_name:vendor.vendor_name,same_as_vendor_name:vendor.same_as_vendor_name=="1", trade_name, selectStateType, selectVendorType, selectRankType	});
					}		
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-vendor").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("vendorEditForm"));
			
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('vendor_id',this.props.vendor_id);
			formData.append('same_as_vendor_name',this.state.same_as_vendor_name ? 1:0);

			axios.post(REST_API_END_POINT+'wholesale/update-vendor',formData).then((res)=>{
				var data = res.data;
				document.getElementById("update-vendor").disabled=false;
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					this.props.toggleModal();
					let selectStateType = null;
					let selectVendorType = null;
					let selectRankType = null;
					
					if(this.state.stateOptions.length){
						selectStateType = this.state.stateOptions[0];
					}
					
					if(this.state.vendorTypes.length){
						selectVendorType = this.state.vendorTypes[0];
					}
					
					if(this.state.ranks.length){
						selectRankType = this.state.ranks[0];
					}
					
					this.setState({
						selectStateType,
						selectVendorType,
						selectRankType			
					});	
					this.props.dataListRender();
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-vendor").disabled=false;
			});

		}
	}
  
	onChange = e => {
		switch (e.target.name) {
			case 'license_img':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
			case 'vendor_obndd_certification':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
			break;
			default:e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};

	render() {
		const { stateOptions, vendorTypes, ranks }  = this.state;
		let modal_name = 'Vendor';
		if(this.state.vendor!=null){
			var vendor=this.state.vendor;
			if(vendor.is_vendor==0){
				modal_name = 'Distributor';
			}
		}else{
			var vendor;
		}
		
		  
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit {modal_name}
			</ModalHeader>
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				id="vendorEditForm"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					{vendor ?<Row>
						<Colxx lg="12">
							<div className="text-center float-left">
								<Barcode value={vendor.barcode} height={40} displayValue={false} />
								<p className="h5">{(vendor.barcode.match(/.{1,4}/g)).join(' ')}</p>
							</div>
						</Colxx>
					</Row>:""}
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>GSTIN<span className="text-danger">*</span></Label>
							  <AvField							
								name="gstin"
								type="text"
								autoComplete="off"
								value={ vendor?((vendor.gstin!=null)?vendor.gstin:''):'' }
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter the GSTIN"
									},									
									pattern: {
										value: "^[A-Z0-9]+$",
										errorMessage: "Invalid GSTIN"
									},
									minLength: {
										value: 15,
										errorMessage: "The GSTIN must be a 15 digits alpha-numeric number"
									},
									maxLength: {
										value: 15,
										errorMessage: "The GSTIN must be a 15 digits alpha-numeric number"
									}
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="6" className="d-none">
							<AvGroup className="error-t-negative">
								<Label>Dispensary</Label>
								<AvField
									id="company_name"
									name="company_name"
									type="text"	
									autoComplete="off"
									value={ vendor?((vendor.company_name!=null)?vendor.company_name:''):'' }
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>{modal_name} Name<span className="text-danger">*</span></Label>
								<AvField
									id="vendor_name"
									name="vendor_name"
									autoComplete="off"
									type="text"
									value={this.state.vendor_name}
									onChange={e=>{ this.setState({ vendor_name:e.target.value }); if(this.state.same_as_vendor_name){ this.setState({ trade_name:e.target.value }) } }}
										
									validate={{
									  required: {
										value: true,
										errorMessage: "Please enter the vendor name"
									  },
									  minLength: {
										value: 2,
										errorMessage:"Your vendor name must be between 2 and 150 characters"
									  },
									  maxLength: {
										value: 150,
										errorMessage: "Your vendor name must be between 2 and 150 characters"
									  }
									}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">								
							<AvGroup className="error-t-negative">
								<Label>Trade Name<span className="text-danger">*</span></Label><br/>
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.same_as_vendor_name}
										onChange={status => { if(!this.state.same_as_vendor_name){ this.setState({ trade_name:this.state.vendor_name }) } this.setState({ same_as_vendor_name:!this.state.same_as_vendor_name }) }}
									/>
									<span className="h6 ml-4">Same as Vendor Name</span>
								</Label>
								<AvField
									id="trade_name"
									name="trade_name"
									autoComplete="off"
									type="text"
									placeholder="Trade Name"
									disabled={this.state.same_as_vendor_name}
									value={this.state.trade_name}
									onChange={e=>{ this.setState({ trade_name:e.target.value }) }}
									validate={{
										required: {
											value: !this.state.same_as_vendor_name,
											errorMessage: "Please enter the trade name or select ”Same as Vendor Name” "
										},
									}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Vendor Address<span className="text-danger">*</span></Label>
							  <AvField
								id="vendor_address"
								name="vendor_address"
								autoComplete="off"
								type="textarea"
								rows="2"
								value={ vendor?((vendor.vendor_address!=null)?vendor.vendor_address:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Vendor City<span className="text-danger">*</span></Label>
							  <AvField
								id="vendor_city"
								name="vendor_city"
								autoComplete="off"
								type="text"
								value={ vendor?((vendor.vendor_city!=null)?vendor.vendor_city:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">
							<Label >Vendor State</Label>
							 <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="state_id"
								id="state_id"
								value={this.state.selectStateType}						
								options={stateOptions}
								onChange={selectStateType => { this.setState({ selectStateType }) }}
							/>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Zip code<span className="text-danger">*</span></Label>
							  <AvField
								id="zip_code"
								name="zip_code"
								type="text"
								value={ vendor?((vendor.zip_code!=null)?vendor.zip_code:''):'' }
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									maxLength: {
										value: 10,
										errorMessage:"Your vendor name must be between 2 and 100 characters"
									}
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">					
							 <AvGroup className="error-t-negative">
							  <Label>Phone<span className="text-danger">*</span></Label>
							  <AvField
								id="phone"
								name="phone"
								type="text"
								value={ vendor?((vendor.phone!=null)?vendor.phone:''):'' }
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									pattern: {
										value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
										errorMessage: "Invalid phone number"
									}
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Email</Label>
							  <AvField
								id="email"
								name="email"
								value={ vendor?((vendor.email_id!=null)?vendor.email_id:''):'' }
								autoComplete="off"
								type="text"								
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6">					
							 <AvGroup className="error-t-negative">
							  <Label>License Number</Label>
							  <AvField
								id="license"
								name="license"
								autoComplete="off"
								type="text"
								value={ vendor?((vendor.license!=null)?vendor.license:''):'' }
								
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">						
							<AvGroup className="error-l-125 error-t-negative">
								<Label>License</Label>
								<InputGroup className="mb-3">
								  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								  <CustomInput
									type="file"
									id="license_img"
									name="license_img"
									accept="image/*"
									onChange={this.onChange}
								  />
								</InputGroup>
								{ vendor?((vendor.license_img!=null)? <NavLink href={vendor.license_img_url} className="d-inline-block p-0 btn-link" target="_blank">{vendor.license_img}</NavLink>:''):'' }
							</AvGroup>					
						</Colxx>
					</Row>			
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Notes</Label>
							  <AvField
								id="notes"
								name="notes"
								autoComplete="off"
								type="textarea"
								placeholder="Sample Notes"
								rows="2"
								value={ vendor?((vendor.notes!=null)?vendor.notes:''):'' }								
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<Label >Vendor Type</Label>
							 <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="partner_type"
								id="partner_type"
								value={this.state.selectVendorType}						
								options={vendorTypes}
								onChange={selectVendorType => { this.setState({ selectVendorType }) }}
							/>
						</Colxx>
						<Colxx lg="6" className="d-none">
							<AvGroup className="error-t-negative">
							  <Label>Role</Label>
							  <AvField
								id="vendor_role"
								name="vendor_role"
								autoComplete="off"
								type="text"
								placeholder="Role"
								value={ vendor?((vendor.vendor_role!=null)?vendor.vendor_role:''):'' }
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6" className="d-none">
							<Label >Rank</Label>
							 <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="rank_id"
								id="rank_id"
								value={this.state.selectRankType}						
								options={ranks}
								onChange={selectRankType => { this.setState({ selectRankType }) }}
							/>
						</Colxx>
					</Row>
					
					<Row className="d-none">						
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Obndd Name</Label>
							  <AvField
								id="vendor_obndd_name"
								name="vendor_obndd_name"
								autoComplete="off"
								type="text"
								value={ vendor?((vendor.vendor_obndd_name!=null)?vendor.vendor_obndd_name:''):'' }
							  />
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row className="d-none">						
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Obndd #</Label>
							  <AvField
								id="vendor_obndd_no"
								name="vendor_obndd_no"
								autoComplete="off"
								type="text"
								value={ vendor?((vendor.vendor_obndd_no!=null)?vendor.vendor_obndd_no:''):'' }
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">						
							<AvGroup className="error-l-125 error-t-negative">
								<Label>Obndd Certification</Label>
								<InputGroup className="mb-3">
								  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								  <CustomInput
									type="file"
									id="vendor_obndd_certification"
									name="vendor_obndd_certification"
									accept=".png,.jpg,.jpeg,.pdf"
									onChange={this.onChange}
								  />
								</InputGroup>
							</AvGroup>
							{ vendor?((vendor.vendor_obndd_certification!=null)? <NavLink href={vendor.vendor_obndd_certification_url} className="d-inline-block p-0 btn-link" target="_blank">{vendor.vendor_obndd_certification}</NavLink>:''):'' }
						</Colxx>
					</Row>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-vendor" >
						<IntlMessages id="customer.save" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditVendorModal;
