import React, { Fragment, Component } from "react";

import {
	Row, Container,
	ListGroup, ListGroupItem
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";

function GetImage(img) {
	return (
		<img src={img} className='sectionImg' />
	)
}

class HomePageSection extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div>
				<Row>
					<Colxx xxs="12" md='12' className='d-flex flex-column align-items-start justify-content-between p-3'>
						<ListGroup className='text-left  mx-auto'>
							<ListGroupItem>
								<a href="https://www.azcentral.com/picture-gallery/entertainment/arts/2020/06/23/black-lives-matter-protests-inspire-new-mural-art-phoenix-arizona/3239828001/" target="_blank"><h6>Black Lives Matter protests inspire new murals arts in Phoenix</h6></a>
							</ListGroupItem>
							<ListGroupItem>
								<a href="https://www.azcentral.com/story/news/local/arizona/2020/03/25/gov-doug-ducey-announced-5-million-federal-gran-provide-meals-seniors-coronavirus/5084773002/" target="_blank"><h6>Arizona given $5 million in federal funding to provide meals for seniors as demand rises amid coronavirus crisis</h6></a>
							</ListGroupItem>
						</ListGroup>
					</Colxx>


				</Row>
			</div>
		);
	}
};

export default HomePageSection;
