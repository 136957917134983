import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";

import { NavLink } from "react-router-dom";

import ItemDropdown from "../../../components/communication/ItemDropdown";
import Pagination from "../../../containers/pages/Pagination";

import { REST_API_END_POINT,themeColor,dataEncryptKey, BASE_URL } from "../../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import SimpleCrypto from "simple-crypto-js";

import AddImageModal from "../../../containers/website/lightbox-gallery/AddImageModal";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class GalleryManagement extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			modalOpenAdd: false,
			refreshed: true,
			search: '',		
			selectAll: 0,		
			selectedIds: [],
			gallery: [],
			currentPage: 1,
			totalPage: 1,
		};
		this.onChangePage = this.onChangePage.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.deleteImage = this.deleteImage.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('currentPage',this.state.currentPage);
        formData.append('pageSize',18);
		axios.post(REST_API_END_POINT+'website/lightbox-gallery',formData).then((res)=>{ 
			this.setState({ gallery: res.data.data, blocking:false, refreshed:false },()=>{ this.setState({ refreshed:true }) });
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}	
	
	onChangePage = page => {
		this.setState({
			currentPage: page
		  },() => this.dataListRender());
	};
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	deleteImage = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one image","warning");
			return;
		}
		var msg_word = 'image';
		if(selectedIds.length>1){
			msg_word = 'images';
		}
		
		var msg="";
        var success_msg="";
        var failure_msg="";
		
		if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('image_ids',JSON.stringify(selectedIds));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('status',status);
				
				axios.post(REST_API_END_POINT+'website/delete-gallery-images',formData).then((res)=>{
					var data = res.data;
				
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	render() {
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}> 
					<SimpleReactLightbox>
						<Row>
							<Colxx xxs="12">
								<Breadcrumb heading="menu.lightbox-gallery" match={this.props.match} />
								<Separator className="mb-5" />
							</Colxx>
						</Row>
						<Row>   
							
							<Colxx lg="12" className="page-tabs">
								<Card className="mb-4">								
									<CardBody>
										<Row>	
											<Colxx xxs="12">
												<div id="api_message"></div>
											</Colxx> 
											<Colxx xxs="12">
												<div className="float-sm-right">
													{" "}<Button																		 
														  color="primary"
														  size="sm"
														  className=""
														  onClick={this.toggleModalAdd}
														>
														  <IntlMessages id="pages.add-new" />							  
													</Button>
												</div>
												<div className="w-100 mt-3 mb-4 dropdown-edit" style={{ clear: 'both' }}>						
													
													{this.state.refreshed ? <SRLWrapper>
														<div>
															<Row className={`text-center`}>
																{this.state.gallery.map((row,index)=>{ 
																	return(<Colxx key={index} xxs="6" md='2' className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
																		<div className="remove-section remove-g-image" onClick={e=>{ this.deleteImage(e,[row.id],2) }} ><i className="simple-icon-close"></i></div>
																		<img src={row.src} height="100%" width="100%" style={{ objectFit:'cover' }} />
																	</Colxx>);
																})}
															</Row>
														</div>
													</SRLWrapper>:""}
													
													<Pagination
														  currentPage={this.state.currentPage}
														  totalPage={this.state.totalPage}
														  onChangePage={i => this.onChangePage(i)}
													/>
												</div>
											</Colxx>
										</Row>
									</CardBody>
								</Card>
							</Colxx>
						</Row>
					</SimpleReactLightbox>
					<AddImageModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
