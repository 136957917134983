import React, { Fragment, Component } from "react";
import {
    Row, Container
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";

function GetImage(img) {
    return (
        <img src={img} className='sectionImg' />
    )
}

class HomePageSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {
		
        return (
			<div className="side-section" style={{ background: this.props.colorcbd==='#ffffff' ? 'transparent':this.props.colorcbd, color:this.props.textColor }}>
				<Container className='container-xl container-page base-section'>
					<Row className={`${this.props.className} base-section`} style={{ flexDirection: this.props.textLeft ? 'row' : 'row-reverse' }}>
						<Colxx xxs="12" md='7' className='d-flex flex-column align-items-start justify-content-between data-sect'>
							{this.props.children}
							<div className='pic-descrep' style={{ textAlign: this.props.textLeft ? 'right' : 'left' }}>
								{this.props.secondText}
							</div>
						</Colxx>
						<Colxx xxs="12" md='5'>
							<div className='sectionImg-container' style={{ background: `url('${this.props.img}')` }} />
						</Colxx>

					</Row>
				</Container>
			</div>
        );
    }
};

export default HomePageSection;
