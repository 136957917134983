import React, { Component, Fragment } from "react";
import { Row, Card,  Button, CardBody, FormGroup, Input, CustomInput } from "reactstrap";
import swal from 'sweetalert';
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import AddNewBuyOneModal from "../../../containers/customer/bogo/AddNewBuyOneModal";
import EditNewBuyOneModal from "../../../containers/customer/bogo/EditNewBuyOneModal";
import ReactTable from "react-table";
import { displayDateFormat } from "../../../helpers/Utils";
import TooltipItemText from "../../../components/common/TooltipItemText";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import moment from 'moment';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

export default class BuyOne extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);//bind the function if it invoked to another component
		this.state = {	
			modalOpenEdit:false,		  
			modalOpenAdd: false,
			data:[],
			selectedIds:[],
			bogo_id:0,
			search: '',
			selectAll:0,
			buyone:[],
			user_privileges:[],
			discountCode:null,
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.buyone.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        if(status===1){
            msg="Activate the buy one get one offers ?";
            success_msg="The buy one get one offer has been activated.";
            failure_msg="Failed to activate buy one get one offer";
        }else if(status===2){
            msg="Delete the buy one get one offers ?";
            success_msg="The buy one get one offer has been deleted.";
            failure_msg="Failed to delete buy one get one offer";
        }else{
            msg="Deactivate the buy one get one offers ?";
            success_msg="The buy one get one offer has been deactivated.";
            failure_msg="Failed to deactivate buy one get one offer";
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-bogo").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('bogo_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'point-of-sale/delete-bogo-offer',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-bogo").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-coupon").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,bogo_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(bogo_id);
			
		}else{
		  var x=selectedIds.indexOf(bogo_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.buyone.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,bogo_id=0) => {
		if(bogo_id!=0){
			this.setState({
				bogo_id: parseInt(bogo_id),
			});
		}
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-all-buy-one-get-offers',formData).then((res)=>{
			var data = res.data;
			var discountCode = res.data.discount_code;
		
			if(data.data){
				this.setState({
					buyone: data.data,
					selectAll:0,
					selectedIds:[],
					discountCode
				});
			}else{
				this.setState({
					buyone: [],
					selectAll:0,
					selectedIds:[],
					discountCode
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    componentDidMount(){  
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
  
  
  render() {
	  var array={1:'All Strains',2:'Specific Strains',4:'Specific Categories',5:'All Products',6:'Specific Products'};
	 
		let data = this.state.buyone
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.code.toLowerCase().includes(this.state.search) || row.buy_type.includes(this.state.search)
				|| row.item_name.toLowerCase().includes(this.state.search)||row.start_date.includes(this.state.search)||row.end_date.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
		{
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				return ( <FormGroup>
					<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
					</FormGroup> );
			}
		},
		{
			Header: "Name",
			accessor: "name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Code",
			accessor: "code",
			Cell: props => <p className="text-muted">{props.value}</p>
		},	  	  
		{
			Header: "Buy Product In",
			accessor: "buy_type",
			Cell: props =>  <p className="text-muted">{array[props.value]}</p>
		},
		{
			Header: "Specific Items",
			accessor: "item_name",
			width:150,
			Cell: props =>  <p className="text-muted">{props.value.length>100 ? <TooltipItemText key={"tip_str"+props.original.id} item={{
										  className:'mr-1 btn btn-primary btn-xs ',
										  placement: "bottom",
										  text: "View Products",
										  body: <p className="text-left">{props.value}</p>
										}} id={"tip_str"+props.original.id} autohide={false} />:props.value}</p>
		},
		{
			Header: "Start Date",
			accessor: "start_date",
			Cell: props => <p className="text-muted">{moment(props.value).format(displayDFormat)}</p>
		},
		{
			Header: "End Date",
			accessor: "end_date",
			Cell: props => <p className="text-muted">{moment(props.value).format(displayDFormat)}</p>
		},
		{
			Header: "Auto Apply",
			accessor: "auto_apply",
			Cell: props => <p className="text-muted">{props.value==1 ? "Yes":"No"}</p>
		},
		{
			Header: "",
			accessor: "id",
			sortable: false,
			Cell: props => this.state.user_privileges.indexOf(87)!==-1 ? <Button color="primary" size="sm" onClick={(e)=> this.toggleModalEdit(e,props.value) } >Edit</Button>:"" 
		}
	];
    return (
      <Fragment>
	  
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.buy-one-get-offers" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}{this.state.user_privileges.indexOf(86)!==-1 ?<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}									  
							>
							  <IntlMessages id="pages.add-new" />							  
						</Button>:""}{" "}{this.state.user_privileges.indexOf(88)!==-1 ?<Button
							color="primary"
							size="sm"
							id="delete-bogo"
							className=""
							onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 							
						  >
							<IntlMessages id="pages.delete" />
						</Button>:""}
					</div>
						
					<div className="mb-4 dropdown-edit">								
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: true}]}
							/>
					</div>							
				</Colxx>
			</Row>
		</Card>
		
		{this.state.user_privileges.indexOf(86)!==-1 ?<AddNewBuyOneModal
			modalOpen={this.state.modalOpenAdd}
			toggleModal={this.toggleModalAdd}
			dataListRender={this.dataListRender}
			discountCode={this.state.discountCode}
		  />:""}
		  
		{this.state.user_privileges.indexOf(87)!==-1 ?<EditNewBuyOneModal
			modalOpen={this.state.modalOpenEdit}
			toggleModal={this.toggleModalEdit}
			bogo_id={this.state.bogo_id}
			dataListRender={this.dataListRender}
		  />:""}
		
		
		</Fragment>
    );
  }
}
