import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge,  DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddListModal from "../../../containers/staffmanagement/AddListModal";
import EditListModal from "../../../containers/staffmanagement/EditListModal";
import ReactTable from "react-table";
import moment from 'moment';
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { displayDateFormat } from "../../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

export default class Listtime extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			modalOpenEdit: false,
			time:[],
			time_id:0,
			search: '',
			year:null,
			timeId:null,
			timeIds:null,
			week_number:null,
			selectedIds:[],
			week_start:null,
			week_end:null
			
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,timeId=0) => {
		this.setState({
			timeId: parseInt(timeId),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.time.forEach(x => {
                newSelected.push(x.timeId);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	componentDidMount(){
	   this.dataListRender();
	}
  
   	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="staff time";
		
		if(selectedIds.length>1){
			msg_word="staff times";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-time").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('timeIds',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'staff/delete-time-entries',formData).then((res)=>{
						
						var data = res.data;
					
						document.getElementById("delete-time").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-time").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
  
    activeItem=(e,timeIds)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(timeIds);
			
		}else{
		  var x=selectedIds.indexOf(timeIds);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.time.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
  
  
	dataListRender() {
		var user_id = localStorage.getItem('user_id');
	    var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		formData.append('target_user_id',this.props.match.params.user_id);
		formData.append('year',this.props.match.params.year);
		formData.append('week_number',this.props.match.params.week_number)
		
		axios.post(REST_API_END_POINT+'staff/time-detail',formData).then((res)=>{
			var data = res.data;
		
			if(data.time_details){
				this.setState({
					time: data.time_details,
					week_start: data.week_start,
					week_end: data.week_end,					
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					time: [],
					selectAll:0,
					selectedIds:[],
					week_start: data.week_start,
					week_end: data.week_end,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
  
	render() {
		let data = this.state.time;
		if (this.state.search) {
			data = data.filter(row => {
				return row.entryDate.toLowerCase().includes(this.state.search) || row.startTime.toLowerCase().includes(this.state.search) || row.endTime.toLowerCase().includes(this.state.search) || row.entryType.toLowerCase().includes(this.state.search) || row.edited.toLowerCase().includes(this.state.search) || row.lineTotal.toLowerCase().includes(this.state.search) 
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "timeId",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"time_"+rowInfo.original.timeId} onChange={event =>this.activeItem(event,rowInfo.original.timeId) } checked={this.state.selectedIds.includes(rowInfo.original.timeId)} />
						</FormGroup> );
				}
			},
			{
				Header: "Entry Date",
				accessor: "entryDate",
				width:110,
				Cell: props => <p className="text-muted">{moment(props.value).format(displayDFormat)}</p>
			},
			{
				Header: "Time in",
				accessor: "startTime",
				width:140,
				Cell: props => <p className="text-muted">{moment(props.value).format('HH:mm')}</p>
			},
			{
				Header: "Time Out",
				accessor: "endTime",
				Cell: props => <div dangerouslySetInnerHTML={{ __html: props.value }} ></div>
			},
			{
				Header: "Type",
				accessor: "entryType",
				Cell: props => <div dangerouslySetInnerHTML={{ __html: props.value }} ></div>
			},
			{
				Header: "Edited",
				accessor: "edited",
				width:100,
				Cell: props => <div dangerouslySetInnerHTML={{ __html: props.value }} ></div>
			},
			{
				Header: "Manual Entry Reason",
				accessor: "rows",
				Cell: props => <p className="text-muted">{props.value!=null ? props.value.manualReason:""}</p>
			},
			{
				Header: "Notes",
				accessor: "cols",
				Cell: props => <p className="text-muted">{props.value!=null ? props.value.editReason:""}</p>
			},
			{
				Header: "Total",
				accessor: "lineTotal",
				width:110,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Options",
				accessor: "timeId",
				sortable: false,
				width:85,
				Cell: props => <div className="action-btns w-9 w-sm-100">
								<ItemDropdown item={<DropdownMenu >
									<DropdownItem onClick={(e)=>this.toggleModalEdit(e,props.value)} >						
										<span className="d-inline-block" >Edit</span>
									</DropdownItem>
									<DropdownItem onClick={event =>this.deleteItem(event,[props.value],2)} >						
										<span className="d-inline-block">Delete</span>
									</DropdownItem>								
									</DropdownMenu>} 
								/>
							</div>
			}
		];
	var user_id = localStorage.getItem('user_id');
	
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.listtime" match={this.props.match} />
					<Separator className="mb-5" />
				</Colxx>
			</Row>
			
			<Card className="p-4">
				<Row>
					<Colxx xxs="12" className="text-center mb-3">
						<h4 className="font-weight-bold">{this.state.week_start!=null ? moment(this.state.week_start).format(displayDFormat):""} - {this.state.week_end!=null ? moment(this.state.week_end).format(displayDFormat):""}</h4>
					</Colxx>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
					<Colxx xxs="12">
						<div className="float-sm-right">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={this.toggleModalAdd}
								>
								Manual Time Entry							  
							</Button>																		
							{" "}<Button
								id="delete-time"
								color="primary"
								size="sm"
								className=""	
								onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
							  >
								<IntlMessages id="pages.delete" />
							</Button>
						</div>
						<div className="mb-4 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
								</Colxx>
							</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: true}]}
							/>
						</div>					
					</Colxx>
					
					<AddListModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
						user_id={user_id}
						target_user_id={this.props.match.params.user_id}
						week_start={this.state.week_start}
						week_end={this.state.week_end}
					/>
					<EditListModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender={this.dataListRender}
						timeId={this.state.timeId}
						
					/>
				</Row>		
			</Card>
		</Fragment>
    );
  }
}
