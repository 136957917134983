import React, { Fragment, Component } from "react";
import {
	Row, Container
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { SRLWrapper } from "simple-react-lightbox";

function GetImage(img) {
	return (
		<img src={img} className='sectionImg' />
	)
}

class HomePageSection extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<SRLWrapper>
				<div>
					<Row className={`text-center`}>
						<Colxx xxs="6" md='4' className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
							<img src="/assets/img/newspapers/paper1.jpg" height="100%" width="100%" />
						</Colxx>
						<Colxx xxs="6" md='4' className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
							<img src="/assets/img/newspapers/paper2.jpg" height="100%" width="100%" />
						</Colxx>
						<Colxx xxs="6" md='4' className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
							<img src="/assets/img/newspapers/paper3.jpg" height="100%" width="100%" />
						</Colxx>
						<Colxx xxs="6" md='4' className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
							<img src="/assets/img/newspapers/paper4.jpg" height="100%" width="100%" />
						</Colxx>
						<Colxx xxs="6" md='4' className='cursor-pointer d-flex flex-column align-items-start justify-content-between p-3'>
							<img src="/assets/img/newspapers/paper5.jpg" height="100%" width="100%" />
						</Colxx>
					</Row>
				</div>
			</SRLWrapper>
		);
	}
};

export default HomePageSection;
