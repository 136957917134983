import React, { Fragment, Component } from "react";
import {
	Row, Card, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu, Container
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import HeaderCart from "../../components/front-end/HeaderCart";
import MobileMenu from "../../components/front-end/MobileMenu";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import { projectTitle, REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, themeColor, BASE_URL } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import Logo from '../../assets/img/Brand/logo-black.png'
const dataCrypto = new SimpleCrypto(dataEncryptKey);

class HomeHeader extends Component {
	constructor(props) {
		super(props);
		this.ToggleDropDown = this.ToggleDropDown.bind(this);
		this.state = {
			configurations: {
				facility_footer_logo: null,
				facility_logo: null
			},
			menus: [],
			aboutOpen: false,
			userCookie: localStorage.getItem('user_cookie_token')
		};
	}

	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id', facility_id);
		axios.post(REST_API_END_POINT + 'website/configurations', formData).then((res) => {
			this.setState({ configurations: res.data.configurations, blocking: false });
		}).catch(e => {
			console.log("Addition failed, Error ", e)
			this.setState({ blocking: false });
		});

		axios.post(REST_API_END_POINT + 'website/get-menus', formData).then((res) => {
			this.setState({ menus: res.data.menus });
		}).catch(e => {
			console.log("Addition failed, Error ", e)
			this.setState({ blocking: false });
		});
	}
	ToggleDropDown() {
		this.setState({ aboutOpen: !this.state.aboutOpen })
		console.log(this.state.aboutOpen)
	}

	render() {

		const { userCookie, configurations } = this.state;
		const local_user_data = localStorage.getItem('user_data');
		const menus = this.state.menus;

		return (

			<Row>
				<Colxx xxs="12">
					<Card className="auth-card py-2 main-header-wrapper">
						<Container className="px-2 px-md-4 ">
							<Row className="w-100">

								<Colxx xxs="6" sm="3" lg="2" xxl="2" >
									<div className="d-flex flex-wrap-wrap align-items-center h-100">
										<NavLink to={`/`} className="white d-block text-center w-100">
											{configurations.facility_logo==null ? <img src="/assets/img/logo-black.svg" className="img-responsive" />:<img src={BASE_URL+'pages/'+configurations.facility_logo} className="img-responsive" style={{maxHeight:'50px'}}  />} 
												{/*<img src={Logo} className="img-responsive" style={{ maxHeight: '60px' }} />*/}
										</NavLink>
									</div>
								</Colxx>

								<Colxx xxs="9" sm="3" lg="9" xxl="9" className="d-md-block d-none mob-800" >
									<div className="d-flex  flex-wrap-wrap align-items-center h-100">
										<ul className="main-page-menu">
											<li><NavLink to={`/`} className="">Home</NavLink></li>
											{menus.map((row, ind) => {
												if (row.children.length > 0) {
													return <li key={ind}>
														<UncontrolledDropdown nav inNavbar>
															<DropdownToggle nav caret >
																{row.name}
															</DropdownToggle>
															<DropdownMenu left="true">
																{
																	row.children.map((c, i) => {
																		return <DropdownItem key={i}><a href={c.url_link} className="">{c.name}</a></DropdownItem>
																	})
																}
															</DropdownMenu>
														</UncontrolledDropdown>
													</li>
												} else {
													return <li key={ind}><a href={row.url_link} className="">{row.name}</a></li>
												}
											})}
											{local_user_data ? <li><NavLink to={`/app/dashboard`} className="">My Account</NavLink></li> : <li><NavLink to={`/user/login`} className="">Login</NavLink></li>}
										</ul>
									</div>
								</Colxx>

								{/* <Colxx xxs="3" sm="3" lg="3" xxl="3" className="d-md-block d-none mob-800" >
									<div className="d-flex menu-home flex-wrap-wrap align-items-center h-100">
										<ul className="main-page-menu">
											
										</ul>
									</div>
								</Colxx> */}
								<Colxx xxs="6" sm="2" lg="1" xxl="1" className="pr-0" >
									<HeaderCart />
								</Colxx>
							</Row>
							{/* mobile menu */}
							<MobileMenu
								menus={menus}
							/>
						</Container>
					</Card>
				</Colxx>
			</Row>
		);
	}
};

export default HomeHeader;
