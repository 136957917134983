import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Badge,
  Card, CardHeader, Table, Media,  InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import number_format from "number_format-php";
import { displayDateFormat } from "../../helpers/Utils";

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, DEFAULT_QTY_TYPES } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

export default class ViewOperationModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			operation_data: null,
			staging_products: [],
			loaded_batches: [],
		};
		
		this.dataListRender = this.dataListRender.bind(this);
	
    }
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.staging_id!== this.props.staging_id && this.props.staging_id!=0) {
				this.dataListRender();
            }
        }
    }
	
	dataListRender() {
		this.setState({ showFile:false });
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('staging_id',this.props.staging_id);
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'processor/get-raw-material-staging-batch-by-id',formData).then((res)=>{
			
			var data = res.data;
			if(data.status==1){
				this.setState({ 
					operation_data:data.operation_data,
					staging_products:data.staging_products,
					packaging_products:data.packaging_products,
					loaded_batches:data.loaded_batches,
				});
			}else{
				this.props.toggleModal();
				swal("Warning!","There is no such process","warning");
			}					
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		}); 
	}
	
    render() {
		const { operation_data, staging_products, packaging_products, loaded_batches } = this.state;
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					View Detail
				</ModalHeader>
					
				<ModalBody >
					{operation_data!=null ? <Fragment>
						<Row>
							<Colxx md="12">
								<div className="view-order-table">
									<div className="font-weight-bold text-uppercase h4">{operation_data.batch_uid}</div>
									<div className="border-bottom pt-2 mb-3" />
									<div className="d-flex flex-column">
										<Table borderless className="d-flex justify-content-start">
											<tbody>
												<tr>
													<td className="pb-0">Product Name :</td>
													<td className="text-right pb-0">{operation_data.product_name}</td>
												</tr>
												{/*<tr>
													<td className="pb-0">Waste (Grams) :</td>
													<td className="text-right pb-0">{number_format(parseFloat(operation_data.waste),2,'.','')}</td>
												</tr>*/}
												<tr>
													<td className="pb-0">RR (Grams) :</td>
													<td className="text-right pb-0">{number_format(parseFloat(operation_data.rr),2,'.','')}</td>
												</tr>
												<tr>
													<td className="pb-0">Completed Packaging :</td>
													<td className="text-right pb-0">{operation_data.packaging=="1" ? "Yes":"No"}</td>
												</tr>
											</tbody>
										</Table>
									</div>
									<div className="border-bottom pt-2 mb-3" />
									
									<div className="font-weight-bold h5">Batches</div>
									<Table responsive bordered className="special-table-layout">
										<thead>
											<tr>
												<th className="mb-2 ">Batch UID</th>						
												<th className="mb-2">Batch Start Time</th>
												<th className="mb-2 ">Batch End Time</th>						
												<th className="mb-2">Action</th>
											</tr>
										</thead>
										<tbody>
											{loaded_batches.map((row,index)=>{												
												return(<Fragment key={index}><tr >
												<td rowSpan="2">{row.batch_uid}</td>
												<td>{row.batch_start_time!=null ? row.batch_start_time:""}</td>
												<td>{row.batch_start_time!=null ? row.batch_end_time:""}</td>
												<td><Button color="primary" size="sm" onClick={e=>{ window.location="/app/processor/edit-product-batch/"+row.id; }}>Edit Batch</Button></td>
											</tr>
											{row.loaded_products.length ? <tr>
												<td colSpan="3">
													<div className="font-weight-bold h6 mb-0">Loaded Product/RR</div>
													<div style={{ maxWidth:'400px' }}>
														<Table borderless>
															{/*<thead>
																	<tr>
																		<th className="mb-2">Product/RR</th>					
																		<th className="mb-2 text-right">Quantity (Kg)</th>
																	</tr>
															</thead>*/}
															<tbody>
															{row.loaded_products.map((r,i)=>{																
																return(<tr key={i}>
																	<td className="no-bg-td">{r.product} : </td>
																	<td className="text-right no-bg-td">{number_format(parseFloat(r.quantity),2,'.','')} Kg</td>
																</tr>)
															})}
															</tbody>
														</Table>
													</div>
												</td>
											</tr>:""}
											</Fragment>)
											})}
										</tbody>
									</Table>
									
									<div className=" pt-3 mb-1" />
									<div className="font-weight-bold h5">Finished Products</div>
									<Table responsive bordered className="special-table-layout">
										<thead>
											<tr>
												<th className="mb-2">Variation</th>						
												<th className="mb-2 text-right">Quantity</th>
												<th className="mb-2 text-right">Package Date</th>
											</tr>
										</thead>
										<tbody>
											{staging_products.map((row,index)=>{
												return(<tr key={index}>
													<td>{row.variation_name!=null ? row.variation_name:""}</td>
													<td className="text-right">{number_format(parseFloat(row.quantity),2,'.','')}</td>
													<td className="text-right">{moment(row.package_date*1000).format(displayDFormat)}</td>
												</tr>)
											})}
										</tbody>
									</Table>
									
									<div className=" pt-3 mb-1" />
									<div className="font-weight-bold h5">Monocarton</div>
									<Table responsive bordered className="special-table-layout">
										<thead>
											<tr>
												<th className="mb-2">Product</th>						
												<th className="mb-2">Variation</th>						
												<th className="mb-2 text-right">Quantity</th>
											</tr>
										</thead>
										<tbody>
											{packaging_products.filter(row=> row.is_mono_carton==1).map((row,index)=>{
												return(<tr key={index}>
													<td>{row.product}</td>
													<td>{row.variation_name!=null ? row.variation_name:""}</td>
													<td className="text-right">{number_format(parseFloat(row.quantity),2,'.','')}</td>
												</tr>)
											})}
										</tbody>
									</Table>
									
									<div className=" pt-3 mb-1" />
									<div className="font-weight-bold h5">Packaging</div>
									<Table responsive bordered className="special-table-layout">
										<thead>
											<tr>
												<th className="mb-2">Product</th>						
												<th className="mb-2">Variation</th>						
												<th className="mb-2 text-right">Quantity</th>
											</tr>
										</thead>
										<tbody>
											{packaging_products.filter(row=> row.is_mono_carton!=1).map((row,index)=>{
												return(<tr key={index}>
													<td>{row.product}</td>
													<td>{row.variation_name!=null ? row.variation_name:""}</td>
													<td className="text-right">{number_format(parseFloat(row.quantity),2,'.','')}</td>
												</tr>)
											})}
										</tbody>
									</Table>
								</div>
							</Colxx>
						</Row>						
					</Fragment>:""}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.close" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
  }
};
