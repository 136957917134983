import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardBody, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { zeroPad } from "../../helpers/Utils";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

import PrintShippingManifestModalContent from "./PrintShippingManifestModalContent";
import PrintShippingManifestModalNewContent from "./PrintShippingManifestModalNewContent";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PrintShippingManifestModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			transfer_details: null,
			products_details: null,
			delivery_details: null,
			order_details: null,
			driver_details: null,
			signature: null,
			facility_data: null,
		};
	
    }
	
	componentDidUpdate(prevProps, prevState, snapshot){		
        if(this.props.modalOpen){
			if((prevProps.delivery_id!== this.props.delivery_id && this.props.delivery_id!=0) || (prevProps.order_id!== this.props.order_id && this.props.order_id!=0)) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('delivery_id',this.props.delivery_id);
				if(typeof this.props.order_id!=0){
					formData.append('order_id',this.props.order_id);
				}
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'transportation/print-manifest',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						var signature = null;
						if(data.signature){
							signature = data.signature; 
						}
						this.setState({ 
							transfer_details:data.transfer_details,
							products_details:data.products_details,
							delivery_details:data.delivery_details,
							order_details:data.order_details,
							driver_details:data.driver_details,
							signature,
							facility_data:data.facility_data,
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
		
    render() {
		const { order_details } = this.state; 
		let invoiceTitle = "Tax Invoice";
		if(order_details!=null){
			if(order_details.invoice==0){
				invoiceTitle = 'Proforma Invoice';
			}
		}
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					{invoiceTitle}
				</ModalHeader>
			 
				<ModalBody className="pt-0" style={{background:"#ccc", overflowX:"scroll"}}>
					<PrintShippingManifestModalNewContent
						ref={el => (this.componentRef = el)}
						{...this.state}
					/>
				</ModalBody>
				<ModalFooter>
					<ReactToPrint
						trigger={() => <Button color="primary" ><IntlMessages id="customer.print" /></Button>}
						content={() => this.componentRef}
						copyStyles={true}
						pageStyle={""}
					/>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
  }
};
