import React, { Fragment, Component, Children } from "react";
import {
    Row, Container, Button
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

SwiperCore.use([Navigation, Autoplay]);

class ShopByCategory extends Component {
    constructor(props) {
        super(props);
		
		this.state = {
			categories: [],
			blocking:true,
		};
    }
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'website/get-home-categories',formData).then((res)=>{ 
			this.setState({ categories: res.data.categories, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
		
	}

    render() {
		const { fields } = this.props;
		const { categories } = this.state;
		let head = fields.find(r=>{ return r.key==='head' });
		
        return (
            <div>
				{categories.length ? <Fragment><div className="text-center pt-4 pb-2 text-uppercase"><h2>{head.value}</h2></div>
					<div className="container mb-5">
						<div className="pb-4 row justify-content-center">
							{categories.map((row, i) => {
								return <a key={i} href={"/product-by-category/" + row.category_id} className="col-md-3 col-sm-6 d-block mb-5 text-center">
									<div className="hZfaLM">
										<div className="lazy-img-observer">
											<img
												className="default-category-img img-responsive"
												src={BASE_URL + 'products/' + row.thumbnail}
												alt=""
											/>
										</div>
									</div>
									<div className="h5 mt-2 text-center">{row.name}</div>
									<div className="">{`Shop now >>`}</div>
								</a>
							})}
						</div>
					</div>
				</Fragment> : ""}
            </div>

        );
    }
};

export default ShopByCategory;
