import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput, Label, InputGroup, InputGroupAddon } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import { uuid } from 'uuidv4';
import axios from 'axios';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

const socialLinkStruct = {
	id:uuid(),
	title:"",
	twitter:"",
	facebook:"",
	instagram:"",
	linkedin:"",
}

const footerAdditionalLogosStruct = {
	id:uuid(),
	title:"",
	logo:"",
	logo_link:""
}

export default class FooterManagement extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			configurations: {},
			blocking:true,
			social_links:[JSON.parse(JSON.stringify(socialLinkStruct))],
			footer_additional_logos:[JSON.parse(JSON.stringify(footerAdditionalLogosStruct))],
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.updateConfiguration = this.updateConfiguration.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.addSocialLinkRow = this.addSocialLinkRow.bind(this);
		this.deleteSocialLinkRow = this.deleteSocialLinkRow.bind(this);
		this.addAdditionalLogoRow = this.addAdditionalLogoRow.bind(this);
		this.deleteAdditionalLogoRow = this.deleteAdditionalLogoRow.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			this.setState({ configurations: res.data.configurations, social_links: res.data.configurations.social_links, footer_additional_logos: res.data.configurations.footer_additional_logos, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	updateConfiguration = (event, errors, values)  => {
		if (errors.length === 0) {
			document.getElementById("update-configurations").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("footer_management_form"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('social_links',JSON.stringify(this.state.social_links));
			formData.append('footer_additional_logos',JSON.stringify(this.state.footer_additional_logos));
			
			/*for(let key in values){
				formData.append(key,values[key]);
			}*/
			
			axios.post(REST_API_END_POINT+'website/update-configurations',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					swal("Success",data.msg,'success').then(()=>{
						window.location.reload();
					})
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					swal("Warning",data.msg,'warning');
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("update-configurations").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-configurations").disabled=false;
			});
		}
	}
	
	deleteItem =  ()  => {
		let msg="Delete the footer additional logo?";
        let success_msg="The Additional logo has been removed successfully.";
        let failure_msg="Failed to delete additional logo";
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {			
			if (willDelete) {
				this.setState({ isLoading:false });
				document.getElementById("update-configurations").disabled=true;
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				
				axios.post(REST_API_END_POINT+'website/remove-footer-extra-logo',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						swal("Success",data.msg,'success').then(()=>{
							window.location.reload();
						})
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					document.getElementById("update-configurations").disabled=false;
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("update-configurations").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
        });
    }
	
	/* Social Links*/
	addSocialLinkRow(){		
		let social_links = this.state.social_links;
		let SocialLink = JSON.parse(JSON.stringify(socialLinkStruct));
		SocialLink.id = uuid();
		social_links.push(SocialLink);
		this.setState({ social_links });
	};
	
	deleteSocialLinkRow(id){		
		let social_links = this.state.social_links;
		social_links = social_links.filter(r=>{ return r.id!=id });
		this.setState({ social_links });
	};
	
	/* Footer Additional Logos */
	addAdditionalLogoRow(){		
		let footer_additional_logos = this.state.footer_additional_logos;
		let AdditionalLogo = JSON.parse(JSON.stringify(footerAdditionalLogosStruct));
		AdditionalLogo.id = uuid();
		footer_additional_logos.push(AdditionalLogo);
		this.setState({ footer_additional_logos });
	};
	
	deleteAdditionalLogoRow(id){		
		let footer_additional_logos = this.state.footer_additional_logos;
		footer_additional_logos = footer_additional_logos.filter(r=>{ return r.id!=id });
		this.setState({ footer_additional_logos });
	};
		
	render() {
		
		const configurations = this.state.configurations	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.footer-management" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row>	
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">
											<AvForm												
												className="av-tooltip tooltip-right-bottom"
												id="footer_management_form"
												onSubmit={this.updateConfiguration}
											  >
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>About Us<span className="text-danger">*</span></Label>
															<AvField
																id="footer_about"
																name="footer_about"
																type="textarea"
																autoComplete="off"
																rows="4"
																value={configurations.footer_about}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Locations<span className="text-danger">*</span></Label>
															<AvField
																id="website_locations"
																name="website_locations"
																type="textarea"
																autoComplete="off"
																rows="4"
																value={configurations.website_locations}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Working Hours<span className="text-danger">*</span></Label>
															<AvField
																id="working_hours"
																name="working_hours"
																type="textarea"
																autoComplete="off"
																rows="4"
																value={configurations.working_hours}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<h4 className="font-weight-bold">Social Links</h4>
														{this.state.social_links.map((row,index)=>{
															return (<Card className="mt-2" key={index}>
																<CardBody className="p-4">
																	<Row>
																		<Colxx md="11">
																			<AvGroup >
																				<Label>Title</Label>
																				<AvField
																					name={"social_link_title["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.title}						
																					onChange={e => {														
																						let social_links = this.state.social_links;
																						social_links = social_links.map(r=>{
																							if(r.id==row.id){
																								r.title = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ social_links })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																		{index!==0 ? <Colxx md="1" className="text-center">
																			<span className="text-danger d-inline-block mt-4" onClick={()=>{ this.deleteSocialLinkRow(row.id); }}><i className="fas fa-2x fa-trash-alt"></i></span>
																		</Colxx>:""}
																		<Colxx md="6">
																			<AvGroup >
																				<Label>Twitter Link</Label>
																				<AvField
																					name={"social_twitter["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.twitter}						
																					onChange={e => {														
																						let social_links = this.state.social_links;
																						social_links = social_links.map(r=>{
																							if(r.id==row.id){
																								r.twitter = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ social_links })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																		<Colxx md="6">
																			<AvGroup >
																				<Label>Facebook Link</Label>
																				<AvField
																					name={"social_facebook["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.facebook}						
																					onChange={e => {														
																						let social_links = this.state.social_links;
																						social_links = social_links.map(r=>{
																							if(r.id==row.id){
																								r.facebook = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ social_links })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																		<Colxx md="6">
																			<AvGroup >
																				<Label>Instagram Link</Label>
																				<AvField
																					name={"social_instagram["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.instagram}						
																					onChange={e => {														
																						let social_links = this.state.social_links;
																						social_links = social_links.map(r=>{
																							if(r.id==row.id){
																								r.instagram = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ social_links })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																		<Colxx md="6">
																			<AvGroup >
																				<Label>Linkedin Link</Label>
																				<AvField
																					name={"social_linkedin["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.linkedin}						
																					onChange={e => {														
																						let social_links = this.state.social_links;
																						social_links = social_links.map(r=>{
																							if(r.id==row.id){
																								r.linkedin = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ social_links })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																	</Row>
																</CardBody>
															</Card>)
														})}
														<div className="mt-4 text-center">
															<Button color="primary" onClick={this.addSocialLinkRow}>Add More</Button>
														</div>
													</Colxx>
												</Row>
												
												{/*<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Twitter Link</Label>
															<AvField
																id="social_twitter_link"
																name="social_twitter_link"
																type="text"
																autoComplete="off"
																value={configurations.social_twitter_link}
																
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Facebook Link<span className="text-danger">*</span></Label>
															<AvField
																id="social_facebook_link"
																name="social_facebook_link"
																type="text"
																autoComplete="off"
																value={configurations.social_facebook_link}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Instagram Link<span className="text-danger">*</span></Label>
															<AvField
																id="social_instagram_link"
																name="social_instagram_link"
																type="text"
																autoComplete="off"
																value={configurations.social_instagram_link}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Linkedin Link</Label>
															<AvField
																id="social_linkedin_link"
																name="social_linkedin_link"
																type="text"
																autoComplete="off"
																value={configurations.social_linkedin_link}
															/>
														</AvGroup>
													</Colxx>
												</Row>*/}
												
												
												<Row className="mt-2">
													<Colxx lg="12">
														<h4 className="font-weight-bold">Footer Additional Logos (Logo of weedmap, etc)</h4>
														{this.state.footer_additional_logos.map((row,index)=>{
															return (<Card className="mt-2" key={index}>
																<CardBody className="p-4">
																	<Row>
																		<Colxx md="11">
																			<AvGroup >
																				<Label>Title</Label>
																				<AvField
																					name={"footer_additional_logo_title["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.title}						
																					onChange={e => {														
																						let footer_additional_logos = this.state.footer_additional_logos;
																						footer_additional_logos = footer_additional_logos.map(r=>{
																							if(r.id==row.id){
																								r.title = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ footer_additional_logos })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																		{index!==0 ? <Colxx md="1" className="text-center">
																			<span className="text-danger d-inline-block mt-4" onClick={()=>{ this.deleteAdditionalLogoRow(row.id); }}><i className="fas fa-2x fa-trash-alt"></i></span>
																		</Colxx>:""}
																		<Colxx md="12">
																			<AvGroup className="error-t-negative">
																				<Label>Additional Logo</Label>
																				<InputGroup className="mb-3">
																					<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
																					<CustomInput
																						type="file"
																						name={"footer_additional_logo_logo_"+index}
																						accept="image/png,image/jpeg,image/jpg,image/gif"
																						onChange={(e)=>{							
																							if(e.target.files[0]){
																								e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
																							}else{
																								e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
																							}
																						}}
																					/>
																				</InputGroup>
																			</AvGroup>
																		
																			{row.logo=="" ? "":
																				<div className="my-2 d-inline-block" style={{ maxWidth:'200px' }}>
																					<img src={BASE_URL+'pages/'+row.logo} className="img-responsive" />
																				</div>}
																		</Colxx>
																		<Colxx md="12">
																			<AvGroup >
																				<Label>Link for Additional Logo</Label>
																				<AvField
																					name={"footer_additional_logo_link["+index+"]"}
																					type="text"
																					autoComplete="off"
																					value={row.logo_link}
																					placeholder="https://"
																					onChange={e => {														
																						let footer_additional_logos = this.state.footer_additional_logos;
																						footer_additional_logos = footer_additional_logos.map(r=>{
																							if(r.id==row.id){
																								r.logo_link = e.target.value;
																							}
																							return r;
																						})
																						this.setState({ footer_additional_logos })														
																					}}
																				/>
																			</AvGroup>
																		</Colxx>
																	</Row>
																</CardBody>
															</Card>)
														})}
														<div className="mt-4 text-center">
															<Button color="primary" onClick={this.addAdditionalLogoRow}>Add More</Button>
														</div>
													</Colxx>
												</Row>
												
												{/*<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Footer Additional Logo (Logo of weedmap, etc)</Label>
															<InputGroup className="mb-3">
																<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
																<CustomInput
																	type="file"
																	name="footer_extra_logo"
																	accept="image/png,image/jpeg,image/jpg,image/gif"
																	onChange={(e)=>{							
																		if(e.target.files[0]){
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
																		}else{
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
																		}
																	}}
																/>
															</InputGroup>
														</AvGroup>
													</Colxx>
													<Colxx lg="12">
													{configurations.footer_extra_logo==null ? "":
														<div className="mt-2 px-3 d-inline-block">
															<Button color="danger" onClick={this.deleteItem} className="float-right" style={{ padding: '4px 8px', fontSize: '0.6rem' }} >X</Button>															
															<img src={BASE_URL+'pages/'+configurations.footer_extra_logo} className="img-responsive" />
														</div>}
													</Colxx>					
												</Row>
												
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Link for Additional Logo</Label>
															<AvField
																id="footer_extra_logo_url"
																name="footer_extra_logo_url"
																type="text"
																placeholder="https://"
																autoComplete="off"
																value={configurations.footer_extra_logo_url}
															/>
														</AvGroup>
													</Colxx>					
												</Row>*/}
												<Row>
													<Colxx lg="12">
														<Button color="primary" id="update-configurations" >Update</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
