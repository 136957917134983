import React, { Fragment, Component } from "react";
import {
	Row, Container
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";


class LiveNews extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div>
				<Row className={`text-center`}>
					<Colxx xxs="12" md='4' className='d-flex flex-column align-items-start justify-content-between data-sect p-3'>
						<iframe className="" title="Phoenix Finds Lacuna Kava Bar" width="100%" height="100%" src="https://www.youtube.com/embed/jBLM30-35lc?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
					</Colxx>
					<Colxx xxs="12" md='4' className='d-flex flex-column align-items-start justify-content-between data-sect p-3'>
						<iframe className="" title="Fox 10 Keepin' It Local:  Lacuna Kava Bar Kava Craze" width="100%" height="100%" src="https://www.youtube.com/embed/hHVlL8TQFQ8?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
					</Colxx>
					<Colxx xxs="12" md='4' className='d-flex flex-column align-items-start justify-content-between data-sect p-3'>
						<iframe className="" title={"The List:  Lacuna Kava Bar tells you 3 things to know before drinking kava, aka \"nature's Xanax\""} width="100%" height="100%" src="https://www.youtube.com/embed/-A32ABPTqZk?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
					</Colxx>
					<Colxx xxs="12" md='4' className='d-flex flex-column align-items-start justify-content-between data-sect p-3'>
						<iframe className="" title="Fox 10 Keepin' It Local:  Lacuna Kava Bar Kava Craze" width="100%" height="100%" src="https://12news.com/embeds/video/75-0aea558f-ea40-4e51-af55-7d5c78c6cf99/iframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
					</Colxx>
				</Row>
			</div>
		);
	}
};

export default LiveNews;
