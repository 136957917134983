import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,Card, CardBody,
  CustomInput,
  Button,
  Modal,Input,
  ModalHeader,
  ModalBody,
  ModalFooter,FormGroup,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class ViewStaffModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {		
			startDate: null,
			selectStateType:{ label: "Select State", value:"1", key: 0 },
			selectUserType:{ label:"Lead Budtender", value:"5", key: 3},
			selectRole:[],
			selectState:[],
			selectOnboarding:[],
			Permissions:[],
			AllPermissions:[],
			Facilities:[],
			AllFacilities:[],
			role_val:[],
			onboarding_val:[],
			state_val:[],
			staff:null,
			genderType:false,
			selected_permissions:[],
			facility_ids:[],
			receive_trans_notification:false,
			pay_rate:'',
			staff_roles:[{user_role:null,pay_rate:'0'}],
		};
	
	}
  
	componentDidMount() {		
		var user_roles=[];
		var states=[];
		var Permissions=[];
		var AllPermissions=[];
		var selected_permissions=[];
		var workflows=[];
		var worklocations=[];
		var Facilities=[];
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'staff/get-data-for-add-staff',formData).then((res)=>{
			var data = res.data;
		
			user_roles=data.user_roles;
			states=data.states;
			workflows=data.workflows;
			
			var selectUserType=null;
			
			user_roles = user_roles.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			let staff_roles=[];
			if(user_roles.length){
				staff_roles = [{user_role:user_roles[0],pay_rate:user_roles[0].pay_rate}]
				if(user_roles[0].user_permissions!=''){
					selected_permissions=user_roles[0].user_permissions.split(',');
				}
			}
			
			states = states.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			workflows = workflows.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
	
			this.setState({
				selectRole:user_roles,
				selectState:states,
				selectOnboarding:workflows,
				Permissions:data.staff_permissions,
				AllPermissions:data.staff_permissions,
				Facilities:data.facilities,
				AllFacilities:data.facilities,
				defaultFaclity:[facility_id],
				selected_permissions,
				staff_roles
			});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
   
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			if (prevProps.staff_id !== this.props.staff_id && this.props.staff_id!=0) {				
				var formData = new FormData();
				formData.append('staff_id',this.props.staff_id);
				axios.post(REST_API_END_POINT+'staff/get-staff-by-id',formData).then((res)=>{
					
					var data = res.data;					
					var staff=data.staff;
					
					var selectUserType = this.state.selectRole.find(row=>{ return row.id==staff.user_type });
					var startDate = moment(staff.date_of_birth);
					var receive_trans_notification = staff.receive_trans_notification==1;
					var pay_rate = staff.pay_rate;
					var staff_roles = staff.staff_roles.map(row=>{
						row.user_role = this.state.selectRole.find(ro=>{ return ro.id==row.user_role });
						return row;
					});
					
					this.setState({ 
						staff,
						selected_permissions:staff.permissions,
						facility_ids:staff.facility_ids,
						selectUserType,
						startDate,
						Permissions:[],
						genderType:false,
						receive_trans_notification,
						pay_rate,
						staff_roles
					},()=>{
						this.setState({ 
							Permissions:this.state.AllPermissions,
							genderType:true
						})
					});								
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
  render() {

    const { selectRole,selectState,selectOnboarding,Permissions,Facilities  } = this.state;

	if(this.state.staff!=null){
		var staff=this.state.staff;
	}else{
		var staff;
	}
  
	return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			View Staff Details
		  </ModalHeader>
		  
		  <ModalBody>
		  <Row>
		  <Colxx lg="8">
				<Row>
					<Colxx lg="12">
						<Label className="font-weight-bold">Full Name</Label>
						<p>{ staff?((staff.name!=null)?staff.name:''):'' }</p>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="6">
						<Label className="font-weight-bold">Gender</Label>
						<p>{ staff?((staff.gender!=null)?(staff.gender=='1'?'Male':'Female'):'Male'):'Male' }</p>
					</Colxx>
					<Colxx lg="6">
						<Label className="font-weight-bold"><IntlMessages id="customer.dob" /></Label>
						<p>{ staff ? this.state.startDate.format('DD-MM-YYYY'):'' }</p>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<Label className="font-weight-bold">Address</Label>
						<p>{ staff?((staff.address!=null)?staff.address:''):'' }</p>				
					</Colxx>					
				</Row>
				{ staff?((staff.identity_proof!='')? <Row>
					<Colxx lg="12">						
						<Label className="font-weight-bold">Identity Proof</Label>
						<div>
							<Button color="primary" onClick={()=>{
								window.open(BASE_URL+'staffs/'+staff.identity_proof)
							}} >{ staff.identity_proof }</Button>
						</div>
					</Colxx>					
				</Row>:''):'' }
				<Row>
					<Colxx lg="6">
						<Label className="font-weight-bold"><IntlMessages id="customer.phone" /></Label>
						<p>{ staff?((staff.phone!=null)?staff.phone:''):'' }</p>
					</Colxx>
					<Colxx lg="6">
						<Label className="font-weight-bold"><IntlMessages id="customer.email" /></Label>
						<p>{ staff?((staff.email_id!=null)?staff.email_id:''):'' }</p>
					</Colxx>
				</Row>
				
				{this.state.facility_ids.length > 0 ?<Row>
						<Colxx lg="12">
							<Label className="font-weight-bold">Work Locations</Label>
							<p>
								{Facilities.filter((row,index)=>{ return this.state.facility_ids.indexOf(row.id)!==-1; }).map(row=>row.name).join(', ')}
							</p>
						</Colxx>
					</Row>:""}
					<Row className="mb-2">
						<Colxx md="12">
							<h4>Staff Roles</h4>
							{this.state.staff_roles.map((row,index)=>{
								let userRole = selectRole.find(r=>{ return r.value==row.user_role.value });
								if(userRole){
									return <Card className="mb-2"><CardBody className="p-2">
										<Row>
											<Colxx md="6">
												<Label className="font-weight-bold">User Role</Label>
												<p className="mb-0">{userRole.label}</p>
											</Colxx>
										
											<Colxx md="6">
												<Label className="font-weight-bold">Pay Rate</Label>
												<p className="mb-0">{row.pay_rate}</p>
											</Colxx>					
										</Row>
									</CardBody></Card>
								}else{
									return "";
								}
							})}
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<Label className="font-weight-bold">Receive transportation related notifications</Label>
							<p>{this.state.receive_trans_notification ? 'Yes':'No'}</p>
						</Colxx>
					</Row>
				</Colxx>	
				{Permissions.length ? <Colxx lg="4"  >
					<Row> 
						<Colxx lg="12">
								<Label className="h4 font-weight-bold">Staff Permissions</Label>
								<div className="staff-scroll">
									{Permissions.map((row,index)=>{
										return <div key={index}>
											{this.state.selected_permissions.indexOf(row.upriv_id)!==-1 ? <i className="fas fa-check text-primary" />:<i className="fas fa-times text-danger" />}{" "}<span>{row.upriv_name}</span>
											</div>
									})}
								</div>
						</Colxx>
					</Row>
				</Colxx>:""}
			</Row>	
		  </ModalBody>
		  <ModalFooter>
			<Button color="secondary" outline onClick={(e)=>{ this.props.toggleModal(e); }}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
	  </Modal>
	  );
  }
};

export default ViewStaffModal;
