import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon,  DropdownItem, DropdownMenu, Card, CardBody, CardTitle
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";
import pageStructure from "../../constants/pageStructure";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {ChromePicker} from 'react-color';

import ItemDropdown from "../../components/communication/ItemDropdown";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
	[{ 'align': [] }],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    ["link"],
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",  
  "list",
  "bullet",
  "indent", 
  "link"
];

class AddPageModal extends Component{
	constructor(props) {
		super(props);
		this.state={
			template_data:{
				content:[]
			},
			products:[],
			sliders:[],
			images:[],
			show_title_head:true,
		}
		this.handleSubmit = this.handleSubmit.bind(this);	
		this.handleRemove = this.handleRemove.bind(this);
	}
	
	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var data_upload = new FormData();
		data_upload.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'website/get-products-for-block',data_upload).then((res)=>{ 
			if(res.data.status==1){
				this.setState({ products:res.data.products, sliders:res.data.sliders, images:res.data.images, blocking:false });				
			}else{
				this.setState({ blocking:false });					
			}			
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			var template_data = JSON.parse(JSON.stringify(this.state.template_data));
			
			document.getElementById("add-page").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("pageForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('template_data',JSON.stringify(template_data));
			formData.append('show_title_head',this.state.show_title_head ? 1:0);
			
			axios.post(REST_API_END_POINT+'website/add-page',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-page").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-page").disabled=false;
			});

		}
	}
	
	addBlock(row){
		let template_data = this.state.template_data;
		template_data.content.push(JSON.parse(JSON.stringify(row)));
		this.setState({ template_data });
	}
	
	handleRemove(e,index){
		var template_data=this.state.template_data;
		template_data.content = template_data.content.filter((r,i)=>{ return i!=index });
		this.setState({ template_data });
	}
	
	generateField(field,index,f_index){
		const refresh_file = this.state.refresh_file;
		if(field.type==="text" || field.type==="email" || field.type==="number"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				value={field.value}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					if(typeof template_data.content[index].fields!=='undefined'){
						template_data.content[index].fields[f_index].value = e.target.value;
					}else{
						template_data.content[index].value = e.target.value;
					}
					this.setState({ template_data });
				}}
			  />
			</AvGroup>)
		}else if(field.type==="select"){
			if(field.key=="products"){
				return(<AvGroup className="error-t-negative" key={field.key}>
					<Label>{field.label}</Label>				  
					<Select
						isMulti
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						name={field.key+"[]"}
						id={field.key}
						value={field.value}
						options={this.state.products}
						onChange={(e)=>{ 
							var template_data=this.state.template_data;
							if(typeof template_data.content[index].fields!=='undefined'){
								template_data.content[index].fields[f_index].value = e;
							}else{
								template_data.content[index].value = e;
							}
							this.setState({ template_data });
						}}
						
					/>
				</AvGroup>)	
			}else if(field.key=="images"){
				return(<AvGroup className="error-t-negative" key={field.key}>
					<Label>{field.label}</Label>				  
					<Select
						isMulti
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						name={field.key+"[]"}
						id={field.key}
						value={field.value}
						options={this.state.images}
						onChange={(e)=>{ 
							var template_data=this.state.template_data;
							if(typeof template_data.content[index].fields!=='undefined'){
								template_data.content[index].fields[f_index].value = e;
							}else{
								template_data.content[index].value = e;
							}
							this.setState({ template_data });
						}}
						
					/>
				</AvGroup>)	
			}else if(field.key=="slider"){
				return(<AvGroup className="error-t-negative" key={field.key}>
					<Label>{field.label}</Label>				  
					<Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						name={field.key+"[]"}
						id={field.key}
						value={field.value}
						options={this.state.sliders}
						onChange={(e)=>{ 
							var template_data=this.state.template_data;
							if(typeof template_data.content[index].fields!=='undefined'){
								template_data.content[index].fields[f_index].value = e;
							}else{
								template_data.content[index].value = e;
							}
							this.setState({ template_data });
						}}
						
					/>
				</AvGroup>)	
			}else{
				return(<AvGroup className="error-t-negative" key={field.key}>
				  <Label>{field.label}</Label>
				  <AvField
					name={field.key}
					type={field.type}
					autoComplete="off"
					value={field.value}
					onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
					onChange={(e)=>{ 
						var template_data=this.state.template_data;
						if(typeof template_data.content[index].fields!=='undefined'){
							template_data.content[index].fields[f_index].value = e.target.value;
						}else{
							template_data.content[index].value = e.target.value;
						}
						this.setState({ template_data });
					}}
				  >
				  {field.options.map((r,i)=>{
					  return <option key={r.key} value={r.value}>{r.label}</option>
				  })}
				  </AvField>
				</AvGroup>)			
			}
		}else if(field.type==="file"){
			return(<AvGroup className="error-t-negative" key={field.key}>
				<Label>{field.label}</Label>
				{refresh_file ? <InputGroup className="mb-3">
					<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
					<CustomInput
						type={field.type}
						id={field.key+index}
						name={field.key+index}
						accept="image/png,image/jpeg,image/jpg,image/gif"
						onChange={(e)=>{							
							if(e.target.files[0]){
								e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
							}else{
								e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
							}
						}}
					/>
				</InputGroup>:""}
				{field.value!='' ? <Row>
						<Colxx lg="8" className="mb-2">	
							<table role="presentation" className="table table-striped">
								<tbody className="files">									
									<tr className="template-download" >
										<td>												
											<Button color="link" className="open-document p-0 cursor-pointer" onClick={(e)=>{ this.toggleModalViewDoc(e,BASE_URL+'pages/'+field.value) }}>{field.value}</Button>
										</td>
										<td>
											<Button type="button" color="danger" size="sm" onClick={()=>{ 
												var template_data=this.state.template_data;
												if(typeof template_data.content[index].fields!=='undefined'){
													template_data.content[index].fields[f_index].value = '';
												}else{
													template_data.content[index].value = '';
												}
												this.setState({ template_data });
											}} >
												<i className="fa fa-trash"></i>{" "}<span className="ml-1">Delete</span>
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
						</Colxx>
					</Row>:""}
			</AvGroup>)
		}else if(field.type==="textarea"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				rows={field.key=="email_content_text" ? 10:2}
				value={field.value.split("<br/>").join("\n")}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					if(typeof template_data.content[index].fields!=='undefined'){
						template_data.content[index].fields[f_index].value = e.target.value.split("\n").join("<br/>");
					}else{
						template_data.content[index].value = e.target.value.split("\n").join("<br/>");
					}
					this.setState({ template_data });
				}}
			  />
			</AvGroup>)
		}else if(field.type==="quill"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
				<ReactQuill
					name={field.key}
					theme="snow"
					value={field.value}
					onChange={(text_content)=>{ 
						var template_data=this.state.template_data;
						if(typeof template_data.content[index].fields!=='undefined'){
							template_data.content[index].fields[f_index].value = text_content;
						}else{
							template_data.content[index].value = text_content;
						}
						this.setState({ template_data });
					}}					
					placeholder=""
					modules={quillModules}
					formats={quillFormats}
				/>
			</AvGroup>)
		}else if(field.type==="color"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
				<ChromePicker
					color={field.value}
					onChangeComplete={(color)=>{ 
						var template_data=this.state.template_data;
						if(typeof template_data.content[index].fields!=='undefined'){
							template_data.content[index].fields[f_index].value = color.hex;
						}else{
							template_data.content[index].value = color.hex;
						}
						this.setState({ template_data });
					}}
					className="no-box-shadow"
				/>
			</AvGroup>)
		}else if(field.type==="array"){
			let template_data=this.state.template_data;
			let title_label = "Iframe Title";
			let link_label = "Iframe Link";
			if(field.key=="articles"){
				title_label = "Title";
				link_label = "External Link";
			}
			return <Fragment>
				<Card>
					<CardBody>
						<h4 className="font-weight-bold">{template_data.content[index].fields[f_index].label}</h4>
						{template_data.content[index].fields[f_index].value.length>0 ? 
							template_data.content[index].fields[f_index].value.map((iframe,if_id)=>{
								return(<Card className="mb-2">
									<CardBody>
										<Row className="align-items-center">
											<Colxx lg="11">
												<AvGroup className="error-t-negative" key={if_id}>
													<Label>{title_label}</Label>
													<AvField
														name={'iframe_t_'+if_id}
														type="text"
														autoComplete="off"
														value={iframe.title}
														onChange={(e)=>{ 
															var template_data=this.state.template_data;
															template_data.content[index].fields[f_index].value[if_id].title = e.target.value;
															this.setState({ template_data });
														}}
													/>
												</AvGroup>
												<AvGroup className="error-t-negative" key={if_id+"_1"}>
													<Label>{link_label}</Label>
													<AvField
														name={'iframe_a_'+if_id}
														type="text"
														autoComplete="off"
														value={iframe.value}
														onChange={(e)=>{ 
															var template_data=this.state.template_data;
															template_data.content[index].fields[f_index].value[if_id].value = e.target.value;
															this.setState({ template_data });
														}}
													/>										  
												</AvGroup>
											</Colxx>
											<Colxx lg="1">
												{template_data.content[index].fields[f_index].value.length>1 ? <div className="remove-section float-right" onClick={e=>{
													var template_data=this.state.template_data;
													template_data.content[index].fields[f_index].value.filter((v,v_i)=>{ return v_i!=if_id });
													this.setState({ template_data });
												}} ><i className="simple-icon-close"></i></div>:""}
											</Colxx>
										</Row>
									</CardBody>
								</Card>)
						}):""}
						<Button color="primary" type="button" onClick={()=>{ 
							var template_data=this.state.template_data;
							template_data.content[index].fields[f_index].value.push({ value:'',title:'' });
							this.setState({ template_data });
						}} >Add More</Button>
					</CardBody>
				</Card>
			</Fragment>
			
		}
	}

	render() {
		const template_data = this.state.template_data;
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Page
		  </ModalHeader>
		  <AvForm
		        id="pageForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Title<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 250 characters"
							  },
							  maxLength: {
								value: 250,
								errorMessage:
								  "The name must be between 2 and 250 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row className="d-none">
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Sub title</Label>
						  <AvField
							id="sub_title"
							name="sub_title"
							type="text"
							autoComplete="off"
							validate={{
							  minLength: {
								value: 2,
								errorMessage:
								  "The sub title must be between 2 and 250 characters"
							  },
							  maxLength: {
								value: 250,
								errorMessage:
								  "The sub title must be between 2 and 250 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row  className="d-none">
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Meta Title</Label>
						  <AvField
							name="meta_title"
							type="text"
							autoComplete="off"
							validate={{
								minLength: {
									value: 2,
									errorMessage:"Meta title must be between 2 and 250 characters"
								},
								maxLength: {
									value: 250,
									errorMessage:"Meta title must be between 2 and 250 characters"
								}
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row >
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Meta Description</Label>
						  <AvField
							name="meta_description"
							type="textarea"
							autoComplete="off"
							rows={2}
							validate={{
								minLength: {
									value: 2,
									errorMessage:"Meta description must be between 2 and 400 characters"
								},
								maxLength: {
									value: 400,
									errorMessage:"Meta description must be between 2 and 400 characters"
								}
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative" >
							<Label>Header Image</Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									id="thumbnail_image"
									name="thumbnail_image"
									accept="image/png,image/jpeg,image/jpg,image/gif"
									onChange={(e)=>{							
										if(e.target.files[0]){
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
										}else{
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
										}
									}}
								/>
							</InputGroup>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.show_title_head}
								onChange={status => { this.setState({ show_title_head:!this.state.show_title_head }) }}								
							/>
							<span className="h6 ml-4">Show Title Header?</span>
						</Label>
					</Colxx>
				</Row>
				
				{template_data.content.map((row,index)=>{
					return <Row>
						<Colxx lg="12">
							<Card className="mb-3">
								<CardBody>
									<CardTitle className="font-weight-bold mb-2">{row.name}</CardTitle>
									<div className="remove-section float-right" onClick={e=>this.handleRemove(e,index)} ><i className="simple-icon-close"></i></div>
									<Row>
										{row.fields.map((field,f_index)=>{
											return <Colxx md={field.type==="color" ? "3":(field.type==="array" ? "12":"6")}>
												{this.generateField(field,index,f_index)}
											</Colxx>
										})}
									</Row>
								</CardBody>								
							</Card>
						</Colxx>
					</Row>
				})}
				<Row className="mt-2">
					<Colxx lg="12">
						<ItemDropdown
							name="Add Block"
							item={<DropdownMenu className="overflow-above-300">
								{pageStructure.map((row,i)=>{
									return <DropdownItem key={i} className="cursor-pointer" onClick={(e)=>{ this.addBlock(row) }}  >
										<span className="d-inline-block">{row.name}</span>
									</DropdownItem>
								})}
							</DropdownMenu>} 
						/>
					</Colxx>
				</Row>
			</ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-page" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddPageModal;
