import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, LITER_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class InventoryAudit extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			products:[],
			categories:[],
			sub_categories:[{ label:"--All--", value:0, key:0 }],
			product_id: { label:"All", value:0, key:0 },
			category_id: { label:"--All--", value:0, key:0 },
			sub_category: { label:"--All--", value:0, key:0 },
			blocking: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
		this.selectCategory = this.selectCategory.bind(this);
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.product_id!=null){
			formData.append('product_id',this.state.product_id.value);
		}else{
			formData.append('product_id',0);
		}
		
		if(this.state.sub_category!=null){
			formData.append('sub_category',this.state.sub_category.value);
		}else{
			formData.append('sub_category',0);
		}
		
		if(this.state.category_id!=null){
			formData.append('category_id',this.state.category_id.value);
		}else{
			formData.append('category_id',0);
		}
		
		axios.post(REST_API_END_POINT+'report/inventory-audit',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				var products = data.products;
				products = products.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				products.unshift({ label:"All", value:0, key:0 });
				
				var categories = data.categories;
				categories = categories.map(row=>{
					row.label=row.name;
					row.value=row.category_id;
					row.key=row.category_id;
					return row;
				});
				categories.unshift({ label:"--All--", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					categories,
					products,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					products: [],
					categories: [],
					sub_categories: [{ label:"--All--", value:0, key:0 }],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
		
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	selectCategory() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.category_id!=null){
			formData.append('category_id',this.state.category_id.value);
		}else{
			formData.append('category_id',0);
		}
		
		axios.post(REST_API_END_POINT+'report/get-sub-category',formData).then((res)=>{
			var data = res.data;
			if(data.categories){				
				var sub_categories = data.categories;
				sub_categories = sub_categories.map(row=>{
					row.label=row.name;
					row.value=row.category_id;
					row.key=row.category_id;
					return row;
				});
				sub_categories.unshift({ label:"--All--", value:0, key:0 });
				
				this.setState({
					sub_categories,
					blocking:false
				});
			}else{
				this.setState({
					sub_categories: [],
					blocking:false
				});
			}				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		for(let row of reports){
			let qty_type='Units';
			let quantity = Math.round(row['quantity']);
			if(row['package_type']==1){												
				if(row['qty_type']==1){
					qty_type='Grams';
					quantity = row['quantity'];
				}else if(row['qty_type']==2){
					qty_type='Kilograms';	
					quantity = (row['quantity']/LBS_CONSTANT).toFixed(2);
				}else if(row['qty_type']==4){
					qty_type='Litres';	
					quantity = (row['quantity']/LITER_CONSTANT).toFixed(2);
				}
			}
			
			formated_data.push([
				row.name,
				row.category,
				row.sub_category,
				(quantity>0 ? "<span style='font-weight:700' >"+quantity+' '+qty_type+"</span>":"<span class='text-danger' style='font-weight:700' >0</span>"),
				row.shop_status==1 ? "Yes":"No",
				row.source==1 ? "Vending":(row.source==2 ? "Grow House":"Inventory")
			])
		}
		return formated_data;
	}
	
  
	render() {	
		const reports = this.state.reports;
		const products = this.state.products;
		const { categories, sub_categories } = this.state;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.inventory-audit" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="10">
								<Label>Product</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="product_id"
									id="product_id"
									placeholder="All"
									value={this.state.product_id}						
									options={products}
									onChange={product_id => { this.setState({ product_id }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="category_id"
									id="category_id"
									placeholder="All"
									value={this.state.category_id}						
									options={categories}
									onChange={category_id => { this.setState({ category_id },()=>{ this.selectCategory() }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Sub Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="sub_category"
									id="sub_category"
									placeholder="All"
									value={this.state.sub_category}						
									options={sub_categories}
									onChange={sub_category => { this.setState({ sub_category }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Name</th>										  
												<th>Category</th>										  
												<th>Sub Category</th>
												<th>Available Qty</th>
												<th>In Shop</th>
												<th>Source</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
