import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT, dataEncryptKey, themeColor, JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";
import Packaging from "../../../components/processor/Packaging";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class EditPackaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			submitted:false
		};
		
		
	}
	
	render() {
		const { submitted } = this.state;
		
    return (
      <Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.edit-packaging" match={this.props.match} />
					<Separator className="mb-5" />
				</Colxx>
			</Row>
		
			<Card className="p-4 mb-3">
				{this.props.match.params.staging_id ? <Packaging 
						staging_id={this.props.match.params.staging_id}
						update={true}
					/>:""}
			</Card>		
      </Fragment>
    );
  }
}
