import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { displayDateFormat } from "../../../helpers/Utils";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT, dataEncryptKey, themeColor, JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";
import Packaging from "../../../components/processor/Packaging";

const dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

export default class RawMaterialStaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			batches:[],
			blocking:true,
			submitted:false,
			staging_batch:null,
		};
		
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('packaging',0);
		
		
		axios.post(REST_API_END_POINT+'processor/packaging',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var batches = data.batches;
				batches = batches.map(row=>{
					row.label=row.product_name+" ("+row.batch_uid+")";
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				
				var productsPackaging = data.products_packaging;
				productsPackaging = productsPackaging.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					return row_data;
				});
				
				this.setState({
					batches,
					productsPackaging,
					blocking:false
				});
				
				if(batches.length==0){
					swal("Warning!",'There is no batches available for packaging. Please process at least one batch',"warning");
				}else if(productsPackaging.length==0){
					swal("Warning!",'There is no products available for packaging. Please enable at least one product for packaging',"warning");
				}
				
			}else{
				this.setState({					
					batches:[],
					productsPackaging: [],
					blocking:false
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {
		const { submitted } = this.state;
		
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.packaging" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4 mb-3">
			<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
				
				<Row className="">
					<Colxx lg="12" className="mb-3 form-group-mb-0">
						<Label>Batch<span className="text-danger">*</span></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name={"staging_batch"}
							id={"staging_batch"}
							placeholder="-- Select Batch --"
							value={this.state.staging_batch}						
							options={this.state.batches}
							onChange={staging_batch => {
								this.setState({ staging_batch:null },()=>{ this.setState({ staging_batch }); })
							}}
						/>
						{submitted ? (this.state.staging_batch==null ? <span className="text-danger">Please select batch</span>:""):""}
					</Colxx>
					
					{this.state.staging_batch!=null ? <Packaging 
						staging_id={this.state.staging_batch.value}
						update={false}
					/>:""}
					
				</Row>	
				
			</BlockUi>
		</Card>
		
		
      </Fragment>
    );
  }
}
