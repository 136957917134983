import React, { Fragment, Component } from "react";
import {
	Row, Container
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";

function GetImage(img) {
	return (
		<img src={img} className='sectionImg' />
	)
}

class HomePageSection extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let margin = typeof this.props.margin=='undefined' ? '5':this.props.margin;
		return (
			<div className={"side-section my-"+margin} style={{ background: this.props.colorcbd === '#ffffff' ? 'transparent' : this.props.colorcbd, color: this.props.textColor, ...this.props.style }}>
				<Container className='container-xl container-page base-section'>
					<div className={`${this.props.className} ${margin!='0' ? 'text-center':''}`}>
						{this.props.children}
					</div>
				</Container>
			</div>
		);
	}
};

export default HomePageSection;
