/* Tridant Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Dashboards
05.Pages
06.Applications
  06.01.Chat
  06.02.Survey
  06.03.Todo
07.UI
  07.01.Alerts
  07.02.Badges
  07.03.Buttons
  07.04.Cards
  07.05.Carousel
  07.06.Charts
  07.07.Collapse
  07.08.Dropdowns
  07.09.Editors
  07.10.Forms
  07.11.Form Components
  07.12.Icons
  07.13.Input Groups
  07.14.Jumbotron
  07.15.Modal
  07.16.Navigation
  07.17.Popover & Tooltip
  07.18.Sortable
  07.19.Maps
  07.20.Tables
*/

module.exports = {
  /* 01.General */
  "general.copyright": "Seed to Sale Software © 2020 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.login-title": "Login",
  "user.register": "Register",
  "user.forgot-password": "Forgot Password",
  "user.email": "E-mail *",
  "user.password": "Password *",
  "user.confirmpassword": "Confirm New Password *",
  "user.reset-password": "Reset Password *",
  "user.new_password": "New Password *",
  "user.confirm_password": "Confirm Password *",
  "user.forgot-password-question": "Forgot password?",
  "user.fullname": "Full Name",
  "user.firstname": "First Name *",
  "user.first_name": "First Name *",
  "user.lastname": "Last Name",
  "user.last_name": "Last Name",
  "user.companyname": "Company Name *",
  "user.phone": "Phone *",
  "user.affiliation": "Affiliation *",
  "user.howdidyouhear": "How did you hear about us?",
  "user.login-button": "LOGIN",
  "user.register-button": "REGISTER",
  "user.reset-password-button": "RESET",
  "user.buy": "BUY",
  "user.username": "Username",
  "user.name": "Name",
  "user.friendly": "Friendly Display Name",

  /* 03.Menu */
  "menu.app": "Home",
  "menu.dashboard": "Dashboard",
  "menu.website": "Website",
  "menu.page-management": "Pages",
  "menu.posts": "Posts",
  "menu.testimonials": "Testimonials",
  "menu.home_sliders": "Home Sliders",
  "menu.home-sliders": "Home Sliders",
  "menu.sliders": "Sliders",
  "menu.lightbox-gallery":"Lightbox Gallery",
  "menu.happy-hour-sliders": "Happy Hour Sliders", 
  "menu.edit-page": "Edit Page",
  "menu.customer-directory": "Distributor Lookup",
  "menu.coupons": "Coupons",
  "menu.customer": "Distributors Management",
  "menu.all-customers": "All Distributors",
  "menu.buy-one-get-offers": "Buy One Get One Offers",
  "menu.customer-points": "All Distributor Points",
  "menu.customer-pos": "Customer Pos",
  "menu.point-of-sale": "Point Of Sale",
  "menu.addbuyone": "Add Buy One Get One Offer",
  "menu.account": "Accounts Management", 
  "menu.all-accounts": "All Accounts", 
  "menu.wholesale": "Wholesale Order Management", 
  "menu.completed-orders": "Completed Orders",
  "menu.account-invoice": "Invoice",
  "menu.add-to-account": "Add to Account",
  "menu.all-recipes": "All Package Setups",
  "menu.shelf-types": "Shelf Types",
  "menu.all-warehouses": "All Warehouses",
  "menu.view-warehouse": "View Warehouse",
  "menu.add-recipe": "Add Recipe",
  "menu.transportation": "Transportation",
  "menu.drivers": "Drivers",
  "menu.shipper-information": "Shipper Information",
  "menu.process-overview": "Process Overview",
  "menu.vehicles": "Vehicles",
  "menu.columns": "Columns",
  "menu.vessels": "Vessels",
  "menu.column-filling":"Column Filling",
  "menu.crc-recipes":"CRC Recipes",
  "menu.equipment_dependencies":"Equipment Dependencies",
  "menu.packaging": "Packaging Process",
  "menu.edit-packaging": "Edit Packaging",
  "menu.kitchen": "Packaging",
  "menu.extractor-operation": "Extractor Operation",
  "menu.oven-operation": "Oven Operation",
  "menu.all-operations": "All Operations",
  "menu.visitors": "Visitors",
  "menu.staff-management": "Staff Management",
  "menu.leaf-management": "Activity Log",
  "menu.staff-scheduler": "Staff Scheduler",
  "menu.raw-material-staging": "Raw Material Staging",
  "menu.staff-time-zone": "Staff Time Tracking",
  "menu.staffmanagement": "Staff Management",
  "menu.mycalendar": "My Calendar",
  "menu.my-calendar": "My Calendar",
  "menu.processor": "Processor",
  "menu.equipments": "Equipments",
  "menu.staff-directory": "Staff Directory",
  "menu.user-roles": "User Roles",
  "menu.department": "Department",
  "menu.warehouse": "Warehouse",
  "menu.bankmanagement": "Bank Management",
  "menu.bank-management": "Bank Management",
  "menu.drawers-management": "Drawers Management",
  "menu.external-banks": "External Banks",
  "menu.payouts": "Payouts",
  "menu.my-reports": "My Reports",
  "menu.inventory1": "Inventory",
  "menu.sales": "Sales",
  "menu.myreports": "My Reports",
  "menu.myjobs": "My Jobs",
  "menu.staff": "Staff",
  "menu.customers1": "Distributors",
  "menu.payments": "Payments",
  "menu.all-jobs": "All Jobs",
  "menu.add-job": "Add Job",
  "menu.official-requests": "Official Requests",
  "menu.your-assigned-jobs": "Your Assigned Jobs",
  "menu.track-drivers": "Track Drivers",
  "menu.internal-bank": "Internal Bank",
  "menu.kitchen-process": "Packaging Process",
  "menu.sacks": "Socks",
  "menu.jars": "Jars",
  "menu.crc-operation": "CRC Operation",
  "menu.mcp-operation": "MCP Operation",
  "menu.evaporator-operation": "Evaporator Operation",
  "menu.view-account": "View Account",
  "menu.all-distributions": "All Distributions",
  "menu.inventory":"Inventory",
  "menu.inventories":"Inventory",
  "menu.strain_types":"Strain Types",
  "menu.category_types":"Category Types",
  "menu.current-inventory":"Current Inventory",
  "menu.rooms":"Rooms",
  "menu.distillate-bucket":"Distillate Bucket",
  "menu.pricing-groups":"Pricing Groups",
  "menu.strains":"Strains",
  "menu.product-category":"Product Category",
  "menu.type":"Type",
  "menu.tax-category":"Tax Category",
  "menu.inventory-audit":"Inventory Audit",
  "menu.waste-management":"Waste Management",
  "menu.additive-templates":"Additive Templates",
  "menu.inventory-transfer":"Inventory Transfer",
  "menu.outgoing-transfer":"All Outgoing Transfers",
  "menu.incoming-transfer":"All Incoming Transfers",
  "menu.outgoing-invoice":"Invoice",
  "menu.create-order":"Select Facility",
  "menu.transfer":"Transfer",
  "menu.allwholesale":"Wholesale Order Management",
  "menu.all-vendors":"All Vendors",
  "menu.view-vendor":"View Vendor",
  "menu.assignment":"Assignment",
  "menu.rfprequest":"RFP Request",
  "menu.all-services":"All Services",
  "menu.distillation-operation":"Distillation Operation",
  "menu.vendor-types":"All Vendor Types",
  "menu.all-groups":"All Vendor Groups",
  "menu.allcomplaints":"All Complaints",
  "menu.groups-list":"All Groups",
  "menu.groups":"Groups",
  "menu.growhouse":"Growhouse",
  "menu.all-rooms":"All Rooms",
  "menu.all-tables":"All Tables",
  "menu.all-table-types":"All Table Types",
  "menu.add-plant-to-table":"Add Plants to Tables",
  "menu.all-plants":"All Plants",
  "menu.edit-plant":"Edit Plant",
  "menu.newinspection": "New Inspection",
  "menu.view-inspection": "Inspection Details",
  "menu.sendemail": "SENT EMAIL",
  "menu.clientview": "Client View",
  "menu.reports": "Reports",
  "menu.waste": "Waste",
  "menu.reportsview": "Reports View",
  "menu.viewlog": "REPORT VIEW LOG",
  "menu.rrblog": "RRB LOG",
  "menu.inspections": "Inspections",
  "menu.calender": "Calender",
  "menu.express-calendar": "Express Calendar",
  "menu.templateeditor": "Template Editor",
  "menu.templates": "Templates",
  "menu.people": "Distributors Management",
  "menu.communication": "Communication",
  "menu.team": "Team",
  "menu.metrics": "Business",
  "menu.onboarding": "Onboarding",
  "menu.create-a-new-workflow": "Create a New Workflow",
  "menu.subscriptions": "Subscriptions",
  "menu.payment-detail": "Payment Detail",
  "menu.settings": "Settings",
  "menu.profile": "Profile",
  "menu.complaints-and-comments": "Complaints and Comments",
  "menu.createworkflow": "Create Work Flow",
  "menu.publicprofile": "Public Profile",
  "menu.requestinspection": "REQUEST AN INSPECTION",
  "menu.agentinspection": "AGENT INSPECTION REQUEST",
  "menu.editprofile": "Edit Profile",								 
  "menu.edit-report": "Edit Report",								 
  "menu.allorders": "All Orders",
  "menu.email-queue": "Email Queue",
  "menu.email-sent": "Email Sent",
  "menu.text-queue": "Text Queue",
  "menu.text-sent": "Text Sent",
  "menu.import-agent": "Import Agents",
  "menu.import-isn-agent": "ISN Importer",
  "menu.place-order": "Place Order",
  "menu.daily-deals": "Daily Deals",
  "menu.mix-and-match-deals": "Mix and Match Deals",
  "menu.daily-deals-calendar": "Deals Calendar",
  "menu.reverse-tax-calculator": "Reverse Tax Calculator",
  "menu.holded-transfer": "Held Transfer Orders",
  "menu.cash-exchange": "Cash Exchange",
  "menu.printer-settings": "Printer Settings",
  "menu.contact-form-entries": "Contact Form Entries",
  
  "menu.room": "Room",
  "menu.table": "Table",
  "menu.shelf-view": "Shelf",
  "menu.plant-orders":"Grow Inventory",
  "menu.email-templates": "Email Templates",
  "menu.text-message-templates": "Text Message Templates",
  "menu.discountcode": "Discount codes",
  "menu.services": "Services",
  "menu.stafftime": "Staff Time",
  "menu.listtime": "List Time",
  "menu.warehouse-view": "Warehouse View",
  "menu.external-bank-view": "External Bank View",
  "menu.drawer-view": "Drawer Detail",
  "menu.jobs": "My Jobs",
  "menu.all-folders": "All Folders",
  "menu.sub-folders": "All Modules",
  
  "menu.default": "Default",
  "menu.analytics": "Analytics",
  "menu.ecommerce": "Ecommerce",
  "menu.content": "Content",
  "menu.pages": "Pages",
  "menu.data-list": "Data List",
  "menu.thumb-list": "Thumb List",
  "menu.image-list": "Image List",
  "menu.details": "Details",
  "menu.search": "Search",
  "menu.login": "Login",
  "menu.register": "Register",
  "menu.forgot-password": "Forgot Password",
  "menu.error": "Error",
  "menu.applications": "Applications",
  "menu.todo": "To-do List",
  "menu.survey": "Survey",
  "menu.chat": "Chat",
  "menu.ui": "UI",
  "menu.alerts": "Alerts",
  "menu.badges": "Badges",
  "menu.buttons": "Buttons",
  "menu.cards": "Cards",
  "menu.carousel": "Carousel",
  "menu.charts": "Charts",
  "menu.collapse": "Collapse",
  "menu.dropdowns": "Dropdowns",
  "menu.editors": "Editors",
  "menu.form-layouts": "Form Layouts",
  "menu.form-components": "Form Components",
  "menu.form-validations": "Form Validations",
  "menu.icons": "Icons",
  "menu.input-groups": "Input Groups",
  "menu.jumbotron": "Jumbotron",
  "menu.modal": "Modal",
  "menu.navigation": "Navigation",
  "menu.popover-tooltip": "Popover & Tooltip",
  "menu.sortable": "Sortable",
  "menu.tables": "Tables",
  "menu.menu": "Menus",
  "menu.subhidden": "Subhidden",
  "menu.hidden": "Hidden",
  "menu.visible": "Visible",
  "menu.maps":"Maps",
  "menu.template-center":"Template Store",

  "menu.landingpage": "Landing Page",
  "menu.multipage-home": "Multipage Home",
  "menu.singlepage-home": "Singlepage Home",
  "menu.about": "About",
  "menu.auth-login": "Auth Login",
  "menu.auth-register": "Auth Register",
  "menu.blog": "Blog",
  "menu.blog-detail": "Blog Detail",
  "menu.careers": "Careers",
  "menu.confirmation": "Confirmation",
  "menu.contact": "Contact",
  "menu.docs": "Docs",
  "menu.features": "Features",
  "menu.prices": "Prices",
  "menu.videos": "Videos",
  "menu.mailing": "Mailing",
  "menu.invoice": "Invoice",
  "menu.blank-page": "Blank Page",
  "menu.types": "Menu Types",
  "menu.levels": "Menu Levels",
  "menu.third-level-1": "Third Level 1",
  "menu.third-level-2": "Third Level 2",
  "menu.third-level-3": "Third Level 3",
  "menu.invoices": "Invoices",
  "menu.pastPayments": "Past Payments",
  "menu.calendar": "Calendar",
  "menu.buyone": "Buy One Get One Offers",
  "menu.currentinventory": "Inventory",
  "menu.customer-lookup": "Distributor Lookup",
   
  "menu.customfieldtemplate":"Custom Field Templates",
  "menu.questionnairetemplates":"Questionnaire Templates",
  "menu.signagreements":"Sign Agreements",
  
  "menu.inspection-agreement": "Inspection agreement",
  "menu.inspectionagreement": "Inspection Agreement",
  "menu.editinspection-agreement": "Edit inspection agreement",
  "menu.online-scheduler": "Online Scheduler",
  "menu.availability": "Availability",
  "menu.editemail": "Edit Email",
  "menu.edittext": "SENT TEXT MESSAGE",
  "menu.complaints": "Complaints and Comments",
  "menu.complaint-view": "Complaint View",
  "menu.complaint-close": "Close Complaint",
  "menu.close-complaint": "Close Complaint",
  "menu.signshipping": "SIGN SHIPPING",
  "menu.edit-product":"Edit Product",
  
  "menu.grow-house": "Growhouse",
  "menu.group": "Groups",
  "menu.plantorders": "All Orders",
  "menu.plant-audit": "Plant Audit",
  "menu.batch": "Batches",
  "menu.batchoption": "Batch Options",
  "menu.extractorloading": "Extractor Loading",
  "menu.distloading": "Distillation Loading",
  "menu.evaloading": "Evaporation Loading",
  "menu.mcploading": "Mcp Operation Loading",
  "menu.ovenloading": "Oven Operation Loading",
  "menu.crcloading": "CRC Operation Loading",
  
  "menu.inventoryadjustment": "Inventory Adjustment",
  "menu.inventoryforensic": "Inventory Forensic",
  "menu.salesreport": "Sales Report",
  "menu.sales-by-category": "Sales by Category",
  "menu.sales-counts": "Sales Counts",
  "menu.sales-count-hour": "Sales Count By Hour",
  "menu.sales-count-day": "Sales Count By Day",
  "menu.sales-count-day-hour": "Sales Count By Day and Hour",
  "menu.best-sellers": "Best Sellers",
  "menu.best-sellers-quantity": "Best Sellers by Quantity",
  "menu.worst-sellers": "Worst Sellers",
  "menu.worst-sellers-quantity": "Worst Sellers By Quantity",
  "menu.discount-detail": "Discount Detail",
  "menu.discounts": "Discounts",
  "menu.discount-by-staff": "Discounts by Staff",
  "menu.discount-by-product": "Discount by Product",
  "menu.auto-apply-discounts": "Auto Apply Discounts",
  "menu.cash-close": "Cash Close",
  "menu.cash-close-detail": "Cash Close Detail",
  "menu.wholesale-report": "Wholesale Report",
  "menu.wholesale-payments": "Wholesale Payments",
  "menu.void-tickets": "Voided tickets",
  "menu.sales-trends": "Sales Trends Report",
  "menu.sales-demographics": "Sales Demographics Report",
  "menu.complex-sales": "Complex Sale Report",
  "menu.consolidated-sales-report": "Consolidated Sale Report",
  "menu.production-process": "Production Process",
  "menu.customers": "Distributors",
  "menu.patients": "Patients",
  "menu.thc-report": "THC Report",
  "menu.staffs": "Staffs",
  "menu.pos": "POS",
  "menu.placeorder": "Place Order",
  
  "menu.plant-inventory": "Plant Inventory",
  "menu.historical-plant-inventory": "Historical Plant Inventory",
  "menu.plant-summary": "Plant Summary",
  "menu.plant-movement": "Plant Movements",
  "menu.removed-plants": "Removed Plants",
  "menu.strain-counts": "Strain Counts",  
  "menu.mother-yields": "Mother Yields",
  "menu.yields": "Yields",
  "menu.yields-statistics": "Yield Statistics",
  "menu.grow-cost-tracking": "Grow Cost Tracking",
  
  "menu.all-receive-inventory-orders": "Receive Inventory Orders",
  "menu.lab-testing-calendar": "Lab Testing Calendar",
  "menu.view-job": "View Job",
  "menu.task-activities": "Activities",
  
  "menu.all-orders": "All Orders",
  "menu.select-facility": "Select Facility",
  "menu.metrc-label-settings": "Metrc Label Settings",
  
  "menu.historical-inventory": "Historical Inventory",
  "menu.inventory-adjustment": "Inventory Adjustments",
  "menu.inventory-forensics": "Inventory Forensics",
  "menu.destruction-events": "Destruction Events",
  "menu.products": "Products",
  "menu.product-pricing": "Product Pricing",
  "menu.receive-inventory": "Receive Inventory",
  "menu.footer-management": "Footer Management",
  "menu.locations": "Metrc Locations",
  "menu.contact-form": "Contact Form",
  "menu.all-packaging-flows": "All Packaging Flows",
  "menu.best-salesman": "Best Salesman",
  
  /* Administrtor */
  "menu.administrator": "Administrtor",
  "menu.users": "Subscribers",
  "menu.user-detail": "Subscriber Detail",
  "menu.subscription-plans": "Subscription Plans",
  "menu.email-management": "Email Management",
  "menu.payment-history": "Payment History",
  "menu.user-reports": "Subscribers Reports",
  "menu.payments-reports": "Payments Reports",
  "menu.subscriptions-reports": "Subscriptions Reports",
  "menu.edit": "Edit",
  
  /* 04.Dashboards */
  "dashboards.todays-inspections": "Today",
  "dashboards.subscribe": "Subscribe",
  "dashboards.new-inspector": "New Inspector?",
  "dashboards.whats-new": "What's new",
  "dashboards.tracking-map": "Map",
  "dashboards.in-progress": "In Progress",
  "dashboards.pending-orders": "Pending Orders",
  "dashboards.completed-orders": "Completed Orders",
  "dashboards.refund-requests": "Refund Requests",
  "dashboards.new-comments": "New Comments",
  "dashboards.sales": "Sales",
  "dashboards.orders": "Orders",
  "dashboards.refunds": "Refunds",
  "dashboards.recent-orders": "Recent Orders",
  "dashboards.product-categories": "Product Categories",
  "dashboards.cakes": "Cakes",
  "dashboards.tickets": "Tickets",
  "dashboards.calendar": "Calendar",
  "dashboards.best-sellers": "Best Sellers",
  "dashboards.website-visits": "Website Visits",
  "dashboards.unique-visitors": "Unique Visitors",
  "dashboards.this-week": "This Week",
  "dashboards.last-week": "Lat Week",
  "dashboards.this-month": "This Month",
  "dashboards.conversion-rates": "Conversion Rates",
  "dashboards.per-session": "Per Session",
  "dashboards.profile-status": "Profile Status",
  "dashboards.payment-status": "Payment Status",
  "dashboards.work-progress": "Work Progress",
  "dashboards.tasks-completed": "Tasks Completed",
  "dashboards.payments-done": "Payments Done",
  "dashboards.order-stock": "Order - Stock",
  "dashboards.categories": "Categories",
  "dashboards.quick-post": "Quick Post",
  "dashboards.title": "Title",
  "dashboards.content": "Content",
  "dashboards.category": "Category",
  "dashboards.save-and-publish": "Save and Publish",
  "dashboards.top-viewed-posts": "Top Viewed Posts",
  "dashboards.posts": "Posts",
  "dashboards.pending-for-publish": "Pending for publish",
  "dashboards.users": "Users",
  "dashboards.on-approval-process": "On approval process",
  "dashboards.alerts": "Alerts",
  "dashboards.waiting-for-notice": "Waiting for notice",
  "dashboards.files": "Files",
  "dashboards.pending-for-print": "Pending for print",
  "dashboards.logs": "Logs",
  "dashboards.gogo": "GOGO",
  "dashboards.magic-is-in-the-details": "MAGIC IS IN THE DETAILS",
  "dashboards.yes-it-is-indeed": "Yes, it is indeed!",
  "dashboards.advanced-search": "Advanced Search",
  "dashboards.toppings": "Toppings",
  "dashboards.type": "Type",
  "dashboards.keyword": "Keyword",
  "dashboards.search": "Search",
  "dashboards.top-rated-items": "Top Rated Items",

  /* 05.Pages */
  "pages.add-new": "Add New",
  "pages.import": "Import",
  "pages.export": "Export",
  "pages.add-new-modal-title": "Add New Item",
  "pages.subject": "Subject",
  "pages.save_email_template": "Save Email Template",
  "pages.save_text_template": "Save Text Message Template",
  "pages.display-options": "Display Options",
  "pages.orderby": "Order By : ",
  "pages.product-name": "Product Name",
  "pages.category": "Category",
  "pages.description": "Description",
  "pages.status": "Status",
  "pages.cancel": "Cancel",
  "pages.submit": "Submit",
  "pages.delete": "Delete",
  "pages.activate": "Activate",
  "pages.active": "Active",
  "pages.inactive": "Inactive",
  "pages.deactivate": "Deactivate",  
  "pages.another-action": "Another action",
  "pages.actions": "ACTIONS",
  "pages.header": "Header",
  "pages.details": "DETAILS",
  "pages.orders": "ORDERS",
  "pages.rating": "Rating",
  "pages.price": "Price",
  "pages.ingredients": "Ingredients",
  "pages.is-vegan": "Is Vegan",
  "pages.order-status": "Order Status",
  "pages.bake-progress": "Bake Progress",
  "pages.popularity": "Popularity",
  "pages.comments": "Comments",
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-back-home": "GO BACK HOME",
  "pages.mailing-info":"Mailing templates use inline styling and table layout to be displayed well at various service providers. To provide better usability we include it as regular html with dangerouslySetInnerHTML.",
  "pages.invoice-info":"Invoice template has an inline styled version for usage outside of the project as well as React version.",
  "pages.react-version":"React Version",
  "pages.inline-version":"Inline Styled Html Version",
  "pages.undelete":"Undelete",
  "pages.update":"Update",
  "pages.ok":"OK",
  "pages.add-template":"ADD TEMPLATE",
  "pages.save":"Save",
  "pages.duplicate":"Duplicate",
  "pages.move":"Move",
  "pages.close":"Close",

  /* 06.Applications */

  /* 06.01.Chat */
  "chat.messages": "Messages",
  "chat.contacts": "Contacts",
  "chat.saysomething": "Say something..",


  /* 06.02.Survey */
  "survey.delete": "Delete",
  "survey.edit": "Edit",
  "survey.add-new": "ADD NEW",
  "survey.add-new-title": "Add New Survey",
  "survey.title": "Title",
  "survey.category": "Category",
  "survey.label": "Label",
  "survey.status": "Status",
  "survey.cancel": "Cancel",
  "survey.submit": "Submit",
  "survey.another-action": "Another action",
  "survey.display-options": "Display Options",
  "survey.orderby": "Order By : ",
  "survey.all-surveys": "All Surveys",
  "survey.completed-surveys": "Completed Surveys",
  "survey.categories": "Categories",
  "survey.active-surveys": "Active Surveys",
  "survey.labels": "Labels",

  /* 06.03.Todo */
  "todo.add-new": "ADD NEW",
  "todo.add-new-title": "Add New Todo",
  "todo.title": "Title",
  "todo.detail": "Detail",
  "todo.category": "Category",
  "todo.label": "Label",
  "todo.status": "Status",
  "todo.cancel": "Cancel",
  "todo.submit": "Submit",
  "todo.action": "Action",
  "todo.another-action": "Another action",
  "todo.display-options": "Display Options",
  "todo.orderby": "Order By : ",
  "todo.all-tasks": "All Tasks",
  "todo.pending-tasks": "Pending Tasks",
  "todo.completed-tasks": "Completed Tasks",
  "todo.categories": "Categories",
  "todo.labels": "Labels",

  /* 07.UI */

  /* 07.01.Alerts */
  "alert.rounded": "Rounded Alert",
  "alert.react-notifications": "React Notifications",
  "alert.outline": "Outline",
  "alert.primary": "Primary",
  "alert.secondary": "Secondary",
  "alert.info": "Info",
  "alert.success": "Success",
  "alert.warning": "Warning",
  "alert.error": "Error",
  "alert.filled": "Filled",
  "alert.primary-text": "This is a primary alert—check it out!",
  "alert.secondary-text": "This is a secondary alert—check it out!",
  "alert.success-text": "This is a success alert—check it out!",
  "alert.danger-text": "This is a danger alert—check it out!",
  "alert.warning-text": "This is a warning alert—check it out!",
  "alert.info-text": "This is a info alert—check it out!",
  "alert.light-text": "This is a light alert—check it out!",
  "alert.dark-text": "This is a dark alert—check it out!",
  "alert.default": "Default Alert",
  "alert.dismissing": "Dismissing Alert",
  "alert.dismissing-text":
    "Holy guacamole! You should check in on some of those fields below.",
  "alert.dismissing-without-animate-text":
    "I am an alert and I can be dismissed without animating!",
  "alert.bank": "Vendors will asked to provide bank information",


  /* 07.02.Badges */
  "badge.sizes": "Sizes",
  "badge.colors": "Colors",
  "badge.outline": "Outline",
  "badge.links": "Links",
  "badge.counter-badges": "Counter Badges",
  "badge.bootstrap-default": "Bootstrap Default",
  "badge.primary": "Primary",
  "badge.secondary": "Secondary",
  "badge.success": "Success",
  "badge.danger": "Danger",
  "badge.warning": "Warning",
  "badge.info": "Info",
  "badge.light": "Light",
  "badge.dark": "Dark",

  /* 07.03.Buttons */
  "button.default": "Bootstrap Default",
  "button.colors": "Colors",
  "button.rounded": "Rounded",
  "button.outline": "Outline",
  "button.states": "States",
  "button.sizes": "Sizes",
  "button.button-groups": "Button Groups",
  "button.large-button": "Large Button",
  "button.small-button": "Small Button",
  "button.extra-small-button": "Extra Small Button",
  "button.block-button": "Block Button",
  "button.active": "Active",
  "button.disabled": "Disabled",
  "button.basic": "Basic",
  "button.toolbar": "Toolbar",
  "button.nesting": "Nesting",
  "button.vertical-variation": "Vertical Variation",
  "button.checkbox-radio-button": "Checkbox and Radio Button",
  "button.checkbox": "Checkbox",
  "button.radio": "Radio",
  "button.radio-button": "Radio Button",
  "button.primary": "Primary",
  "button.secondary": "Secondary",
  "button.success": "Success",
  "button.danger": "Danger",
  "button.warning": "Warning",
  "button.info": "Info",
  "button.light": "Light",
  "button.dark": "Dark",
  "button.states-text": "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.click-here": "Click Here",
  "button.states-text-alternate": "This button shows a spinner for 2 seconds and an error icon for 3 seconds before switching to normal state. This states can be triggered by adding and removing classes.",
  "button.primary-link": "Primary Link",
  "button.link": "Link",
  "button.primary-button": "Primary Button",
  "button.button": "Button",
  "button.left": "Left",
  "button.middle": "Middle",
  "button.right": "Right",
  "button.dropdown": "Dropdown",
  "button.dropdown-link": "Dropdown Link",
  "button.createnewdocumentrequest": "Create-new-document-request",
  "button.close": "close",
					   

  /* 07.04.Cards */
  "cards.icon-card": "Icon Card",
  "cards.image-card": "Image Card",
  "cards.image-overlay-card": "Image Overlay Card",
  "cards.image-card-list": "Image Card List",
  "cards.tab-card": "Tab Card",
  "cards.user-card": "User Card",
  "cards.Stages": "Stages",
  /* 07.05.Carousel */
  "carousel.basic": "Carousel Basic",
  "carousel.single": "Carousel Single",
  "carousel.without-controls": "Carousel Without Controls",

  /* 07.06.Charts */
  "charts.line": "Line Chart",
  "charts.polar": "Polar Chart",
  "charts.area": "Area Chart",
  "charts.scatter": "Scatter Chart",
  "charts.bar": "Bar Chart",
  "charts.radar": "Radar Chart",
  "charts.pie": "Pie Chart",
  "charts.doughnut": "Doughnut Chart",
  "charts.shadow": "Shadow",
  "charts.no-shadow": "No Shadow",

  /* 07.07.Collapse */
  "collapse.basic": "Basic",
  "collapse.toggle": "Toggle",
  "collapse.accordion": "Accordion",
  "collapse.controlled": "Controlled",
  "collapse.uncontrolled": "Uncontrolled",

  /* 07.08.Dropdowns */
  "dropdowns.basic": "Basic",
  "dropdowns.controlled": "Controlled",
  "dropdowns.uncontrolled": "Uncontrolled",
  "dropdowns.dropdown": "Dropdown",
  "dropdowns.header": "Header",
  "dropdowns.action": "Action",
  "dropdowns.another-action": "Another Action",
  "dropdowns.right": "Right",
  "dropdowns.left": "Left",
  "dropdowns.drop-directions": "Drop Directions",
  "dropdowns.dropright": "Dropright",
  "dropdowns.dropleft": "Dropleft",
  "dropdowns.small-button": "Small Button",
  "dropdowns.large-button": "Large Button",
  "dropdowns.sizing": "Sizing",
  "dropdowns.split-button": "Split Button Dropdowns",
  "dropdowns.dropup": "Dropup",
  "dropdowns.template-settings": "Template Settings",
  "dropdowns.template-tools": "Template Tools",
  "dropdowns.template-add": "Add Template",
  "dropdowns.exportspreadsheet": "Export to spreadsheet",
  "dropdowns.share-template": "Share Template",
  "dropdowns.template-center": "Template Center",
  "dropdowns.template-delete": "Delete this template",
  "dropdowns.template-undelete": "Undelete a template",
  "dropdowns.template-old": "Old template editor",
  "dropdowns.choose": "Choose Templates",
  "dropdowns.mytemplates": "My Templates",	 

  /* 07.09.Editors */
  "editors.draftjs": "Draft.js",
  "editors.quill-standart": "Quill Standard",
  "editors.quill-bubble": "Quill Bubble",

  /* 07.10.Forms */
  "forms.basic": "Basic",
  "forms.email": "E-mail",
  "forms.email-muted": "We'll never share your email with anyone else.",
  "forms.password": "Password",
  "forms.submit": "Submit",
  "forms.horizontal": "Horizontal",
  "forms.radios": "Radios",
  "forms.first-radio": "First radio",
  "forms.second-radio": "Second radio",
  "forms.third-radio-disabled": "Third disabled radio",
  "forms.checkbox": "Checkbox",
  "forms.signin": "Sign in",
  "forms.top-labels-over-line": "Top Labels Over Line",
  "forms.tags": "Tags",
  "forms.date": "Date",
  "forms.state": "State",
  "forms.top-labels-in-input": "Top Labels In Input",
  "forms.email-u": "E-MAIL",
  "forms.password-u": "PASSWORD",
  "forms.tags-u": "TAGS",
  "forms.date-u": "DATE",
  "forms.state-u": "STATE",
  "forms.grid": "Form Grid",
  "forms.address": "Address",
  "forms.address2": "Address 2",
  "forms.city": "City",
  "forms.city-message": "Please select a city!",
  "forms.state-message": "Please select a state!",
  "forms.zip": "Zip",
  "forms.signup": "Signup",
  "forms.inline": "Inline",
  "forms.validation-availity": "Availity Reactstrap Validation",
  "forms.validation-formik": "Formik Validation",
  "forms.default": "Default",
  "forms.firstname": "First name",
  "forms.firstname-message": "Please enter your first name!",
  "forms.lastname": "Last name",
  "forms.lastname-message": "Please enter your last name!",

  /* 07.11.Form Components */
  "form-components.custom-inputs": "Custom Inputs",
  "form-components.checkboxes": "Checkboxes",
  "form-components.radios": "Radios",
  "form-components.inline": "Inline",
  "form-components.react-select": "React Select",
  "form-components.state-single": "State Single",
  "form-components.state-multiple": "State Multiple",
  "form-components.react-autosuggest": "React Autosuggest",
  "form-components.date-picker": "Date Picker",
  "form-components.date": "Date",
  "form-components.date-range": "Date Range",
  "form-components.date-with-time": "Date with Time",
  "form-components.embedded": "Embedded",
  "form-components.dropzone": "Dropzone",
  "form-components.drop-files-here": "Drop Files Here",
  "form-components.tags": "Tags",
  "form-components.switch": "Switch",
  "form-components.primary": "Primary",
  "form-components.secondary": "Secondary",
  "form-components.primary-inverse": "Primary Inverse",
  "form-components.secondary-inverse": "Secondary Inverse",
  "form-components.slider": "Slider",
  "form-components.double-slider": "Double Slider",
  "form-components.single-slider": "Single Slider",
  "form-components.rating": "Rating",
  "form-components.interactive": "Interactive",
  "form-components.readonly": "Readonly",
  "form-components.type-a-cake": "Type a Cake",
  "form-components.start": "Start",
  "form-components.end": "End",
  "form-components.tables": "Tables",
  "form-components.custom-radio": "Radio",
  "form-components.rad": "Type of Stage",
  /* 07.12.Icons */
  "icons.simplelineicons": "Simple Line Icons",
  "icons.iconsmind": "Iconsmind Icons",

  /* 07.13.Input Groups */
  "input-groups.basic": "Basic",
  "input-groups.sizing": "Sizing",
  "input-groups.small": "Small",
  "input-groups.default": "Default",
  "input-groups.large": "Large",
  "input-groups.checkboxes-and-radios": "Checkboxes and radios",
  "input-groups.multiple-inputs": "Multiple Inputs",
  "input-groups.first-and-last-name": "First and last name",
  "input-groups.multiple-addons": "Multiple Addons",
  "input-groups.button-addons": "Button Addons",
  "input-groups.button": "Button",
  "input-groups.buttons-with-dropdowns": "Buttons with Dropdowns",
  "input-groups.dropdown": "Dropdown",
  "input-groups.header": "Header",
  "input-groups.action": "Action",
  "input-groups.another-action": "Another Action",
  "input-groups.custom-select": "Custom Select",
  "input-groups.options": "Options",
  "input-groups.choose": "Choose...",
  "input-groups.custom-file-input": "Custom File Input",

  /* 07.14.Jumbotron */
  "jumbotron.hello-world": "Hello, world!",
  "jumbotron.lead":"This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
  "jumbotron.lead-detail":"It uses utility classes for typography and spacing to space content out within the larger container.",
  "jumbotron.learn-more": "Learn more",

  /* 07.15.Modal */
  "modal.basic": "Basic",
  "modal.modal-title": "Modal title",
  "modal.launch-demo-modal": "Launch Demo Modal",
  "modal.scrolling-long-content": "Scrolling Long Content",
  "modal.backdrop": "Backdrop",
  "modal.backdrop-value": "Backdrop value",
  "modal.right-modal": "Right Modal",
  "modal.launch-right-modal": "Launch Right Modal",
  "modal.nested-modal": "Nested Modal",
  "modal.size": "Size",
  "modal.launch-large-modal": "Launch Large Modal",
  "modal.launch-small-modal": "Launch Small Modal",

  /* 07.16.Navigation */
  "nav.basic": "Nav Basic",
  "nav.active": "Active",
  "nav.disabled": "Disabled",
  "nav.disabled-link": "Disabled Link",
  "nav.link": "Link",
  "nav.longer-link": "Longer nav link",
  "nav.another-link": "Another Link",
  "nav.right": "Right",
  "nav.dropdown": "Dropdown",
  "nav.header": "Header",
  "nav.action": "Action",
  "nav.another-action": "Another Action",
  "nav.horizontal-alignment": "Nav Horizontal Alignment",
  "nav.vertical-alignment": "Nav Vertical Alignment",
  "nav.pills": "Nav Pills",
  "nav.fill-justify": "Nav Fill and Justify",
  "nav.pills-dropdowns": "Nav Pills with Dropdowns",
  "nav.pagination-basic": "Pagination Basic",
  "nav.pagination-sizing": "Pagination Sizing",
  "nav.large": "Large",
  "nav.small": "Small",
  "nav.center": "Center",
  "nav.pagination-alignment": "Pagination Alignment",
  "nav.breadcrumb-basic": "Breadcrumb Basic",

  /* 07.17.Popover & Tooltip */
  "popover-tooltip.popover": "Popover",
  "popover-tooltip.tooltip": "Tooltip",

  /* 07.18.Sortable */
  "sortable.columns": "Sorting Columns",
  "sortable.basic": "Basic",
  "sortable.handles": "Handles",

  /* 07.19.Maps */
  "maps.google": "Google",
  "maps.yandex": "Yandex",

  /* 07.20.Tables */
  "table.bootstrap-tables": "Bootstrap Tables",
  "table.bootstrap-basic": "Basic Table",
  "table.bootstrap-striped": "Striped Rows",
  "table.bootstrap-bordered": "Bordered Table",
  "table.bootstrap-borderless": "Borderless Table",
  "table.bootstrap-hoverable": "Hoverable Rows",
  "table.bootstrap-responsive": "Responsive Table",
  "table.react-tables": "React Tables",
  "table.react-pagination": "Pagination",
  "table.react-scrollable": "Scrollable",
  "table.react-advanced": "Filter, Length and Jump",
  
  /*Common*/
  "common-label.edit": "Edit",
  "common-label.view": "View",
  "common-label.print": "Print",
  "common-label.add-new": "ADD NEW",
  
  /*New Inspection Form*/
  "new-inspection.inspector": "Inspector",
  "new-inspection.date": "Date/Time",
  "new-inspection.location": "Location",
  "new-inspection.address": "Address",
  "new-inspection.zip": "Zip/Postal Code",
  "new-inspection.client": "Client",
  "new-inspection.clientFirstName": "First Name",
  "new-inspection.clientLastName": "Last Name",
  "new-inspection.clientEmail": "Email",
  "new-inspection.clientSecondEmail": "2nd Email",
  "new-inspection.clientPhone": "Phone",
  "new-inspection.clientReferral": "Referral source",
  "new-inspection.clientAdditional": "Add Additional Client",
  "new-inspection.clientServices": "Add Additional Client",			

	/*Customer Form*/
  "customer.name":"Distributor Name",
  "customer.dob":"Date of Birth",
  "customer.address":"Distributor Address",
  "customer.zip":"Pincode",
  "customer.phone":"Phone",
  "customer.email":"Email",
  "customer.expiration":"Expiration Date of Card",
  "customer.mmjcard":"Distributor MMJ Card",
  "customer.license":"Driving License",
  "customer.caregiver":"Caregiver's Name",
  "customer.caredob":"Caregiver's Date of Birth",
  "customer.caremmjno":"Caregiver's MMJ Number",
  "customer.expdatemmjcard":"Expiration Date of Caregiver's MMJ Card",
  "customer.caregiverLicense":"Caregiver's Driving License",
  "customer.employeeNumber":"Employee Agent Number",
  "customer.hypurIsn":"Hypur ISN",
  "customer.typeOfCustomer":"Type of Distributor",
  "customer.isMember":"Is Member",
  "customer.taxExempt":"Tax Exempt",
  "customer.notes":"Notes",
  "customer.add":"Add",
  "customer.cancel":"Cancel",
  "customer.create": "Create",
  "customer.update": "Update",
  "customer.save": "Save",
  "customer.submit": "Submit",
  "customer.addtocart": "Add To Cart",
  "customer.print": "Print",
  "customer.reset": "Reset",
  "customer.check": "Check",
  "customer.apply": "Apply",
  "customer.close": "Close",
  "customer.complete": "Complete",
  "customer.pause": "Pause",
  "customer.restart": "Restart",
  "customer.edit": "Edit",
  "customer.delete": "Delete",
  "customer.save-settings": "Save Settings",
  
  
  /*Sidebar Customer Menus*/
  "sidebar.customermanagement":"Distributors Management",
  "sidebar.customers":"Distributors",
  "sidebar.addcustomer":"Add Distributor",
  "sidebar.allorders":"All Orders",
  "sidebar.expressCalendar":"Express Calendar",
  "sidebar.customerDirectory":"Distributor Directory",
  "sidebar.coupons":"Coupons",
  "sidebar.customerPoints":"Distributor Points",
  "sidebar.customerPos":"Distributor Pos",
  "sidebar.creategroup":"Create Groups",
  
  /*Sidebar Pointofsale Menus*/
  "sidebar.Point-of-sale":"Point Of Sale",
  "sidebar.customer-pos":"Distributor Pos",
  "sidebar.place-order":"Place Order",
  "sidebar.customer-lookup": "Distributor Lookup",
  "sidebar.all-orders": "All Orders",
  "sidebar.daily-deals": "Daily Deals",
  "sidebar.mix-and-match-deals": "Mix and Match Deals",
  "sidebar.daily-deals-calendar": "Deals Calendar",
  "sidebar.reverse-tax-calculator": "Reverse Tax Calculator",
  "sidebar.cash-exchange": "Cash Exchange",
  "sidebar.printer-settings": "Printer Settings",
  
  /*Sidebar Accounts Menus*/
  "sidebar.all-accounts":"All Accounts",
  "sidebar.completed-orders":"Completed Orders",
  "sidebar.all-distributions":"All Distributions",
  
  /*Sidebar Inventory Menus*/  
  "sidebar.rooms":"Rooms",
  "sidebar.inventory":"Inventory",
  "sidebar.distillate-bucket":"Distillate Bucket",
  "sidebar.pricing-groups":"Pricing Groups",
  "sidebar.strains":"Strains",
  "sidebar.product-category":"Product Category",
  "sidebar.type":"Type",
  "sidebar.tax-category":"Tax Category",
  "sidebar.inventory-audit":"Inventory Audit",
  "sidebar.waste-management":"Waste Management",
  "sidebar.additive-templates":"Additive Templates",
  "sidebar.inventory-transfer":"Inventory Transfer",
  "sidebar.outgoing-transfer":"All Outgoing Transfers",
  "sidebar.incoming-transfer":"All Incoming Transfers",
  "sidebar.transfer":"Transfer",
  "sidebar.allrooms":"All Rooms",
  "sidebar.tabletypes":"Table Types",
  "sidebar.allplants":"All Plants",
  "sidebar.group":"Groups",
  "sidebar.batch":"Batch",
  "sidebar.growinventory":"Grow Inventory",
  "sidebar.plant":"Plant Audit",
  "sidebar.batchoption":"Batch Option",
  "sidebar.productionprocessreport": "Productionprocess report",
  "sidebar.growhouse": "Growhouse",
  "sidebar.holded-transfer": "Held Transfer Orders",
  "sidebar.strain_types":"Strain Types",
  "sidebar.category_types":"Category Types",
  
  /*Sidebar transportation*/
  "sidebar.drivers":"Drivers",
  "sidebar.track-drivers":"Track Drivers",
  "sidebar.shipper-information":"Shipper Information",
  "sidebar.vehicles":"Vehicles",
  
  /*Sidebar kitchen*/
  "sidebar.all-recipes":"All Package Setups",
  "sidebar.add-recipe":"Add Package Setup",
  "sidebar.kitchen-process":"Packaging Process",
  "sidebar.all-packaging-flows": "All Packaging Flows",
  
  /*Sidebar warehouse*/
  "sidebar.all-warehouses":"All Warehouses",
  "sidebar.shelf-types":"Shelf Types",
  "sidebar.view-warehouse":"View Warehouse",
  
  /*Sidebar department*/
  "sidebar.department":"Department",
  
  /*Sidebar staffmanagement*/
 "sidebar.staff-directory":"Staff Directory",
 "sidebar.user-roles":"User Roles",
 "sidebar.staff-scheduler":"Staff Scheduler",
 "sidebar.staff-time-zone":"Staff Time Tracking",
 
 /*Sidebar visitors*/
 "sidebar.visitors":"Visitors",
 "sidebar.settings":"Settings",
 
/*Sidebar equipments*/
 "sidebar.equipments":"Equipments",
 "sidebar.equipment_dependencies":"Equipment Dependencies",
 "sidebar.columns":"Columns",
 "sidebar.vessels":"Vessels",
 "sidebar.sacks": "Socks",
 "sidebar.jars": "Jars",
 "sidebar.process-overview":"Process Overview",
 "sidebar.raw-material-staging":"Raw Material Staging",
 "sidebar.column-filling":"Column Filling",
 "sidebar.all-operations":"All Operations",
 "sidebar.extractor-operation":"Extractor Operation",
 "sidebar.oven-operation":"Oven Operation",
 "sidebar.mcp-operation":"MCP Operation",
 "sidebar.evaporator-operation":"Evaporator Operation",
 "sidebar.distillation-operation":"Distillation Operation",
 "sidebar.crc-operation": "CRC Operation",
 "sidebar.crc-recipes":"CRC Recipes",
 "sidebar.packaging":"Packaging Process",

 /*Sidebar externalbanks*/
 "sidebar.external-banks":"External Banks",
 "sidebar.internal-bank":"Internal Bank",
 "sidebar.drawersmanagement":"Drawers Management",
 "sidebar.payouts":"Payouts",
 
 /*Sidebar myreports*/
 "sidebar.my-reports":"My Reports",
 "sidebar.inventory1":"Inventory",
 "sidebar.sales":"Sales",
 "sidebar.customers1":"Distributors",
 "sidebar.thc-report":"THC Report",
 "sidebar.staff":"Staff",
 "sidebar.waste":"Waste",
 "sidebar.current-inventory":"Current Inventory",
 
 
 /*Sidebar myjobs*/
 "sidebar.my-jobs":"My Jobs",
 "sidebar.all-jobs":"All Jobs",
 "sidebar.all-folders":"All Folders",
 "sidebar.sub-folders":"Sub Folders",
 "sidebar.add-job":"Add Job",
 "sidebar.your-assigned-jobs":"Your Assigned Jobs",
 
 /*Sidebar officialrequests */
 "sidebar.official-requests":"Official Requests",
 "sidebar.payment":"Payment",
 "sidebar.assignment":"Assignment",
 "sidebar.rfprequest":"RFP Request",
  
  /*Sidebar Growhouse Menus*/
  
  "sidebar.table-types":"Rooms",
  
  /*Sidebar Onboarding Menus */  
  "sidebar.onboarding":"Onboarding",
  "sidebar.create-a-new-workflow":"Create a New Workflow",
  
  "sidebar.production-process":"Production Process",
  "sidebar.locations": "Metrc Locations",
  "sidebar.metrc-label-settings": "Metrc Label Settings",
  "sidebar.best-salesman": "Best Salesman",
  
  /*Front End*/
  "sidebar.posts":"Posts",
  "sidebar.menu":"Menus",
  "sidebar.pages":"Pages",
  "sidebar.testimonials":"Testimonials",
  "sidebar.home_sliders":"Home Sliders",
  "sidebar.sliders":"Sliders",
  "sidebar.lightbox-gallery":"Lightbox Gallery",
  "sidebar.happy_hour_sliders":"Happy Hour Sliders",
  "sidebar.footer":"Footer Management",
  "sidebar.contact-form": "Contact Form",
  "sidebar.contact-form-entries": "Contact Form Entries",
  

};
