import React, { Component, Fragment } from "react";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Rating from "../../components/common/Rating";

import axios from 'axios';
import swal from 'sweetalert';
import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import number_format from "number_format-php";
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL, REST_API_BASE_URL, GOOGLE_API_KEY, PUBNUB_CREDENTIALS} from "../../constants/defaultValues";

import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker, InfoWindow

} from "react-google-maps";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const google=window.google;

const defaultMapOptions = {
  zoomControl: true,
  disableDefaultUI: true
};

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key="+GOOGLE_API_KEY+"&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withGoogleMap,

  lifecycle({
    componentDidMount() {
		const DirectionsService = new google.maps.DirectionsService();
		var places=[];
		
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
	
		var formData = new FormData();
		formData.append('order_id',this.props.order_id);
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'transportation/get-data-for-assign-driver',formData).then((res)=>{
			
			var data = res.data;
			
			var place={};
			place = {address:data.start_loc};
			places.push(place);
			places.push({address:data.end_loc});
								
			if(places.length>1){
				const waypoints = places.map(p => ({
					location: p.address,
					stopover: true
				}));
				
				const origin = waypoints.shift().location;
				const destination = waypoints.pop().location;

				DirectionsService.route(
					{
						origin: origin,
						destination: destination,
						travelMode: google.maps.TravelMode.DRIVING,
						waypoints: waypoints
					},
					(result, status) => {
					  if (status === google.maps.DirectionsStatus.OK) {
						
						if(typeof result.routes[0]!='undefined'){
							let legs = result.routes[0].legs[0];
							let start_location = {lat:legs.start_location.lat(),lng:legs.start_location.lng()}
							let end_location = {lat:legs.end_location.lat(),lng:legs.end_location.lng()}
							places[0].latLng=start_location;
							places[1].latLng=end_location;
						}
						
						this.setState({
							directions: result,
							places
						});
					  } else {
						console.error(`error fetching directions ${result}`);
					  }
					}
				);
			}									
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});              
			
			  
    }
  })
)(props => (
  <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }} defaultOptions={defaultMapOptions}>
    {props.directions && <DirectionsRenderer directions={props.directions} options={{suppressMarkers:true}} />}	
	{props.places && props.places.map((marker,i)=>{
		if(i==0){
			return(<Marker
			  key={i}
			  position={marker.latLng}
			  title={marker.address}
			>	
			</Marker>)
		}else{
			return(<Marker
			  key={i}
			  position={marker.latLng}
			  title={marker.address}
			>	
			</Marker>)
		}
	})}
  </GoogleMap>
));


class ViewTransportationModal extends Component{
	
	constructor(props) {
		super(props);
		
		let currencySymbol = getCurrencySymbol();
		this.state = {
			order_data: null,		
			order_products: null,		
			delivery_details: null,		
			customer_review: null,		
			signature: null,		
			photoproof: null,
			currencySymbol,
		};
		
	}

	componentDidMount(){        	
		if (this.props.delivery_id!=0) {
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('delivery_id',this.props.delivery_id);
			formData.append('facility_id',facility_id);
			axios.post(REST_API_END_POINT+'transportation/view-delivery-detail',formData).then((res)=>{
				
				var data = res.data;
				if(data.status==1){
					var customer_review=null;
					var signature=null;
					var photoproof=null;
					if(data.customer_review){
						customer_review=data.customer_review;
					}
					
					if(data.signature){
						signature=data.signature;
					}
					
					if(data.photoproof){
						photoproof=data.photoproof;
					}
					var order_data = data.order_data;
					var delivery_details = data.delivery_details;
					this.setState({ 
						order_data,
						order_products:data.order_products,
						delivery_details,
						customer_review,
						signature,
						photoproof,
					});
					
					if(order_data.delivery_status==1){
						var subscriber_id = localStorage.getItem('subscriber_id');
						var direction_channel = ['tridant_'+subscriber_id+'_'+facility_id];
						var local_channel = 'tridant_s_'+subscriber_id+'_'+facility_id;
						
						setTimeout(function(){
							const container = document.getElementById('map');
							if(container) {
								const PubNub=window.PubNub;
								const L=window.L;
								const eon=window.eon;

								var pn = new PubNub({
									publishKey: PUBNUB_CREDENTIALS.publishKey,
									subscribeKey: PUBNUB_CREDENTIALS.subscribeKey,
									ssl: (('https:' == document.location.protocol) ? true : false)
								});
								
								var pn_loc = new PubNub({
									publishKey: PUBNUB_CREDENTIALS.publishKey,
									subscribeKey: PUBNUB_CREDENTIALS.subscribeKey,
									ssl: (('https:' == document.location.protocol) ? true : false)
								});
								
								//pn.subscribe({ channels: direction_channel });		
								
								L.RotatedMarker = L.Marker.extend({
									options: { angle: 0 },
									_setPos: function(pos) {
										L.Marker.prototype._setPos.call(this, pos);
										if (L.DomUtil.TRANSFORM) {	// use the CSS transform rule if available
											this._icon.style[L.DomUtil.TRANSFORM] += ' rotate(' + this.options.angle + 'deg)';
										} else if (L.Browser.ie) { // fallback for IE6, IE7, IE8
											var rad = this.options.angle * L.LatLng.DEG_TO_RAD,costheta = Math.cos(rad),sintheta = Math.sin(rad);
											this._icon.style.filter += ' progid:DXImageTransform.Microsoft.Matrix(sizingMethod=\'auto expand\', M11=' + costheta + ', M12=' + (-sintheta) + ', M21=' + sintheta + ', M22=' + costheta + ')';
										}
									}
								});
								
								pn_loc.addListener({
									message: function (loc_data) {
										if(loc_data.message.user_id){											
											let new_torchys=[{latlng:{ lat: -34.397, lng: 150.644 },user_id:loc_data.message.user_id}];
											new_torchys[0].latlng= {lat:loc_data.message.latitude,lng:loc_data.message.longitude};
											pn.publish({
														channel: local_channel,
														message: new_torchys
													});
										}
									}
								});
								pn_loc.subscribe({ channels: direction_channel });
								
								var old_latLng = { lat: -34.397, lng: 150.644 };
								
								var map = eon.map({
									pubnub: pn,
									id: 'map',
									googleKey: GOOGLE_API_KEY,
									options : { zoom:8, center:{ lat: -34.397, lng: 150.644 } },
									channels:[local_channel],
									provider: 'google',
									rotate: true,
									history: true,
									connect: function () {
										var me = {
											icon: {
												'marker-color': '#ce1126'
											}
										};
										var them = { 
											icon: {
												'marker-color': '#29abe2'
											}
										};
									},
									message: function (data) {
										if(typeof data!='undefined'){
											if(typeof data[0]!='undefined'){
												if(data[0]['user_id']){
													if(data[0]['user_id']==delivery_details['driver_user_id']){
														map.setView(data[0].latlng, 8);
													}
												}
											}
										}
									},
									marker: function (latlng, data) {
										if(typeof latlng!='undefined'){
											old_latLng = latlng
										}
										
										var marker = new L.RotatedMarker(old_latLng, {
										  icon: L.icon({
											iconUrl: REST_API_BASE_URL+'img/truck-T.png',
											iconSize: [120, 68]
										  })
										});
										marker.bindPopup(delivery_details['name']);
										return marker;
										
									}
								});
							}
						}, 300);
					}
					
				}else{
					this.props.toggleModal();
					swal("Warning!","There is no such order","warning");
				}					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
    }
	
	render() {
		const { order_data, order_products, delivery_details, customer_review, photoproof, signature } = this.state;
		let invoiceTitle = "Tax Invoice";
		let delivery_status = 'Waiting for driver';
		if(order_data!=null){
			if(order_data.delivery_status==1){
				delivery_status = 'Out for delivery';
			}else if(order_data.delivery_status==2){
				delivery_status = 'Delivered';
			}else if((order_data.delivery_status==null && order_data.status==null) || (order_data.delivery_status==3 && order_data.status==2)){ 
				delivery_status = 'Waiting for assigning driver';
			}else if(order_data.delivery_status==5){
				delivery_status = 'Canceled';
			}else if(order_data.delivery_status==4){
				delivery_status = 'Returned';
			}
			if(order_data.invoice==0){
				invoiceTitle = 'Proforma Invoice';
			}
		}
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					View Details
				</ModalHeader>
			 
				<ModalBody>
					<Row>
						<Colxx lg="5">			  
							<h3 className="font-weight-bold">Order Details</h3>						  
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Customer Name</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">{order_data!=null ? order_data.vendor:""}</span>						  
								</Colxx>
							</Row>
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Wholesale Type</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">Sale</span>						  
								</Colxx>
							</Row>
							
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Payment</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">{this.state.currencySymbol}{order_data!=null ? order_data.total:""}{order_data!=null ? (order_data.payment_type!=null ? "("+order_data.payment_type+")":""):""}</span>						  
								</Colxx>
							</Row>
							
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Products</span>						  
								</Colxx>
								<Colxx xxs="6">
									<Table>
										<thead><tr><th>Name</th><th>Quantity</th></tr></thead>
										<tbody>
											{order_data!=null ? order_products.map((row,index)=>{
												return(<tr key={index}>
													<td>{row.product_name}</td>
													<td>{number_format(parseFloat(row.product_qty),2,'.','')}</td>
												</tr>)
											}):null}
										</tbody>
									</Table>
								</Colxx>
							</Row>
							<Row className="mt-2 mb-4">
								<Colxx xxs="12">								
									<Button color="primary" onClick={(e)=> this.props.toggleModalPrintShippingManifest(e,this.props.delivery_id) }>
										Print {invoiceTitle}
									</Button>
								</Colxx>
							</Row>
						</Colxx>
						
						<Colxx lg="5">
							<h3 className="font-weight-bold">Delivery Details</h3>						  
							<Row className="mt-2">
								<Colxx xxs="12">
									{order_data!=null ? (order_data.delivery_status!=2 && order_data.delivery_status!=5 ? <Fragment> 
										<Button color="primary" size="sm" className="mr-2" onClick={(e)=> this.props.cancelDelivery(e,this.props.delivery_id) }>
											Cancel Order
										</Button>{" "}<Button color="primary" size="sm"  onClick={(e)=> this.props.markDelivery(e,this.props.delivery_id) } >
											Mark Delivery
										</Button>
									</Fragment>:""):""}				  
								</Colxx>
							</Row>
							
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Driver Name</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">{delivery_details!=null ? delivery_details.name:""}</span>						  
								</Colxx>
							</Row>
							
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Expected Time</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">{delivery_details!=null ? delivery_details.expected_time:""}</span>						  
								</Colxx>
							</Row>
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Delivery Status</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">{delivery_status}</span>						  
								</Colxx>
							</Row>
							<Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Custmer Review</span>						  
								</Colxx>
								<Colxx xxs="6">
									<span className="h8">{customer_review!=null ? customer_review.review_msg:"No Comments"}</span>						  
								</Colxx>
							</Row>
							{customer_review!=null ? <Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Custmer Rating</span>						  
								</Colxx>
								<Colxx xxs="6">
									<Rating total={5} rating={parseInt(customer_review.star_count)} interactive={false} />
								</Colxx>
							</Row>:""}
							
							{photoproof!=null ? <Row className="mt-2">
								<Colxx xxs="6">
									<span className="h8">Photo proof</span>						  
								</Colxx>
								<Colxx xxs="6">
									<a href={BASE_URL+"delivery-proof/"+photoproof.proof_img} className="btn btn-md btn-primary" target="_blank" >View</a>
								</Colxx>
							</Row>:""}
							
							{signature!=null ? <Row className="mt-3">
								<Colxx xxs="6">
									<span className="h8">Signature</span>						  
								</Colxx>
								<Colxx xxs="6">
									<img src={BASE_URL+"sign/"+signature.sign_img} width="100" height="75" />
								</Colxx>
							</Row>:""}
						</Colxx>			
									
						{delivery_details!=null ? <Colxx lg="2" className="text-center">
							<img src={BASE_URL+"qrcode/"+delivery_details.qr_code_img} alt="QR Code" className="img-responsive p-0" width="100" height="100"/>
						</Colxx>:""}
					</Row>
					
					{order_data!=null ? (order_data.delivery_status!=1 ? <MapWithADirectionsRenderer directions places={[]} order_id={order_data.transfer_id} />:<div id="map" style={{width:'100%', height:'500px'}}></div>):""}
					
				</ModalBody>
				<ModalFooter>
					<Button outline onClick={this.props.toggleModal}>Cancel</Button>{" "}
				</ModalFooter>
			</Modal>
		);
	}
};

export default ViewTransportationModal;
