import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ViewOperationModal from "../../../containers/processor/ViewOperationModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Alloperations extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			staging_id:0,
			search: '',
			modalOpenView:false,
			alloperations:[],
		};
	}
	
	toggleModalView = (e,staging_id=0) => {
		this.setState({
			staging_id: parseInt(staging_id),
			modalOpenView: !this.state.modalOpenView,
		});
	};	
	
    componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/get-all-raw-material-staging',formData).then((res)=>{
			var data = res.data;
		
			if(data.raw_material_stagings){
				this.setState({
					alloperations: data.raw_material_stagings,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					alloperations: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    render() {
		let data = this.state.alloperations;
		if (this.state.search) {
			data = data.filter(row => {
				if(row.operated_by==null){
					row.operated_by="";
				}
				return row.batch_uid.toLowerCase().includes(this.state.search) || row.product_name.toLowerCase().includes(this.state.search) || row.no_of_batch.toLowerCase().includes(this.state.search) || row.rr.toLowerCase().includes(this.state.search) || row.operated_by.toLowerCase().includes(this.state.search)
			})
		}
		
	const dataTableColumns = [			
	  {
		Header: "Product Name",
		accessor: "product_name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Batch UID",
		accessor: "batch_uid",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "No. of Batches",
		accessor: "no_of_batch",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },  	  
	  {
		Header: "RR (Grams)",
		accessor: "rr",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  }, 	  	  
	  {
		Header: "Completed Packaging",
		accessor: "packaging",
		Cell: props => <p className="list-item-heading">{props.value=="1" ? "Yes":"No"}</p>
	  },  	  
	  {
		Header: "Operated By",
		accessor: "operated_by",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },	  	  
	  {
		Header: "Options",
		accessor: "id",
		Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
									{/*props.original.packaging!="1" ? <DropdownItem className="cursor-pointer" onClick={e=>{ window.location="/app/processor/edit-product-batch/"+props.value; }} >
										<span className="d-inline-block" >Edit Batch</span>				
									</DropdownItem>:""*/}	
									{props.original.packaging=="1" ? <DropdownItem className="cursor-pointer" onClick={e=>{ window.location="/app/processor/edit-packaging/"+props.value; }} >
										<span className="d-inline-block" >Edit Packaging</span>				
									</DropdownItem>:""}								
									<DropdownItem className="cursor-pointer" onClick={(e)=>this.toggleModalView(e,props.value)} >
										<span className="d-inline-block" >View</span>				
									</DropdownItem>
								</DropdownMenu>}
							/>
						</div>
					</Fragment>
	  },

	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.all-operations" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4"><br/>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
		   <Row>			  
				<Colxx xxs="12">			  
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="12">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							responsive
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: false}]}
						/>
						
						<ViewOperationModal
							modalOpen={this.state.modalOpenView}
							toggleModal={this.toggleModalView}
							staging_id={this.state.staging_id}
						/>
					</div>					
				</Colxx>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
