import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon

} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import ImageCropper from "../../../components/common/ImageCropper";
import moment from 'moment';
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddProductCategoryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateCroppedImageUrl = this.updateCroppedImageUrl.bind(this);
		
		this.state = {
			modalOpenAdd: false,		
			parentSelect: null,
			categoryType: null,
			metricCategorySelect: null,
			shop_yes:true,
			shop_no:false,
			shop_yes_value:"1",
			shop_no_value:"0",
			pos_yes:true,
			pos_no:false,
			pos_yes_value:"1",
			pos_no_value:"0",
			croppedImageUrl:null,
			is_minwidth:true
		};
	
	}
	
	handleSubmit(event, errors, values) {
		if(this.state.croppedImageUrl!=null && !this.state.is_minwidth){
			swal("Warning!","Minimum required dimension of the image is 320x320","warning");
			return;
		}
		
		if (errors.length === 0) {
			if(this.state.categoryType==null){
				swal("Warning!","Please select category type","warning");
				return;
			}
			document.getElementById("add-product-category").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("productcategoryForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			if(this.state.croppedImageUrl!=null){
				var fileFromBlob = new File([this.state.croppedImageUrl], 'category-image'+moment().unix()+'.png');
				formData.append('thumbnail',fileFromBlob);
			}
			formData.delete('thumbnail_crop');
			
			axios.post(REST_API_END_POINT+'inventory/add-product-category',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				this.setState({
					parentSelect: null,
					shop_yes:true,
					shop_no:false,
					pos_yes:true,
					pos_no:false,
					metricCategorySelect:null
				});
				
				document.getElementById("add-product-category").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-product-category").disabled=false;
			});

		}
	}
	
	updateCroppedImageUrl = (croppedImageUrl,is_minwidth) => {
		this.setState({ croppedImageUrl,is_minwidth })
	};

  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Create Product Category
			</ModalHeader>
			<AvForm
		        id="productcategoryForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="cname"
								name="name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 120 characters"
								  },
								  maxLength: {
									value: 120,
									errorMessage:
									  "The name must be between 2 and 120 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>						
					</Row>
					<Row className="d-none">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Metrc Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="metrc_category"
									id="metrc_category"
									placeholder="--Select Metrc Category--"						
									value={this.state.metricCategorySelect}					
									options={this.props.metric_categories}
									onChange={metricCategorySelect => { this.setState({ metricCategorySelect }) }}

								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="mt-2 mb-3">
							<Label>Category Type<span className="text-danger">*</span></Label> &nbsp;&nbsp;	
							<div className="d-none">
								<AvRadioGroup
									inline
									name="category_type"
									validate={{
									  required: {
										value: true,
										errorMessage: "Please select any of the category type"
									  }
									}}
									defaultValue="0"
								>								
									<AvRadio customInput label="Medical Marijuana" value="1" />
									<AvRadio customInput label="Edibles" value="2" />
									<AvRadio customInput label="Non-Edibles" value="3" />
									<AvRadio customInput label="Raw Material" value="4" />
									<AvRadio customInput label="Shatter" value="5" />
									<AvRadio customInput label="Other" value="0" />
								</AvRadioGroup>
							</div>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="c_type"
								id="c_type"
								placeholder="--Select Category Type--"
								value={this.state.categoryType}					
								options={this.props.category_types}
								onChange={categoryType => { this.setState({ categoryType }) }}
							/>
						</Colxx>
					</Row>
					
					<span >Description</span>
					<Row>
						<Colxx lg="12">						
							<AvGroup className="error-t-negative">
								<AvField
									name="desc"
									id="desc"
									type="textarea"
									autoComplete="off"
									value={this.state.textarea_content}
									placeholder=""
									rows="5"
									validate={{
									  minLength: {
										value: 5,
										errorMessage:
										  "Your message must have minimum of 5 characters"
									  },
									}}
								/>
							</AvGroup>																		
						</Colxx>																		
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Parent Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="parent_category"
									id="parent_category"
									placeholder="--Select Parent Category--"						
									value={this.state.parentSelect}						
									options={this.props.parent_categories}
									onChange={parentSelect => { this.setState({ parentSelect }) }}

								/>
							</AvGroup>
						</Colxx>
					</Row>
					
					<ImageCropper 
						updateCroppedImageUrl={this.updateCroppedImageUrl}
						label={"Thumbnail (Recommended size 320x320)"}
					/>
					<div className="d-none">
						<span>Features</span>
						<Row className="mt-4">
								
							<Colxx lg="6">						
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="Aromas"
										name="aromas"
										value="Y"
										id="aromas"
									/>
								</FormGroup>
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="Flavors"
										name="flavors"
										value="Y"
										id="flavors"
									/>
								</FormGroup>
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="Effects"
										name="effects"
										value="Y"
										id="effects"
									/>
								</FormGroup>
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="Symptoms"
										name="symptoms"
										value="Y"
										id="symptoms"
									/>
								</FormGroup>
							</Colxx>
							<Colxx lg="6">
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="THC"
										name="thc"
										value="Y"
										id="thc"
									/>
								</FormGroup>						
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="CBD"
										name="cbd"
										value="Y"
										id="cbd"
									/>
								</FormGroup>
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="Cannabinoids"
										name="cannabinoids"
										value="Y"
										id="cannabinoids"
									/>
								</FormGroup>
								<FormGroup>
									<CustomInput 
										type="checkbox" 
										label="Cloth Size"
										name="cloth_size"
										value="Y"
										id="cloth_size"
									/>
								</FormGroup>
							</Colxx>									
						</Row>
					</div>
					<Row>
						<Colxx lg="12" className="mt-2 mb-2">
							<Label>Show in shop</Label>	&nbsp;&nbsp;						
							<Label>&nbsp;&nbsp;
								<CustomInput
									id="shop_yes"
									type="radio"
									name="show_on_shop"
									className="d-inline-block"
									value={this.state.shop_yes_value}
									className="d-inline-block"
									checked={this.state.shop_yes}
									onChange={() => { this.setState({ shop_yes:true,shop_no:false }) }}
								/>
								{" "}Yes
							</Label>&nbsp;&nbsp;						
							<Label>
								<CustomInput
									id="shop_no"
									type="radio"
									name="show_on_shop"
									className="d-inline-block"
									value={this.state.shop_no_value}
									className="d-inline-block"
									checked={this.state.shop_no}
									onChange={() => { this.setState({ shop_yes:false,shop_no:true }) }}								
								/>
								{" "}No
							</Label>		
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12" className="mt-2 mb-2">
							<Label>Show in POS</Label>	&nbsp;&nbsp;						
							<Label>&nbsp;&nbsp;
								<CustomInput
									id="pos_yes"
									type="radio"
									name="show_on_pos"
									className="d-inline-block"
									value={this.state.pos_yes_value}
									className="d-inline-block"
									checked={this.state.pos_yes}
									onChange={() => { this.setState({ pos_yes:true,pos_no:false }) }}
								/>
								{" "}Yes
							</Label>&nbsp;&nbsp;						
							<Label>
								<CustomInput
									id="pos_no"
									type="radio"
									name="show_on_pos"
									className="d-inline-block"
									value={this.state.pos_no_value}
									className="d-inline-block"
									checked={this.state.pos_no}
									onChange={() => { this.setState({ pos_yes:false,pos_no:true }) }}								
								/>
								{" "}No
							</Label>		
						</Colxx>					
					</Row>
					
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-product-category">
					  <IntlMessages id="customer.save" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
	  </Modal>
	  );
  }
};

export default AddProductCategoryModal;
