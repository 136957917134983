import React, { Fragment, Component, Children } from "react";
import {
    Row, Container, Button
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

SwiperCore.use([Navigation, Autoplay]);

class AboutUs extends Component {
    constructor(props) {
        super(props);
		
		this.state = {
			posts: [],
			blocking:true,
		};
    }
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'website/get-about-us-posts',formData).then((res)=>{ 
			this.setState({ posts: res.data.posts, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
		
	}

    render() {
		const { fields } = this.props;
		const { posts } = this.state;
		let head = fields.find(r=>{ return r.key==='head' });
		
		const paramstwo = {
			spaceBetween: 0,
			centeredSlides: true,
			loop: posts.length > 3,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			shouldSwiperUpdate: true,
			rebuildOnUpdate: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 10,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 0,
				},
			}
		}

        return (
            <div>
				{posts.length > 0 ? <Fragment><div className="text-center mt-2"><h1>{head.value}</h1></div>
					<div className="container mb-4 pt-4 pb-4">
						<div className="row mobile-slide-padding justify-content-center">
							{posts.length > 3 || (posts.length > 1 && (isMobile || isAndroid)) ? <Swiper {...paramstwo}>{posts.map((row, i) => {
								return (<div className="col-12 col-lg-4 text-center text-md-left" key={i}>
									{row.featured_image != null ? <img
										className="default-img img-responsive"
										src={BASE_URL + 'pages/' + row.featured_image}
										alt=""
									/> : ""}
									<div className="pt-3 f-20"><h3>{row.name}</h3></div>
									<div className="mt-2"><p className="f-16">{row.excerpt}</p></div>
									<div className="mt-2"><a href={"/blog/" + row.alias_url} className="read-more">READ MORE</a></div>
								</div>)
							})}</Swiper> : <div className="row w-100 px-3">
								{posts.map((row, i) => {
									return (<div className="col-12 col-lg-4 not-swiper-slide text-center text-md-left" key={i}>
										{row.featured_image != null ? <img
											className="default-img img-responsive"
											src={BASE_URL + 'pages/' + row.featured_image}
											alt=""
										/> : ""}
										<div className="pt-3 f-20"><h3>{row.name}</h3></div>
										<div className="mt-2"><p className="f-16">{row.excerpt}</p></div>
										{row.read_more_link != "" && row.read_more_link != null ? <div className="mt-2"><a href={row.read_more_link} className="read-more">READ MORE</a></div> : ""}
									</div>)
								})}
							</div>}
						</div>
					</div></Fragment> : ""}
            </div>

        );
    }
};

export default AboutUs;
