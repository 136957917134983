import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';
import { displayDateFormat } from "../../helpers/Utils";

import {REST_API_END_POINT, dataEncryptKey, themeColor, JQUERY_MODULE, LBS_CONSTANT} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import KitchenProcessComponent from "../../components/kitchen/KitchenProcess";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const displayDFormat = displayDateFormat();

const finishedStruct = {
	id:uuid(),
	product_id:null,
	variation_id:null,
	quantity:0,
	variations:[],
}

export default class Batch extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			containers:[],
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			package_uid:null,		  
			step:1,	
			batch_data:null,
			batchStartTime:null,
			batchEndTime:null,
			submitted:false,
			show_date_picker:true,
			blocking:false,
		};
		
		this.updateRawMaterialStaging = this.updateRawMaterialStaging.bind(this);
		this.getContainerDetails = this.getContainerDetails.bind(this);
		this.addConsolidation = this.addConsolidation.bind(this);
		this.removeConsolidation = this.removeConsolidation.bind(this);
		this.completeConsolidation = this.completeConsolidation.bind(this);
		this.updateFinishedProduct = this.updateFinishedProduct.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		if (prevProps.staging_id !== this.props.staging_id && this.props.staging_id!=0) {				
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			this.setState({ blocking:true });
			
			var formData = new FormData();
			formData.append('staging_id',this.props.staging_id);
			formData.append('facility_id',facility_id);
			axios.post(REST_API_END_POINT+'processor/get-product-batch-detail',formData).then((res)=>{
				let data = res.data;
				let batch_data = data.batch_data;
				let batchStartTime=null, batchEndTime=null, container_uid=null;
				/*var containers = data.containers;
				containers = containers.map(row=>{
					row.label=row.container_name+" ("+row.barcode+")";
					row.value=row.container_id;
					row.key=row.container_id;
					return row;
				});*/
				
				if(batch_data){
					if(batch_data.packaging=="1"){
						swal("Success!","This batch has been already packed","success").then(()=>{
							window.location="/app/processor/all-operations";
						});
					}
					if(batch_data.batch_start_time){
						batchStartTime = moment(batch_data.batch_start_time,displayDFormat+' hh:mm a');
					}
					if(batch_data.batch_end_time){
						batchEndTime = moment(batch_data.batch_end_time,displayDFormat+' hh:mm a');
					}
					if(batch_data.container_id){
						container_uid = this.props.containers.find(r=>{ return r.value==batch_data.container_id; });
					}
				}
				
				this.setState({ 
					blocking:false,
					show_date_picker:false, 
					batch_data, 
					batchStartTime, batchEndTime, container_uid, 
					consolidations:[], container_capacity:0, in_house_weight_lbs_val:"", in_house_weight_gram_val:"", submitted:false 
				},()=>{
					this.setState({ show_date_picker:true });
				});
				if(container_uid){
					this.getContainerDetails(container_uid.value);
				}
			}).catch(e =>{
				this.setState({ blocking:false });
				console.log("Addition failed , Error ", e);
			});              
			
		}
    }
	
	getContainerDetails(container_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('container_id',container_id);
		formData.append('staging_id',this.props.staging_id);
		
		axios.post(REST_API_END_POINT+'processor/get-container-details',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){				
				var consolidations = data.consolidations;
				var container_capacity = data.container_capacity;
				this.setState({
					consolidations,
					container_capacity,
				});
			}else{
				this.setState({					
					consolidations:[],
					container_capacity:0,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	updateRawMaterialStaging(key,value) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('staging_id',this.props.staging_id);
        formData.append(key,value);
		
		axios.post(REST_API_END_POINT+'processor/update-raw-material-staging-container',formData).then((res)=>{
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	addConsolidation() {
		const container_uid=this.state.container_uid;
		const package_uid=this.state.package_uid;
		const consolidations=this.state.consolidations;
		const container_capacity=this.state.container_capacity;
		if(container_uid!=null){
			var container_id=container_uid.value;
			if(package_uid!=null){
				var package_id=package_uid.value;
				var in_house_weight_lbs_val =0;
				var in_house_weight_gram_val =0;
				
				var package_current_qty = parseFloat(package_uid.quantity);
				
				if(this.state.in_house_weight_lbs_val!=''){
					in_house_weight_lbs_val = parseFloat(this.state.in_house_weight_lbs_val);
				}
				
				var in_house_weight = parseFloat(((in_house_weight_lbs_val*LBS_CONSTANT)+in_house_weight_gram_val).toFixed(4));
				if(package_current_qty<in_house_weight){
					let package_current_qty_lbs=parseFloat(package_current_qty/LBS_CONSTANT).toFixed(4);
					swal("Warning!",'The stock has only '+package_current_qty_lbs+' Kg ',"warning");
					return;
				}
				
				var total_grams=0;
				if(consolidations.length){
					total_grams = parseFloat(consolidations.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
				}
								
				if(parseFloat((total_grams+in_house_weight).toFixed(2))>container_capacity){
					swal("Warning!",'The addition of this stock will exceed the vessel capacity ',"warning");
					return;
				}
				
				document.getElementById('consolidate-next-stock').disabled=true;
				
				
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('container_id',container_id);
				if(package_uid.package){
					formData.append('package_id',package_id);
					formData.append('staging_load_id',0);
				}else{
					formData.append('package_id',0);
					formData.append('staging_load_id',package_id);
				}
				formData.append('sack_stock_id',0);
				formData.append('in_house_weight',in_house_weight);
				formData.append('staging_id',this.props.staging_id);
				
				axios.post(REST_API_END_POINT+'processor/add-consolidation',formData).then((res)=>{
					var data = res.data;
					
					if(data.status==1){				
						let consolidations = data.consolidations;
						
						var product_packages = data.packages;
						product_packages = product_packages.map(row=>{
							row.label=row.name+" ("+row.barcode+")";
							row.value=row.package_id;
							row.key=row.package_id;
							row.package=true;
							return row;
						});
						
						let rrs = data.rrs;
						rrs = rrs.map(row=>{
							row.label=row.name+" ("+row.batch_uid+")";
							row.value=row.id;
							row.key=row.id;
							row.package=false;
							return row;
						});
						
						let packages_dropdown = [...product_packages,...rrs]

						this.setState({
							consolidations,							
							package_uid:null, 
							in_house_weight_lbs_val:"",
							in_house_weight_gram_val:""
						});
						
						this.props.updatePackages({ product_packages, rrs, packages_dropdown });
					}else{
						swal("Warning!","Failed consolidate stock to vessel","warning");
					}
					document.getElementById('consolidate-next-stock').disabled=false;
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById('consolidate-next-stock').disabled=false;
				});
			}else{
				swal("Warning!","Please select a stock/RR","warning");
			}
		}else{
			swal("Warning!","Please select a vessel","warning");
		}
	}
	
	removeConsolidation(biomass_id){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('biomass_id',biomass_id);
		formData.append('staging_id',this.props.staging_id);
		
		axios.post(REST_API_END_POINT+'processor/remove-consolidation',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.package_id;
					row.key=row.package_id;
					row.package=true;
					return row;
				});
				
				let rrs = data.rrs;
				rrs = rrs.map(row=>{
					row.label=row.name+" ("+row.batch_uid+")";
					row.value=row.id;
					row.key=row.id;
					row.package=false;
					return row;
				});
				
				let packages_dropdown = [...product_packages,...rrs]
				
				var consolidations = this.state.consolidations.filter(row=>{ return row.biomass_id!=biomass_id });
				this.setState({
					consolidations
				});
				this.props.updatePackages({ product_packages, rrs, packages_dropdown });
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	completeConsolidation(){
		var total_grams=0;
		this.state.consolidations.map((consolidation,index)=>{			
			total_grams+=parseFloat(consolidation.in_house_weight);
		});
		
		if(total_grams!=0){
			this.setState({ step:2 });
		}else{
			swal("Warning!","Please consolidate at least one stock","warning");
		}
		/*this.setState({
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			package_uid:null,
		});*/
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			
		}
		
		return;
	}
	
	handleChangeBatchStartTime = date => {
		if(typeof date!=='string'){
			if(date!=null && this.state.batchEndTime!=null){
				if(date.unix()>this.state.batchEndTime.unix()){
					swal("Warning!", "The start time should be less than end time!", "warning");				
					return;
				}
			}
			this.setState({
			  batchStartTime: date
			});
			if(date!=null){
				this.updateRawMaterialStaging('batch_start_time',date.format("YYYY-MM-DD HH:mm:ss"));
			}
		}
	};
	
	handleChangeBatchEndTime = date => {
		if(typeof date!=='string'){
			if(date!=null && this.state.batchStartTime!=null){
				if(date.unix()<this.state.batchStartTime.unix()){
					swal("Warning!", "The end time should be greater than start time!", "warning");				
					return;
				}
			}
			this.setState({
			  batchEndTime: date
			});
			if(date!=null){
				this.updateRawMaterialStaging('batch_end_time',date.format("YYYY-MM-DD HH:mm:ss"));
			}
		}
	};
	
	validDate = (currentDate, selectedDate) => {
		if(currentDate!=null){
			if(currentDate.unix()<=moment(moment().format("YYYY-MM-DD 23:59:59")).unix()){
				return true;
			}
		}
		return false;
	};
	
	/* Finished Products */
	addFinishedProduct = () =>{
		let finishedProducts = this.state.finishedProducts;
		let finishedProduct = JSON.parse(JSON.stringify(finishedStruct));
		finishedProduct.id=uuid();
		finishedProducts.push(finishedProduct);
		this.setState({ finishedProducts });
	}
	
	removeFinishedProduct = (id) =>{
		let finishedProducts = this.state.finishedProducts;
		finishedProducts = finishedProducts.filter(row=>{ return row.id!==id });
		this.setState({ finishedProducts });
	}
	
	updateFinishedProduct = (id,key,value) => {
		if(key==='product_id'){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('product_id',value.value);
			axios.post(REST_API_END_POINT+'processor/get-variations',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var product_variations = data.product_variations;
					product_variations = product_variations.map(row=>{
						row.label=row.variation_name;
						row.value=row.variation_id;
						row.key=row.variation_id;
						return row;
					});
					
					let finishedProducts = this.state.finishedProducts;
					finishedProducts = finishedProducts.map(row=>{
						if(row.id==id){
							row[key] = value;
							row.variation_id = null;
							row.variations = product_variations;
						}
						return row;
					});
					this.setState({ finishedProducts });
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}else{
			let finishedProducts = this.state.finishedProducts;
			finishedProducts = finishedProducts.map(row=>{
				if(row.id==id){
					row[key] = value;
				}
				return row;
			});
			this.setState({ finishedProducts });
		}
	};

	renderFinishedProducts = () => {
		const { submitted } = this.state;
		let finishedProducts = [];
		if(this.state.finishedProducts.length>0){
			finishedProducts = this.state.finishedProducts.map((finished,index)=>{
				return (<Row className="align-items-center mb-2" key={index}>
							<Colxx md="4">
								<AvGroup className="error-t-negative">
									<Label>Product<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"product_id"+index}
										id={"product_id"+index}
										placeholder="-- Select Product --"
										value={finished.product_id}						
										options={this.state.products}
										onChange={product_id => { 
											this.updateFinishedProduct(finished.id,'product_id',product_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select product</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="4">
								<AvGroup className="error-t-negative">
									<Label>Variation<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"variation_id"+index}
										id={"variation_id"+index}
										placeholder="-- Select Variation --"
										value={finished.variation_id}						
										options={finished.variations}
										onChange={variation_id => { 
											this.updateFinishedProduct(finished.id,'variation_id',variation_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select variation</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Quantity<span className="text-danger">*</span></Label>
									<AvField
										id={"f_quantity_"+index}
										name={"f_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.quantity}
										onChange={e => { 
											this.updateFinishedProduct(finished.id,'quantity',e.target.value);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the quantity"
											},
											min: {
												value: 0,
												errorMessage: "Quantity must be greater than 0"
											},
											step: {
												value: 1,
												errorMessage: "Quantity must be a whole number"
											}
										}}
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="1">
								<span className="text-danger" onClick={()=>{ this.removeFinishedProduct(finished.id) }}><i className="fas fa-2x fa-times" /></span>
							</Colxx>	
						</Row>)
			})
		}
		return finishedProducts;
	};
	
	/* Packaging */
	addPackagingProduct = () =>{
		let packagingProducts = this.state.packagingProducts;
		let finishedProduct = JSON.parse(JSON.stringify(finishedStruct));
		finishedProduct.id=uuid();
		packagingProducts.push(finishedProduct);
		this.setState({ packagingProducts });
	}
	
	removePackagingProduct = (id) =>{
		let packagingProducts = this.state.packagingProducts;
		packagingProducts = packagingProducts.filter(row=>{ return row.id!==id });
		this.setState({ packagingProducts });
	}
	
	updatePackagingProduct = (id,key,value) => {
		if(key==='product_id'){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('product_id',value.value);
			axios.post(REST_API_END_POINT+'processor/get-variations',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var product_variations = data.product_variations;
					product_variations = product_variations.map(row=>{
						row.label=row.variation_name;
						row.value=row.variation_id;
						row.key=row.variation_id;
						return row;
					});
					
					let packagingProducts = this.state.packagingProducts;
					packagingProducts = packagingProducts.map(row=>{
						if(row.id==id){
							row[key] = value;
							row.variation_id = null;
							row.variations = product_variations;
						}
						return row;
					});
					this.setState({ packagingProducts });
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}else{
			let packagingProducts = this.state.packagingProducts;
			packagingProducts = packagingProducts.map(row=>{
				if(row.id==id){
					row[key] = value;
				}
				return row;
			});
			this.setState({ packagingProducts });
		}
	};

	renderPackagingProducts = () => {
		const { submitted } = this.state;
		let packagingProducts = [];
		if(this.state.packagingProducts.length>0){
			packagingProducts = this.state.packagingProducts.map((finished,index)=>{
				let variation_count = this.state.packagingProducts.filter(p=>{ 
						if(p.variation_id!=null && finished.variation_id!=null){
							return p.variation_id.value==finished.variation_id.value;
						}else{
							return false;
						}			
					}).length;
				
				return (<Row className="align-items-center mb-2" key={index}>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Product<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"p_product_id"+index}
										id={"p_product_id"+index}
										placeholder="-- Select Product --"
										value={finished.product_id}						
										options={this.state.productsPackaging}
										onChange={product_id => { 
											this.updatePackagingProduct(finished.id,'product_id',product_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select product</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Variation<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"p_variation_id"+index}
										id={"p_variation_id"+index}
										placeholder="-- Select Variation --"
										value={finished.variation_id}						
										options={finished.variations}
										onChange={variation_id => { 
											this.updatePackagingProduct(finished.id,'variation_id',variation_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select variation</span>:""):""}
									{variation_count>1 ? <span className="text-danger">Duplicate variation found</span>:""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup >
									<Label>Current Stock<span className="text-danger">*</span></Label>
									<AvField
										id={"avail_quantity_"+index}
										name={"avail_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.variation_id!=null ? finished.variation_id.stock:""}
										readOnly
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="2">
								<AvGroup >
									<Label>Used Quantity<span className="text-danger">*</span></Label>
									<AvField
										id={"p_quantity_"+index}
										name={"p_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.quantity}
										onChange={e => { 
											this.updatePackagingProduct(finished.id,'quantity',e.target.value);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the quantity"
											},
											min: {
												value: "0",
												errorMessage: "Quantity must be greater than 0"
											},
											max: {
												value: finished.variation_id!=null ? finished.variation_id.stock.toString():"0",
												errorMessage: "Quantity must be less than or equal to "+(finished.variation_id!=null ? finished.variation_id.stock.toString():"0")
											},
											step: {
												value: 1,
												errorMessage: "Quantity must be a whole number"
											}
										}}
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="1">
								<span className="text-danger" onClick={()=>{ this.removePackagingProduct(finished.id) }}><i className="fas fa-2x fa-times" /></span>
							</Colxx>	
						</Row>)
			})
		}
		return packagingProducts;
	};
	
	submitRawMaterialStaging = () => {
		this.setState({ submitted:true })
		if(this.state.batchStartTime==null || this.state.batchEndTime==null){
			return false;
		}
		
		if(this.state.batchStartTime.format('YYYY-MM-DD hh:mm a')==this.state.batchEndTime.format('YYYY-MM-DD hh:mm a')){
			swal("Warning!", "The start time should be less than end time!", "warning");				
			return false;
		}
		
		if(document.getElementById('step-2-submit-btn')){
			document.getElementById('step-2-submit-btn').disabled=true;
		}
		
		var facility_id = localStorage.getItem('facility_id');
		var user_id = localStorage.getItem('user_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		formData.append('container_id',this.state.container_uid.value);
		formData.append('staging_id',this.props.staging_id);
		
		let total_grams = parseFloat(this.state.consolidations.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
		formData.append('container_qty',total_grams);
		
		axios.post(REST_API_END_POINT+'processor/update-raw-material-staging-container',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				this.props.nextBatch();
			}else{
				swal("Warning!",data.msg,'warning')
			}
			if(document.getElementById('step-2-submit-btn')){
				document.getElementById('step-2-submit-btn').disabled=false;
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			if(document.getElementById('step-2-submit-btn')){
				document.getElementById('step-2-submit-btn').disabled=false;
			}
		});
		return true;
	};
	
	
	render() {
		if(this.props.staging_id==0){
			return "";
		}
		
		const { consolidations, container_capacity, submitted, batch_data, show_date_picker } = this.state;
		const { containers, product_packages, packages_dropdown } = this.props; 
		var total_grams=0;
		var total_container_weight_lbs=0;
		var total_container_weight_gram=0;
		
		var final_consolidations=this.state.step<3 ? consolidations.map((consolidation,index)=>{			
			total_grams+=parseFloat(consolidation.in_house_weight);
			return(<Row className="align-items-center mb-3" key={index}>
						<Colxx lg="6">
							<Input
								readOnly={true}
								value={(consolidation.staging_load_id!=0 ? "RR ":consolidation.product_name)+" ("+consolidation.barcode+")"}
							/>
						</Colxx>
						<Colxx lg="6">
							<Row className="align-items-center">
								<Colxx lg="10">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_lbs+" (Kilograms)"}
									/>
								</Colxx>
								<Colxx lg="5" className="d-none">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_gram+" (Grams)"}
									/>
								</Colxx>
								<Colxx lg="2">
									<span className="text-danger" onClick={()=>{ this.removeConsolidation(consolidation.biomass_id) }}><i className="fas fa-2x fa-times" /></span>
								</Colxx>
							</Row>
						</Colxx>	
					</Row>);
		}):[];
		total_container_weight_lbs=parseFloat(parseFloat(total_grams/LBS_CONSTANT).toFixed(4));
		total_container_weight_gram=parseFloat(((total_grams/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(5);
		
		var fill = 0;
		if(container_capacity!=0){
			fill = (total_grams/container_capacity)*100;
		}
		
    return (
      <Fragment>
        <Card className="p-4">
			<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
				{this.props.showNextPrev ? <div className="stage-batch-number"><span>{this.props.currentIndex}</span></div>:""}
				<h4>Batch #{batch_data!=null ? batch_data.batch_uid:""}</h4>
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<Row>
						<Colxx lg="12" className="mb-4">
							<Row className="processor-container-fill">
								<Colxx lg="10">
									{show_date_picker ? <Row >
										<Colxx lg="6">
											<AvGroup>
												<Label>Batch Start Time<span className="text-danger">*</span></Label>
												<DatePicker
													id="batch_start_time"
													name="batch_start_time"
													autoComplete="off"
													className="mb-2"
													maxDate={moment()}
													value={this.state.batchStartTime}
													placeholderText=""
													dateFormat={displayDFormat}
													timeFormat="hh:mm a"
													onChange={this.handleChangeBatchStartTime}
													isValidDate={this.validDate}
													readOnly={true}
												  />
												  {submitted ? (this.state.batchStartTime==null ? <span className="text-danger">Please select batch start time</span>:""):""}
											</AvGroup>
										</Colxx>
										<Colxx lg="6">
											<AvGroup>
												<Label>Batch End Time<span className="text-danger">*</span></Label>
												<DatePicker
													id="batch_end_time"
													name="batch_end_time"
													autoComplete="off"
													className="mb-2"
													maxDate={moment()}
													value={this.state.batchEndTime}
													placeholderText=""
													dateFormat={displayDFormat}
													timeFormat="hh:mm a"
													onChange={this.handleChangeBatchEndTime}
													isValidDate={this.validDate}
													readOnly={true}
												  />
												{submitted ? (this.state.batchEndTime==null ? <span className="text-danger">Please select batch end time</span>:""):""}
											</AvGroup>
										</Colxx>
									</Row>:""}
									
									<AvGroup className="error-t-negative">
										<Label>Enter/Scan Destination Vessel UID</Label>
										<Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"						
											name="container_uid"
											id="container_uid"
											placeholder="-- Select Container --"
											value={this.state.container_uid}						
											options={containers}
											onChange={container_uid => { 
												this.setState({ container_uid }); 
												if(container_uid!=null){
													this.getContainerDetails(container_uid.value);
													this.updateRawMaterialStaging('container_id',container_uid.value);
												}
											}}
										/>
									</AvGroup>
								
									<Row>
										<Colxx lg="5" className="mb-4">
											<AvGroup className="error-t-negative">
												<Label>Enter/Scan Stock/RR UID To Consolidate</Label>
												<Select
													components={{ Input: CustomSelectInput }}
													className="react-select"
													classNamePrefix="react-select"						
													name="package_uid"
													id="package_uid"
													placeholder="-- Select Stock/RR --"
													value={this.state.package_uid}						
													options={packages_dropdown}
													onChange={package_uid => { 
														if(package_uid!=null){
															let in_house_weight_lbs_val=parseFloat(parseFloat(package_uid.quantity/LBS_CONSTANT).toFixed(4));
															this.setState({ package_uid, in_house_weight_lbs_val })
														}else{
															this.setState({ package_uid })
														}
													}}
												/>
											</AvGroup>
										</Colxx>
										<Colxx lg="3" className="mb-4 form-group-mb-0">
											<AvGroup>
												<Label>In House Weight</Label>
												<AvField
													id="in_house_weight_lbs_val"
													name="in_house_weight_lbs_val"
													type="number"												
													placeholder="(Kilograms)"
													value={this.state.in_house_weight_lbs_val}
													onChange={e => { this.setState({ in_house_weight_lbs_val:e.target.value }) }}												
												/>
												<Label>Kilograms</Label>
											</AvGroup>
										</Colxx>						
										<Colxx lg="4" className="mb-4 d-none form-group-mb-0">
											<AvGroup>
												<Label>&nbsp;</Label>
												<AvField
													id="in_house_weight_gram_val"
													name="in_house_weight_gram_val"
													type="number"	
													placeholder="(Grams)"
													value={this.state.in_house_weight_gram_val}
													onChange={e => { this.setState({ in_house_weight_gram_val:e.target.value }) }}
												/>
												<Label>Grams</Label>
											</AvGroup>
										</Colxx>
									
										<Colxx lg="4" className="mb-4 form-group-mb-0">
											<Label className="d-block">&nbsp;</Label>
											<Button	color="success" block size="lg" className="mr-1" id="consolidate-next-stock" disabled={this.state.container_uid==null} onClick={this.addConsolidation} >Add Stock/RR To Vessel</Button> 
										</Colxx>
									</Row>
									
									<div>
									{final_consolidations}
									</div>
									
									<Row className="align-items-center">
										<Colxx lg="6">
											<Label>Total Vessel Weight </Label>
										</Colxx>
										<Colxx lg="6">
											<Row className="align-items-center">
												<Colxx lg="10">																	
													<Input
														readOnly={true}
														value={total_container_weight_lbs+" (Kilograms)"}
													/>
												</Colxx>
												<Colxx lg="5" className="d-none">
													<Input
														readOnly={true}
														value={total_container_weight_gram+" (Grams)"}
													/>
												</Colxx>
											</Row>
										</Colxx>
									</Row>
									
									{this.props.showNextPrev ? <Row className="text-center mt-3 mb-4">
										<Colxx lg="6" className="text-right">
											<Button	color="success" type="button" size="lg" style={{minWidth:'170px'}} disabled={!this.props.isPrev} onClick={this.props.prevBatch} >{"<<"} Previous</Button> 
										</Colxx>
										<Colxx lg="6" className="text-left">
											<Button color="danger" type="button" id="step-2-submit-btn" size="lg" style={{minWidth:'170px'}} disabled={!this.props.isNext || total_container_weight_lbs==0} onClick={this.submitRawMaterialStaging} >Next {">>"}</Button>
										</Colxx>
									</Row>:""}
									
									{this.props.isFinished ? <Row className="text-center mt-3 mb-4">
										<Colxx lg="12">
											<Button	color="success" id="complete-batching" type="button" size="lg" disabled={total_container_weight_lbs==0} style={{minWidth:'200px'}} onClick={()=>{ if(this.submitRawMaterialStaging()){ this.props.completeBatching(); } }} >{!this.props.showNextPrev ? "Update Batch":"Complete Batching"}</Button> 
										</Colxx>
									</Row>:""}
								</Colxx>
								<Colxx lg="2">
									<div className="meter animate">
										<span style={{height: (fill*3.52)+"px"}}><span></span></span>
									</div>
									
									<h2 className="text-center mt-3 mb-0" id="container_filled">{number_format(parseFloat(fill),2,'.','')}%</h2>
									<p className="text-center mb-0" style={{fontSize:'14px'}}>is occupied</p>
									<p className="text-center mb-2" style={{fontSize:'14px'}}>Capacity: <span>{parseInt(container_capacity/LBS_CONSTANT)+' Kilograms '}</span></p>
								</Colxx>
								
							</Row>

						</Colxx>	 
					</Row>
				</AvForm>
			</BlockUi>
		</Card>
		
      </Fragment>
    );
  }
}
