import React, { Fragment, Component, Children } from "react";
import {
    Row, Container, Button
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import Rating from "../FilledRating";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { projectTitle, REST_API_END_POINT, dataEncryptKey, BASE_URL } from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

SwiperCore.use([Navigation, Autoplay]);

class RecentReviews extends Component {
    constructor(props) {
        super(props);
		
		this.state = {
			google_reviews: [],
			blocking:true,
		};
		this.goNextReview = this.goNextReview.bind(this);
		this.goPrevReview = this.goPrevReview.bind(this);
		
		this.swiperRef = React.createRef();
    }
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'website/get-home-google-reviews',formData).then((res)=>{ 
			this.setState({ google_reviews: res.data.google_reviews, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
		
	}
	
	goNextReview() {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
		}
	}

	goPrevReview = () => {
		let swiperRef = this.swiperRef;
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
		}
	};
	
    render() {
		const { fields } = this.props;
		const { google_reviews } = this.state;
		let head = fields.find(r=>{ return r.key==='head' });
		
		const google_reviews_params = {
			spaceBetween: 40,
			centeredSlides: false,
			loop: false,
			shouldSwiperUpdate: true,
			rebuildOnUpdate: true,
			breakpoints: {
				640: {
					slidesPerView: 1,
					spaceBetween: 40,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 40,
				},
			}
		}
		
        return (
            <div>
				{google_reviews.length > 0 ? <Fragment><div className="container testimonial-wrapper pb-4 pt-4">
						<div className="text-center pt-4 pb-2"><h1>{head.value}</h1></div>
						<Swiper {...google_reviews_params} ref={this.swiperRef}>
							{google_reviews.map((row, i) => {
								return (<div key={i} className="text-center">
									<div><p className="pt-2 black text-center">"{row.comment_text}"</p></div>
									<div className="text-muted mb-2 small-size-rating">
										<Rating total={5} rating={parseInt(row.rating)} interactive={false} />
									</div>
									{row.profile_photo_url != null ? <img
										className="author-img img-responsive"
										src={row.profile_photo_url}
										alt={row.author_name}
									/> : ""}
									<div><p className="pt-2 black h6 text-center">{row.author_name}</p></div>
									<div className="review-type-img">
										<img className="img-responsive" src={row.review_type == 1 ? "/assets/img/google-logo.png" : "assets/img/weedmaps-logo.png"} alt={row.review_type == 1 ? "Google" : "Weedmap"} />
									</div>
								</div>)
							})}
						</Swiper>
						<div className="swiper-button-next swiper-review-next" onClick={this.goNextReview} ></div>
						<div className="swiper-button-prev swiper-review-prev" onClick={this.goPrevReview} ></div>
					</div></Fragment> : ""}
            </div>

        );
    }
};

export default RecentReviews;
