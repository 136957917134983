import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddSliderModal extends Component{
	constructor(props) {
		super(props);
		this.state={
			auto_play:true,
			show_footer:false,
		}
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-post").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("postForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('auto_play',this.state.auto_play ? 1:0);
			formData.append('show_footer',this.state.show_footer ? 1:0);
			
			axios.post(REST_API_END_POINT+'website/add-slider',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-post").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-post").disabled=false;
			});

		}
	}

	render() {
		
		
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Slider
		  </ModalHeader>
		  <AvForm
		        id="postForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Name<span className="text-danger">*</span></Label>
							<AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								validate={{
									required: {
										value: true,
										errorMessage: "Please enter the name"
									},
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx lg="12" className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.auto_play}
								onChange={status => { this.setState({ auto_play:!this.state.auto_play }) }}
							/>
							<span className="h6 ml-4">Auto Play</span>
						</Label>
					</Colxx>
				</Row>
				
				<Row className={!this.state.auto_play ? "d-none":""}>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Delay (ms)<span className="text-danger">*</span></Label>
							<AvField
								id="auto_play_delay"
								name="auto_play_delay"
								type="number"
								autoComplete="off"
								defaultValue="3000"
								validate={{
									required: {
										value: this.state.auto_play,
										errorMessage: "Please enter the delay"
									},
									min: {
										value: "1000",
										errorMessage: "Please enter a value greater than or equal to 1000"
									},
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx lg="12" className="mb-2">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.show_footer}
								onChange={status => { this.setState({ show_footer:!this.state.show_footer }) }}
							/>
							<span className="h6 ml-4">Show slider footer</span>
						</Label>
					</Colxx>
				</Row>
				
			</ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-post" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddSliderModal;
